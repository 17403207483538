import React from "react";
import { HiLightningBolt, HiDocumentText, HiUserGroup } from "react-icons/hi";

const StepCard = ({ number, title, description, icon }) => (
  <div className="bg-white rounded-xl shadow-lg p-6 relative overflow-hidden">
    <div className="absolute top-0 left-0 bg-green-600 text-white text-2xl font-bold w-12 h-12 flex items-center justify-center rounded-br-xl">
      {number}
    </div>
    <div className="mt-8">
      {icon}
      <h3 className="text-xl font-bold mt-4 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

export default function HowItWorks() {
  return (
    <section className="bg-gradient-to-b from-white to-green-50 py-20">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
            Buried in endless research, but still unsure about your market?
          </h2>
          <p className="text-xl text-gray-700 max-w-3xl mx-auto">
            Our streamlined process turns your idea into a comprehensive business plan in three simple steps.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          <StepCard
            number="1"
            title="Enter Your Idea"
            description="AI pinpoints your ideal customer, eliminating wasted ad dollars and guesswork."
            icon={<HiLightningBolt className="text-green-500 text-4xl" />}
          />
          <StepCard
            number="2"
            title="100+ Page Business Report"
            description="Our AI crafts a comprehensive business plan, cutting through the overwhelm of traditional research."
            icon={<HiDocumentText className="text-green-500 text-4xl" />}
          />
          <StepCard
            number="3"
            title="Run AI User Interviews"
            description="AI-powered interviews uncover honest feedback that friends and family might not provide."
            icon={<HiUserGroup className="text-green-500 text-4xl" />}
          />
        </div>
      </div>
    </section>
  );
}
