import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import Logo from "../../images/thumbnail.png";
import {
  Menu,
  X,
  Search,
  Bell,
  ChevronDown,
  Check,
  MessageCircle,
  Send,
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon,
  ChartBarIcon,
  LucideCloudLightning,
  SearchIcon,
  BriefcaseIcon,
  RocketIcon,
  ArrowRight,
  Calendar,
  Clock,
} from "lucide-react";

const NavLink = ({ href, children }) => (
  <motion.a
    href={href}
    className="text-gray-800 hover:text-blue-600 transition-colors duration-300"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    {children}
  </motion.a>
);

const ThirdArticle = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="min-h-screen font-sans text-gray-900">
      {/* Header */}
      <motion.header
        className={`bg-white backdrop-blur-md bg-opacity-80 shadow-sm sticky top-0 z-50 transition-all duration-300 ${
          scrolled ? "py-2" : "py-4"
        }`}
      >
        <nav className="container mx-auto px-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <Link to="/" className="flex items-center text-2xl font-bold text-gray-800">
                <img src={Logo} alt="FeedbackbyAI Logo" className="h-10 w-auto mr-2" />
                <span className="text-2xl font-bold text-blue-600">FeedbackbyAI</span>
              </Link>
            </div>
            <div className="hidden md:flex space-x-8">
              <motion.a
                href="/signin/"
                className="bg-green-500 text-white px-6 py-2 rounded-full font-semibold hover:bg-blue-700 transition-colors duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Signin
              </motion.a>
            </div>
            <motion.button
              className="md:hidden"
              onClick={() => setMenuOpen(!menuOpen)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {menuOpen ? <X /> : <Menu />}
            </motion.button>
          </div>
          <AnimatePresence>
            {menuOpen && (
              <motion.div
                className="mt-4 md:hidden space-y-4"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <NavLink href="#features">Features</NavLink>
                <NavLink href="#how-it-works">How It Works</NavLink>
                <NavLink href="#pricing">Pricing</NavLink>
                <motion.a
                  href="/signup/"
                  className="block bg-blue-600 text-white px-6 py-2 rounded-full font-semibold hover:bg-blue-700 transition-colors duration-300 text-center"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Get Started
                </motion.a>
              </motion.div>
            )}
          </AnimatePresence>
        </nav>
      </motion.header>

      {/* Main Content */}
      <article className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-6 text-gray-800">50+ AI Characters: Getting Unbiased Feedback on Your Business Idea</h1>

        {/* Relevant Unsplash Image */}
        <img
          src="https://images.unsplash.com/photo-1552345387-b54624b477d7?q=80&w=2559&auto=format&fit=crop"
          alt="AI Characters Business Feedback"
          className="w-full h-auto rounded-lg shadow-lg mb-6"
        />

        <p className="text-lg text-gray-700 mb-6">
          When it comes to validating your business idea, feedback is everything. But relying on friends and family often leads to biased or
          overly supportive responses that may not help you grow your idea. With <strong>FeedbackbyAI</strong>, you can access unbiased
          feedback from over 50+ AI characters, each representing different customer personas, professions, and personalities. These AI
          characters simulate real-world interviews, giving you the insights you need to refine and develop your business idea.
        </p>

        <h2 className="text-3xl font-semibold mb-4 text-gray-800">The Challenge of Biased Feedback</h2>

        <p className="text-lg text-gray-700 mb-6">
          Friends and family often want to be supportive, but their feedback may be influenced by their relationship with you. This bias can
          mask real problems with your idea or overlook important areas for improvement. Honest feedback, however, can be difficult to find,
          and hiring focus groups or consultants can be expensive. Entrepreneurs need real, actionable feedback that helps them understand
          the strengths and weaknesses of their ideas from a diverse group of people.
        </p>

        <h2 className="text-3xl font-semibold mb-4 text-gray-800">How FeedbackbyAI’s 50+ AI Characters Work</h2>

        <p className="text-lg text-gray-700 mb-6">
          <strong>FeedbackbyAI</strong> offers a unique approach to idea validation. Our 50+ AI characters act as stand-ins for various
          customer types, providing feedback based on their persona. Whether you’re targeting software developers, investors, or marketing
          managers, you can select the characters that best fit your target audience. The AI characters ask tough questions and provide
          critical feedback, mimicking the responses of real-world users.
        </p>

        <ul className="list-disc list-inside mb-6 text-lg text-gray-700">
          <li>
            <strong>Encourager</strong>: Offers positive feedback while highlighting areas for potential growth.
          </li>
          <li>
            <strong>Troll</strong>: Represents tough feedback and harsh criticism, preparing you for the most challenging customer
            scenarios.
          </li>
          <li>
            <strong>Product Hunter</strong>: Provides insights on product-market fit and whether your idea is innovative enough.
          </li>
          <li>
            <strong>Investor</strong>: Offers financial insights and feedback on whether your idea is investment-ready.
          </li>
          <li>
            <strong>Psychologist</strong>: Focuses on human behavior, providing feedback on how your idea resonates with people.
          </li>
          <li>And many more, from software developers to social media influencers and gamers.</li>
        </ul>

        <h2 className="text-3xl font-semibold mb-4 text-gray-800">Benefits of AI-Driven User Interviews</h2>

        <p className="text-lg text-gray-700 mb-6">
          Unlike traditional user feedback methods, AI-driven interviews provide several advantages. By using a variety of personas, you can
          ensure that your business idea gets tested from multiple angles, offering insights into how different customer segments will
          perceive your product or service. Additionally, the AI characters provide unbiased, honest feedback without the filter of personal
          relationships.
        </p>

        <ul className="list-disc list-inside mb-6 text-lg text-gray-700">
          <li>Receive objective feedback that reflects real market concerns.</li>
          <li>Identify weaknesses in your idea that friends and family might overlook.</li>
          <li>Test your idea with multiple personas to ensure broad market fit.</li>
        </ul>

        <h2 className="text-3xl font-semibold mb-4 text-gray-800">How This Feedback Sharpens Your Business Idea</h2>

        <p className="text-lg text-gray-700 mb-6">
          Feedback from AI characters helps you refine your business idea before you invest time and money into development. You can quickly
          identify any areas that need improvement, understand customer expectations, and make informed adjustments. Whether you're
          assessing product-market fit, pricing, or overall appeal, AI-generated feedback offers the critical insights needed to sharpen
          your strategy and increase your chances of success.
        </p>

        {/* Call to Action Section */}
        <div className="bg-blue-100 border-l-4 border-blue-500 p-4 mb-6">
          <p className="text-xl font-semibold text-blue-700">
            Ready to receive unbiased feedback on your business idea? Try our AI-powered interviews with 50+ AI characters today. Sign up
            for a free trial and get started.
          </p>
        </div>

        <div className="bg-gray-100 p-6 rounded-lg text-center">
          <h3 className="text-2xl font-bold mb-4">Get Unbiased Feedback with FeedbackbyAI</h3>
          <p className="text-lg mb-4">Sign up for a free trial and see how AI-driven interviews can refine your business idea.</p>
          <button className="bg-blue-500 hover:bg-green-500 text-white font-bold py-2 px-4 rounded">
            <a href="/">Sign Up for Free</a>
          </button>
        </div>
      </article>
    </div>
  );
};

export default ThirdArticle;
