// import { useState } from "react";
// import { Star, Eye, Clock, MessageSquare, Target, Gift, ArrowLeft, Share2, Bookmark, ChevronRight } from "lucide-react";
// import DashboardHeader from "./DashboardHeader";

// export default function SpotlighteList() {
//   const [selectedSpotlight, setSelectedSpotlight] = useState(null);

//   // Added more data model here...
//   const spotlights = [
//     {
//       id: 1,
//       title: "Grow Together",
//       subtitle: "Connecting Urban Farmers & Fostering Food Security",
//       description: "Discover & Share Authentic Recipes from Around the World",
//       longDescription:
//         "Global Grub is revolutionizing how people connect through food. Our platform combines AI-powered recipe matching with social features to create meaningful connections around authentic cuisine.",
//       image: "/api/placeholder/1600/800",
//       category: "Food & Beverage",
//       badge: "San Francisco,USA",
//       traction: ["500K+ social media following", "100K+ recipe database", "50+ countries represented", "Featured in major publications"],
//       looking: [
//         {
//           title: "Seed Funding",
//           amount: "$200K-500K",
//           details: "For marketing and platform development",
//         },
//         {
//           title: "Marketing Partner",
//           details: "Strategic growth and user acquisition",
//         },
//       ],
//       offering: [
//         {
//           title: "Market Position",
//           details: "10+ Years Culinary Expertise",
//         },
//         {
//           title: "Audience",
//           details: "500K+ Social Media Following",
//         },
//         {
//           title: "Network",
//           details: "Established Brand Partnerships",
//         },
//       ],
//       creator: {
//         name: "Aisha Khan",
//         role: "Food Blogger & Recipe Curator",
//         avatar: "/api/placeholder/64/64",
//         responseTime: "2 hours",
//         responseRate: "94%",
//       },
//       metrics: {
//         views: 2467,
//         interested: 47,
//         daysLeft: 2,
//         viewsToday: 342,
//       },
//     },
//     // More spotlights...
//   ];

//   return (
//     <div className="min-h-screen bg-gray-50">
//       {selectedSpotlight ? (
//         <SpotlightDetail spotlight={selectedSpotlight} onBack={() => setSelectedSpotlight(null)} />
//       ) : (
//         <SpotlightList spotlights={spotlights} onSelect={setSelectedSpotlight} />
//       )}
//     </div>
//   );
// }

// // List View Component
// function SpotlightList({ spotlights, onSelect }) {
//   return (
//     <div className="font-sans  min-h-screen">
//       <DashboardHeader />

//       <div className="max-w-5xl mx-auto p-8">
//         <div className="flex justify-between items-center mb-8">
//           <div>
//             <h1 className="text-2xl font-bold">Idea Spotlight</h1>
//             <p className="text-gray-600 mt-1">Showcase Your Idea to 10,000+ Businesses!</p>
//           </div>
//           <button className="bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white px-6 py-3 rounded-xl shadow-lg transition-all duration-200">
//             Post Spotlight
//           </button>
//         </div>

//         <div className="space-y-8">
//           {spotlights.map((spotlight) => (
//             <SpotlightCard key={spotlight.id} spotlight={spotlight} onClick={() => onSelect(spotlight)} />
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// // Card Component
// function SpotlightCard({ spotlight, onClick }) {
//   return (
//     <div
//       className="bg-white rounded-xl shadow-lg overflow-hidden border border-gray-100 hover:shadow-xl transition-all duration-200 cursor-pointer"
//       onClick={onClick}
//     >
//       <div className="relative h-64">
//         <img src={spotlight.image} alt={spotlight.title} className="w-full h-full object-cover" />
//         <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent">
//           <div className="absolute bottom-6 left-6 text-white">
//             <div className="flex items-center space-x-3 mb-3">
//               <span className="bg-amber-500 text-white px-3 py-1 rounded-full text-sm flex items-center">
//                 <Star className="w-4 h-4 mr-1" fill="white" />
//                 Featured
//               </span>
//               <span className="bg-white/20 backdrop-blur-sm px-3 py-1 rounded-full text-sm">{spotlight.category}</span>
//               {spotlight.badge && <span className="bg-blue-500 px-3 py-1 rounded-full text-sm">{spotlight.badge}</span>}
//             </div>
//             <h2 className="text-2xl font-bold mb-1">{spotlight.title}</h2>
//             <p className="text-lg opacity-90">{spotlight.subtitle}</p>
//           </div>
//         </div>
//       </div>

//       <div className="p-6 space-y-6">
//         <div className="flex items-center justify-between">
//           <div className="flex items-center space-x-4">
//             <img
//               src={spotlight.creator.avatar}
//               alt={spotlight.creator.name}
//               className="w-12 h-12 rounded-full ring-4 ring-white shadow-lg"
//             />
//             <div>
//               <p className="font-bold">{spotlight.creator.name}</p>
//               <p className="text-gray-600 text-sm">{spotlight.creator.role}</p>
//             </div>
//           </div>
//           {/* <div className="flex items-center space-x-6">
//             <MetricBadge icon={<Eye className="w-4 h-4" />} value={spotlight.metrics.views} />
//             <MetricBadge icon={<MessageSquare className="w-4 h-4" />} value={spotlight.metrics.interested} />
//             <div className="bg-blue-50 text-blue-700 px-3 py-1 rounded-lg flex items-center">
//               <Clock className="w-4 h-4 mr-1" />
//               <span>{spotlight.metrics.daysLeft}d left</span>
//             </div>
//           </div> */}
//         </div>

//         <div className="grid grid-cols-2 gap-6">
//           <RequirementBox title="Needs" items={spotlight.looking} color="blue" icon={<Target className="w-5 h-5" />} />
//           <RequirementBox title="Offers" items={spotlight.offering} color="green" icon={<Gift className="w-5 h-5" />} />
//         </div>

//         <div className="flex justify-between items-center pt-4 border-t">
//           <div className="flex items-center text-gray-500">
//             <Clock className="w-4 h-4 mr-1" />
//             <span className="text-sm">Responds in {spotlight.creator.responseTime}</span>
//           </div>
//           <button
//             className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
//             onClick={(e) => {
//               e.stopPropagation();
//               // Handle connect action
//             }}
//           >
//             Connect Now
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// // Detail View Component
// function SpotlightDetail({ spotlight, onBack }) {
//   return (
//     <div className="min-h-screen">
//       <nav className="bg-white border-b sticky top-0 z-10">
//         <div className="max-w-5xl mx-auto px-6 py-4 flex items-center justify-between">
//           <button onClick={onBack} className="flex items-center text-gray-600 hover:text-gray-900">
//             <ArrowLeft className="w-5 h-5 mr-2" />
//             Back to Spotlights
//           </button>
//           <div className="flex items-center space-x-4">
//             <ActionButton icon={<Share2 className="w-5 h-5" />} />
//             <ActionButton icon={<Bookmark className="w-5 h-5" />} />
//           </div>
//         </div>
//       </nav>

//       <div className="h-80 relative">
//         <img src={spotlight.image} alt={spotlight.title} className="w-full h-full object-cover" />
//         <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent">
//           <div className="max-w-5xl mx-auto px-6 h-full flex items-end pb-8">
//             <div className="text-white">
//               <div className="flex items-center space-x-3 mb-4">
//                 <Badge color="amber" icon={<Star className="w-4 h-4" />}>
//                   Featured
//                 </Badge>
//                 <Badge color="white" transparent>
//                   {spotlight.category}
//                 </Badge>
//                 {spotlight.badge && <Badge color="blue">{spotlight.badge}</Badge>}
//               </div>
//               <h1 className="text-4xl font-bold mb-2">{spotlight.title}</h1>
//               <p className="text-xl opacity-90">{spotlight.subtitle}</p>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="max-w-5xl mx-auto px-6 py-8">
//         <div className="grid grid-cols-3 gap-8">
//           <div className="col-span-2 space-y-8">
//             <MetricsPanel metrics={spotlight.metrics} />
//             <DescriptionPanel spotlight={spotlight} />
//             <RequirementsPanel looking={spotlight.looking} offering={spotlight.offering} />
//           </div>
//           <div className="space-y-6">
//             <CreatorCard creator={spotlight.creator} />
//             <StatsCard metrics={spotlight.metrics} />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// // Utility Components
// function MetricBadge({ icon, value }) {
//   return (
//     <div className="flex items-center text-gray-500">
//       {icon}
//       <span className="ml-1">{value.toLocaleString()}</span>
//     </div>
//   );
// }

// function RequirementBox({ title, items, color, icon }) {
//   return (
//     <div className={`bg-${color}-50/50 rounded-xl p-6 border border-${color}-100`}>
//       <div className={`flex items-center text-${color}-600 mb-4`}>
//         {icon}
//         <h3 className="font-semibold ml-2">{title}</h3>
//       </div>
//       <ul className="space-y-3">
//         {items.map((item, i) => (
//           <li key={i} className="flex items-start text-gray-700">
//             <span className={`w-2 h-2 bg-${color}-600 rounded-full mt-2 mr-2`}></span>
//             <span>{typeof item === "string" ? item : item.title}</span>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// }

// function ActionButton({ icon }) {
//   return <button className="p-2 hover:bg-gray-100 rounded-full transition-colors">{icon}</button>;
// }

// function Badge({ children, color, transparent, icon }) {
//   const baseClasses = transparent ? "bg-white/20 backdrop-blur-sm" : `bg-${color}-500`;

//   return (
//     <span className={`${baseClasses} text-white px-3 py-1 rounded-full text-sm font-medium flex items-center`}>
//       {icon && <span className="mr-1">{icon}</span>}
//       {children}
//     </span>
//   );
// }

// function MetricsPanel({ metrics }) {
//   return (
//     <div className="bg-white p-6 rounded-xl shadow-sm border">
//       <div className="flex items-center justify-between">
//         <div className="flex items-center space-x-6">
//           <MetricDisplay icon={<Eye className="w-5 h-5 text-gray-400" />} value={metrics.views} label="Views" />
//           <MetricDisplay icon={<MessageSquare className="w-5 h-5 text-gray-400" />} value={metrics.interested} label="Interested" />
//         </div>
//         <div className="bg-blue-50 text-blue-700 px-4 py-2 rounded-lg flex items-center">
//           <Clock className="w-4 h-4 mr-2" />
//           <span>{metrics.daysLeft} days left</span>
//         </div>
//       </div>
//     </div>
//   );
// }

// function MetricDisplay({ icon, value, label }) {
//   return (
//     <div className="flex items-center">
//       {icon}
//       <div className="ml-2">
//         <p className="text-2xl font-bold">{value.toLocaleString()}</p>
//         <p className="text-sm text-gray-500">{label}</p>
//       </div>
//     </div>
//   );
// }

// function DescriptionPanel({ spotlight }) {
//   return (
//     <div className="bg-white p-6 rounded-xl shadow-sm border space-y-6">
//       <h2 className="text-xl font-bold">About the Opportunity</h2>
//       <p className="text-gray-600 leading-relaxed">{spotlight.longDescription}</p>
//       <div className="border-t pt-6 space-y-4">
//         <h3 className="font-medium">Key Highlights:</h3>
//         <ul className="space-y-2 text-gray-600">
//           {spotlight.traction.map((item, i) => (
//             <li key={i} className="flex items-start">
//               <span className="w-2 h-2 bg-blue-600 rounded-full mt-2 mr-2"></span>
//               {item}
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// }

// function RequirementsPanel({ looking, offering }) {
//   return (
//     <div className="grid grid-cols-2 gap-6">
//       <RequirementBox title="Looking For" items={looking} color="blue" icon={<Target className="w-5 h-5" />} />
//       <RequirementBox title="What We Offer" items={offering} color="green" icon={<Gift className="w-5 h-5" />} />
//     </div>
//   );
// }

// function CreatorCard({ creator }) {
//   return (
//     <div className="bg-white p-6 rounded-xl shadow-sm border">
//       <div className="flex items-center space-x-4 mb-6">
//         <img src={creator.avatar} alt={creator.name} className="w-16 h-16 rounded-full ring-4 ring-white shadow-lg" />
//         <div>
//           <h2 className="font-bold text-lg">{creator.name}</h2>
//           <p className="text-gray-600">{creator.role}</p>
//         </div>
//       </div>
//       <button className="w-full bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white py-3 rounded-lg font-medium mb-4 transition-all duration-200">
//         Connect Now
//       </button>
//       <div className="text-sm text-gray-500 flex items-center justify-center">
//         <Clock className="w-4 h-4 mr-1" />
//         Typically responds in {creator.responseTime}
//       </div>
//     </div>
//   );
// }

// function StatsCard({ metrics }) {
//   return (
//     <div className="bg-white p-6 rounded-xl shadow-sm border">
//       <h3 className="font-medium mb-4">Interest Overview</h3>
//       <div className="space-y-4">
//         <StatRow label="Views today" value={metrics.viewsToday} />
//         <StatRow label="Total interested" value={metrics.interested} />
//         <StatRow label="Response rate" value={`${metrics.responseRate}%`} />
//       </div>
//     </div>
//   );
// }

// function StatRow({ label, value }) {
//   return (
//     <div className="flex items-center justify-between text-sm">
//       <span className="text-gray-600">{label}</span>
//       <span className="font-medium">{value}</span>
//     </div>
//   );
// }
// import { ArrowUpRight } from "lucide-react";

// export default function SpotlightLanding() {
//   return (
//     <div className="bg-white min-h-screen">
//       {/* Minimal Navigation */}
//       <nav className="fixed w-full backdrop-blur-lg bg-white/80 z-50">
//         <div className="max-w-screen-xl mx-auto">
//           <div className="flex items-center justify-between h-16 px-8">
//             <div className="text-lg tracking-tight">Spotlight</div>
//             <div className="flex space-x-8 text-sm">
//               <button className="hover:text-emerald-600 transition-colors">About</button>
//               <button className="hover:text-emerald-600 transition-colors">Pricing</button>
//               <button className="bg-black text-white px-4 py-1.5 rounded-full text-sm">Start Free</button>
//             </div>
//           </div>
//         </div>
//       </nav>

//       {/* Hero */}
//       <div className="pt-32 pb-20 px-8">
//         <div className="max-w-screen-xl mx-auto">
//           <h1 className="text-[10rem] font-bold leading-none tracking-tight text-center bg-clip-text text-transparent bg-gradient-to-r from-emerald-600 to-emerald-400">
//             Spotlight
//           </h1>
//           <p className="text-2xl text-center mt-8 tracking-tight max-w-2xl mx-auto text-gray-600">
//             Put your vision in front of 10,000+ investors and partners who can help you grow.
//           </p>
//           <div className="flex justify-center mt-12">
//             <button className="group bg-black text-white px-8 py-4 rounded-full text-sm flex items-center">
//               Get Started
//               <ArrowUpRight className="ml-2 w-4 h-4 group-hover:translate-x-0.5 group-hover:-translate-y-0.5 transition-transform" />
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* Value Props */}
//       <div className="py-32 bg-black">
//         <div className="max-w-screen-xl mx-auto px-8">
//           <div className="grid grid-cols-3 gap-24">
//             <div>
//               <div className="text-white/80 text-sm tracking-wide mb-4">01. Visibility</div>
//               <div className="text-3xl text-white font-medium tracking-tight mb-4">Premium placement in front of decision makers</div>
//               <div className="text-white/60">No more cold emails. Get your business in front of people who matter.</div>
//             </div>
//             <div>
//               <div className="text-white/80 text-sm tracking-wide mb-4">02. Connections</div>
//               <div className="text-3xl text-white font-medium tracking-tight mb-4">Direct access to potential partners</div>
//               <div className="text-white/60">Receive connection requests from interested investors and collaborators.</div>
//             </div>
//             <div>
//               <div className="text-white/80 text-sm tracking-wide mb-4">03. Growth</div>
//               <div className="text-3xl text-white font-medium tracking-tight mb-4">Track engagement and measure results</div>
//               <div className="text-white/60">Get real-time insights into profile views and connection requests.</div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Simple Pricing */}
//       <div className="py-32">
//         <div className="max-w-screen-xl mx-auto px-8">
//           <h2 className="text-4xl font-medium tracking-tight text-center mb-20">Simple pricing for everyone</h2>

//           <div className="grid grid-cols-3 gap-8 max-w-5xl mx-auto">
//             <PricingTier name="Basic" price="59" features={["Standard placement", "Basic analytics", "Up to 50 connections/month"]} />
//             <PricingTier
//               name="Pro"
//               price="95"
//               popular={true}
//               features={["Priority placement", "Advanced analytics", "Unlimited connections", "Monthly email feature"]}
//             />
//             <PricingTier
//               name="Elite"
//               price="194"
//               features={["Top placement", "Custom reports", "Dedicated email blast", "API access", "Concierge service"]}
//             />
//           </div>
//         </div>
//       </div>

//       {/* Final CTA */}
//       <div className="bg-gradient-to-b from-emerald-50 to-white py-32">
//         <div className="max-w-screen-xl mx-auto px-8 text-center">
//           <h2 className="text-4xl font-medium tracking-tight mb-8">Ready to step into the Spotlight?</h2>
//           <p className="text-xl text-gray-600 mb-12 max-w-2xl mx-auto">
//             Join businesses that are getting discovered by the right people every day.
//           </p>
//           <button className="group bg-black text-white px-8 py-4 rounded-full text-sm flex items-center mx-auto">
//             Start Free Trial
//             <ArrowUpRight className="ml-2 w-4 h-4 group-hover:translate-x-0.5 group-hover:-translate-y-0.5 transition-transform" />
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// function PricingTier({ name, price, features, popular }) {
//   return (
//     <div className={`rounded-2xl p-8 ${popular ? "bg-black text-white" : "bg-gray-50"}`}>
//       <div className="text-sm tracking-wide mb-8">{name}</div>
//       <div className="text-4xl font-medium mb-8">
//         ${price}
//         <span className="text-base font-normal opacity-60">/month</span>
//       </div>
//       <ul className="space-y-4">
//         {features.map((feature, i) => (
//           <li key={i} className={popular ? "text-white/60" : "text-gray-600"}>
//             {feature}
//           </li>
//         ))}
//       </ul>
//       <button
//         className={`w-full mt-8 px-6 py-3 rounded-full text-sm
//           ${popular ? "bg-white text-black hover:bg-gray-100" : "bg-black text-white hover:bg-gray-900"} transition-colors`}
//       >
//         Get started
//       </button>
//     </div>
//   );
// }

import { useState } from "react";
import { ArrowUpRight, Target, BarChart2, Users, ChevronDown, Play, ArrowRight, Star, ChevronRight } from "lucide-react";

export default function SpotlightLanding() {
  const [activeFaq, setActiveFaq] = useState(null);

  return (
    <div className="bg-white min-h-screen">
      {/* Announcement Bar */}
      <div className="bg-black text-white py-2 text-center text-sm font-medium">🚀 Introducing Spotlight on FeedbackByAI</div>

      {/* Navigation */}
      <nav className="sticky top-0 backdrop-blur-lg bg-white/80 border-b z-50">
        <div className="max-w-7xl mx-auto px-8 h-16 flex items-center justify-between">
          <span className="text-xl font-semibold">Spotlight</span>
          <div className="flex items-center space-x-8">
            <button className="hover:text-emerald-600 text-sm">How it Works</button>
            <button className="hover:text-emerald-600 text-sm">Pricing</button>
            <button className="hover:text-emerald-600 text-sm">FAQ</button>
            <button className="bg-black hover:bg-gray-900 text-white px-4 py-2 rounded-full text-sm">Create Spotlight</button>
          </div>
        </div>
      </nav>

      {/* Hero */}
      <div className="pt-32 pb-24">
        <div className="max-w-7xl mx-auto px-8">
          <div className="text-center max-w-3xl mx-auto mb-12">
            <h1 className="text-6xl font-bold mb-6">
              {/* Launch Your Business into the */}
              Launch Your Startup to
              <span className="text-emerald-600"> 10,000+ Businesses</span>
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              Transform your business idea into reality with direct access to 10,000+ investors, partners, and customers.
            </p>
            <div className="flex justify-center items-center gap-6">
              <button className="bg-black text-white px-8 py-4 rounded-full text-sm flex items-center group">
                Create Your Spotlight
                <ArrowUpRight className="ml-2 w-4 h-4 group-hover:translate-x-0.5 group-hover:-translate-y-0.5 transition-transform" />
              </button>
              <button className="text-gray-600 hover:text-black px-8 py-4 rounded-full text-sm border border-gray-200 flex items-center group">
                View Sample
                <ArrowUpRight className="ml-2 w-4 h-4 group-hover:translate-x-0.5 group-hover:-translate-y-0.5 transition-transform" />
              </button>
            </div>
          </div>

          {/* Sample Spotlight Preview */}
          <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-xl border border-gray-200 p-8 relative">
            <div className="absolute -top-3 left-8 bg-emerald-100 text-emerald-700 px-3 py-1 rounded-full text-sm">Sample Spotlight</div>
            <div className="flex items-start justify-between mb-8">
              <div className="flex items-center space-x-4">
                <img src="/api/placeholder/80/80" className="w-20 h-20 rounded-xl" />
                <div>
                  <h3 className="text-xl font-bold mb-1">GrowthMetrics AI</h3>
                  <p className="text-gray-600">AI-Powered Analytics Platform</p>
                </div>
              </div>
              <span className="bg-emerald-100 text-emerald-700 px-3 py-1 rounded-full text-sm">Featured</span>
            </div>

            <p className="text-gray-600 mb-8">
              Building the next generation of AI-powered analytics tools to help SaaS companies reduce churn and boost retention through
              predictive insights.
            </p>

            <div className="grid grid-cols-2 gap-8 mb-8">
              <div>
                <h4 className="font-medium mb-4">Looking for</h4>
                <ul className="space-y-3">
                  <li className="flex items-center text-gray-600">
                    <Target className="w-4 h-4 mr-2 text-emerald-600" />
                    $500K Seed Investment
                  </li>
                  <li className="flex items-center text-gray-600">
                    <Target className="w-4 h-4 mr-2 text-emerald-600" />
                    Strategic Partners
                  </li>
                </ul>
              </div>
              <div>
                <h4 className="font-medium mb-4">Traction</h4>
                <ul className="space-y-3">
                  <li className="flex items-center text-gray-600">
                    <BarChart2 className="w-4 h-4 mr-2 text-emerald-600" />
                    1,000+ beta users
                  </li>
                  <li className="flex items-center text-gray-600">
                    <BarChart2 className="w-4 h-4 mr-2 text-emerald-600" />
                    $25K MRR
                  </li>
                </ul>
              </div>
            </div>

            <button className="w-full bg-black text-white px-6 py-3 rounded-lg flex items-center justify-center group">
              View Full Profile
              <ArrowUpRight className="ml-2 w-4 h-4 group-hover:translate-x-0.5 group-hover:-translate-y-0.5 transition-transform" />
            </button>
          </div>
        </div>
      </div>

      {/* Break Through Noise */}
      <div className="py-24 bg-black">
        <div className="max-w-7xl mx-auto px-8">
          <h2 className="text-3xl font-bold text-white text-center mb-16">Ready to Break Through the Noise?</h2>
          <p className="text-xl text-gray-400 text-center max-w-3xl mx-auto">
            Every day, great businesses struggle to reach the right people. Endless cold emails, networking events, and pitch meetings—with
            limited results. Spotlight changes that.
          </p>
        </div>
      </div>

      {/* What is Spotlight */}
      <div className="py-24">
        <div className="max-w-7xl mx-auto px-8">
          <h2 className="text-3xl font-bold text-center mb-16">What is Spotlight?</h2>
          <p className="text-xl text-gray-600 text-center max-w-3xl mx-auto">
            Spotlight is your premium platform for showcasing your business to a curated network of 10,000+ active investors, partners, and
            customers. No more hoping to be discovered—put your vision directly in front of decision-makers who can help you grow.
          </p>
        </div>
      </div>

      {/* Benefits */}
      <div className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-8">
          <h2 className="text-3xl font-bold text-center mb-16">Why Businesses Love Spotlight</h2>
          <div className="grid grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div key={index} className="p-8 bg-white rounded-xl shadow-sm">
                {benefit.icon}
                <h3 className="text-xl font-bold mt-6 mb-4">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* How it Works */}
      <div className="py-24">
        <div className="max-w-7xl mx-auto px-8">
          <h2 className="text-3xl font-bold text-center mb-16">How Spotlight Works</h2>
          <div className="grid grid-cols-3 gap-8">
            <Step number="01" title="Create Your Profile" description="Showcase your vision, traction, and what you're looking for" />
            <Step number="02" title="Get Featured" description="Your business appears prominently across FeedbackByAI" />
            <Step number="03" title="Connect & Grow" description="Receive direct messages from interested partners" />
          </div>
        </div>
      </div>

      {/* Pricing */}
      <div className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-8">
          <h2 className="text-3xl font-bold text-center mb-16">Pricing Plans</h2>
          <div className="grid grid-cols-3 gap-8 max-w-5xl mx-auto">
            <PricingCard name="Basic" price="59" features={["Standard placement", "Basic analytics", "Up to 50 connections/month"]} />
            <PricingCard
              name="Pro"
              price="95"
              popular={true}
              features={["Priority placement", "Advanced analytics", "Monthly email feature", "Unlimited connections"]}
            />
            <PricingCard
              name="Elite"
              price="194"
              features={["Top placement", "Dedicated email blast", "Custom analytics reports", "Concierge service"]}
            />
          </div>
          <p className="text-center mt-12 text-gray-600">Try free for 7 days. No credit card required.</p>
        </div>
      </div>

      {/* FAQ */}
      <div className="py-24">
        <div className="max-w-3xl mx-auto px-8">
          <h2 className="text-3xl font-bold text-center mb-16">Common Questions</h2>
          <div className="space-y-4">
            <FaqItem
              question="What is Spotlight?"
              answer="Spotlight is a new feature on FeedbackByAI that helps startups get discovered by a targeted audience of 10,000+ businesses, including potential investors, partners, and customers."
              isActive={activeFaq === 0}
              onClick={() => setActiveFaq(activeFaq === 0 ? null : 0)}
            />
            <FaqItem
              question="How does Spotlight work?"
              answer="Simply create a profile highlighting your startup, and we'll feature it prominently on our platform. You'll then be able to connect with businesses who are interested in collaborating with you."
              isActive={activeFaq === 1}
              onClick={() => setActiveFaq(activeFaq === 1 ? null : 1)}
            />
            <FaqItem
              question="What's included in the free trial?"
              answer="You get full access to all features for 7 days. Create your profile, get featured, and start receiving connections. No credit card required to start."
              isActive={activeFaq === 2}
              onClick={() => setActiveFaq(activeFaq === 2 ? null : 2)}
            />
          </div>
        </div>
      </div>

      {/* Final CTA */}
      <div className="py-24 bg-black">
        <div className="max-w-7xl mx-auto px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-8">Ready to Step into the Spotlight?</h2>
          <p className="text-xl text-gray-400 mb-12">Join businesses that are getting discovered by the right people every day.</p>
          <div className="flex justify-center items-center gap-6">
            <button className="bg-white text-black px-8 py-4 rounded-full text-sm flex items-center group">
              Start Free Trial
              <ArrowUpRight className="ml-2 w-4 h-4 group-hover:translate-x-0.5 group-hover:-translate-y-0.5 transition-transform" />
            </button>
            <button className="text-white hover:text-gray-300 px-8 py-4 rounded-full text-sm border border-white/20 flex items-center group">
              View Sample
              <ArrowUpRight className="ml-2 w-4 h-4 group-hover:translate-x-0.5 group-hover:-translate-y-0.5 transition-transform" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

// const benefits = [
//   {
//     title: "Premium Placement",
//     description: "Your business featured prominently for 7 days",
//     icon: <Star className="w-6 h-6 text-emerald-600" />,
//   },
//   {
//     title: "Smart Matching",
//     description: "Our AI connects you with the most relevant partners",
//     icon: <Target className="w-6 h-6 text-emerald-600" />,
//   },
//   {
//     title: "Real-time Analytics",
//     description: "Track views, engagement, and connection requests",
//     icon: <BarChart2 className="w-6 h-6 text-emerald-600" />,
//   },
//   {
//     title: "Direct Access",
//     description: "Connect instantly with interested partners",
//     icon: <Users className="w-6 h-6 text-emerald-600" />,
//   },
//   {
//     title: "Verified Network",
//     description: "Access our pre-vetted community of 10,000+ businesses",
//     icon: <ChevronRight className="w-6 h-6 text-emerald-600" />,
//   },
// ];

const Step = ({ number, title, description }) => (
  <div>
    <div className="text-emerald-600 font-bold mb-4">{number}</div>
    <h3 className="text-xl font-bold mb-4">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const PricingCard = ({ name, price, features, popular }) => (
  <div className={`rounded-xl p-8 ${popular ? "bg-black text-white ring-2 ring-black" : "bg-white"}`}>
    <div className="text-sm tracking-wide mb-8">{name}</div>
    <div className="text-4xl font-bold mb-8">
      ${price}
      <span className="text-base font-normal opacity-60">/month</span>
    </div>
    <ul className="space-y-4 mb-8">
      {features.map((feature, i) => (
        <li key={i} className={popular ? "text-gray-400" : "text-gray-600"}>
          {feature}
        </li>
      ))}
    </ul>
    <button
      className={`w-full py-3 rounded-lg transition-colors ${
        popular ? "bg-white text-black hover:bg-gray-100" : "bg-black text-white hover:bg-gray-900"
      }`}
    >
      Start Free Trial
    </button>
  </div>
);

const FaqItem = ({ question, answer, isActive, onClick }) => (
  <div className="border-b border-gray-200">
    <button className="w-full py-6 flex items-center justify-between text-left" onClick={onClick}>
      <span className="font-medium">{question}</span>
      <ChevronDown className={`w-5 h-5 transition-transform ${isActive ? "rotate-180" : ""}`} />
    </button>
    {isActive && <div className="pb-6 text-gray-600">{answer}</div>}
  </div>
);

// Navigation Link component
const NavLink = ({ children }) => <button className="hover:text-emerald-600 text-sm transition-colors">{children}</button>;

// Sample data for benefits grid
export const benefits = [
  {
    title: "Premium Placement",
    description: "Your business featured prominently for 7 days",
    icon: <Star className="w-6 h-6 text-emerald-600" />,
  },
  {
    title: "Targeted Exposure",
    description: "Reach investors actively seeking opportunities like yours",
    icon: <Target className="w-6 h-6 text-emerald-600" />,
  },
  {
    title: "Smart Matching",
    description: "Our AI connects you with the most relevant partners",
    icon: <Users className="w-6 h-6 text-emerald-600" />,
  },
  {
    title: "Real-time Analytics",
    description: "Track views, engagement, and connection requests",
    icon: <BarChart2 className="w-6 h-6 text-emerald-600" />,
  },
  {
    title: "Direct Access",
    description: "Connect instantly with interested partners",
    icon: <ArrowRight className="w-6 h-6 text-emerald-600" />,
  },
  {
    title: "Verified Network",
    description: "Access our pre-vetted community of 10,000+ businesses",
    icon: <ChevronRight className="w-6 h-6 text-emerald-600" />,
  },
];
