import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Edit2,
  Check,
  Star,
  PhoneCall,
  ArrowRight,
  Handshake,
  Trophy,
  Sparkles,
  Bell,
  Loader2,
  Send,
  ArrowLeft,
  Coins,
  Clock,
} from "lucide-react";
import api from "./axiosConfig";
import Modal from "react-modal";
import ReactConfetti from "react-confetti";

const TimeStatus = ({ remainingTime }) => (
  <div className="sticky top-[120px] bg-white border-b z-10">
    <div className="max-w-7xl mx-auto px-6 py-3 flex justify-between items-center">
      <div className="flex items-center gap-4">
        <div className="text-sm font-medium">Time Left</div>
        <div className="px-3 py-1 bg-yellow-50 text-yellow-700 rounded-lg flex items-center gap-2">
          <Clock className="w-4 h-4" />
          {remainingTime}
        </div>
      </div>
    </div>
  </div>
);

const WildCardButtons = ({ onWildcardAction, actionsDisabled, progressState }) => (
  <div className="grid grid-cols-2 md:grid-cols-2 gap-3 mb-6">
    {[
      { type: "I'm your Customer", icon: Star, color: "purple", label: "I'm your Customer" },
      //   { type: "Call Me First", icon: PhoneCall, color: "green", label: "Call Me First" },
      { type: "Partner With Me", icon: Handshake, color: "blue", label: "Partner With Me" },
      //   { type: "Notify at Launch", icon: Bell, color: "yellow", label: "Notify at Launch" },
    ].map(({ type, icon: Icon, color, label }) => (
      <button
        key={type}
        onClick={() => onWildcardAction(type)}
        disabled={actionsDisabled}
        className={`bg-${color}-50 text-${color}-700 p-3 rounded-lg flex flex-col items-center gap-1 
            transform transition-all duration-300 ${
              actionsDisabled ? "opacity-50 cursor-not-allowed" : `hover:bg-${color}-100 hover:scale-105 hover:shadow-lg`
            }`}
      >
        <Icon className={`w-5 h-5 ${!actionsDisabled && progressState < 50 ? "animate-bounce" : ""}`} />
        <span className="text-sm font-medium">{label}</span>
      </button>
    ))}
  </div>
);
export default function EditTest() {
  const { testId } = useParams();
  const [testData, setTestData] = useState({
    title: "",
    description: "",
    features: [],
    pricing: "",
    image: "",
  });
  const [actionsSubmitted, setActionsSubmitted] = useState(false); // For wildcard buttons
  const [suggestionsSubmitted, setSuggestionsSubmitted] = useState(false); // For suggestions button

  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [modalInput, setModalInput] = useState("");
  const [currentCredits, setCurrentCredits] = useState(0);
  const [remainingTime, setRemainingTime] = useState("24h");
  const [progress, setProgress] = useState(0);
  const [tipInput, setTipInput] = useState("");
  // Add these states after your other state declarations
  const [showConfetti, setShowConfetti] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [progressStage, setProgressStage] = useState("start"); // 'start', 'halfway', 'complete'
  const [showSuccess, setShowSuccess] = useState(false);
  const [showAiReview, setShowAiReview] = useState(false);
  const [pointsEarned, setPointsEarned] = useState(0);
  const [pointsNeeded, setPointsNeeded] = useState(200); // For free test
  const [score, setScore] = useState(null);
  const [reason, setReason] = useState("");
  const [isAccepted, setIsAccepted] = useState(false);
  const [rejectionMessage, setRejectionMessage] = useState(""); // Add this line to your state declarations
  const [profileComplete, setProfileComplete] = useState(false);
  const [showProfileTooltip, setShowProfileTooltip] = useState(false);

  const [profileData, setProfileData] = useState({}); // Initialize as an empty object

  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  const fetchProfile = async () => {
    try {
      const response = await api.get("/profile/");
      const profile = response.data.profile || {}; // Default to empty object
      setProfileData(profile);
      // Check if any profile field is empty
      const isProfileIncomplete =
        !profile.first_name || !profile.last_name || !profile.company_name || !profile.company_size || !profile.role || !profile.work_email;
      setIsProfileModalOpen(isProfileIncomplete); // Open modal if incomplete
    } catch (err) {
      console.error("Error fetching profile data:", err);
      setError("Failed to fetch profile data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile(); // Fetch profile on component mount
  }, []);

  // Handle window resize for confetti
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Track progress changes
  useEffect(() => {
    if (progress === 100) {
      setProgressStage("complete");
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 6000);
    } else if (progress === 50) {
      setProgressStage("halfway");
      // Short celebratory confetti
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 3000);
    }
  }, [progress]);

  //   useEffect(() => {
  //     const fetchTestData = async () => {
  //       try {
  //         const response = await api.get(`/landing-pages/${testId}/`);
  //         const parsedData = JSON.parse(response.data.data);

  //         setTestData({
  //           title: parsedData.title || "Untitled Test",
  //           description: parsedData.description || "No description available",
  //           features: parsedData.features || [],
  //           pricing: parsedData.pricing || "Free",
  //           image: response.data.image || null,
  //         });

  //         setActionsSubmitted(response.data.actions_submitted);
  //         setSuggestionsSubmitted(response.data.suggestions_submitted);
  //       } catch (error) {
  //         console.error("Error fetching test data:", error);
  //       } finally {
  //         setLoading(false);
  //       }
  //     };

  //     fetchTestData();
  //   }, [testId]);

  useEffect(() => {
    const fetchTestData = async () => {
      try {
        const response = await api.get(`/landing-pages/${testId}/`);

        // Handle the parsing of `data`
        let parsedData = {};
        if (typeof response.data.data === "string") {
          try {
            parsedData = JSON.parse(response.data.data);
          } catch (e) {
            console.error("Error parsing test data:", e);
            parsedData = {};
          }
        } else {
          parsedData = response.data.data || {};
        }

        // Set test data with fallback values
        setTestData({
          title: parsedData.title || "Untitled Test",
          description: parsedData.description || "No description available.",
          features: parsedData.features || [],
          pricing: parsedData.pricing || "Free",
          image: response.data.image || null,
        });

        // Set other states based on response
        setActionsSubmitted(response.data.actions_submitted || false);
        setSuggestionsSubmitted(response.data.suggestions_submitted || false);
      } catch (error) {
        console.error("Error fetching test data:", error);
        setError("Failed to load test data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchTestData();
  }, [testId]);

  const openModal = (actionType) => {
    setSelectedAction(actionType);
    setModalInput("");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAction(null);
    setModalInput("");
  };

  // Add this to track combined progress
  useEffect(() => {
    if (suggestionsSubmitted && actionsSubmitted) {
      setProgress(100);
    } else if (suggestionsSubmitted || actionsSubmitted) {
      setProgress(50);
    }
  }, [suggestionsSubmitted, actionsSubmitted]);

  const handleModalSubmit = async () => {
    try {
      setShowAiReview(true); // Start loader
      const endpoint = `/landing-pages/${testId}/wildcards/`;
      const payload = {
        action_data: {
          action: selectedAction,
          details: modalInput,
        },
      };

      const response = await api.post(endpoint, payload);
      const { is_accepted, score, explanation } = response.data;

      setShowAiReview(false); // Stop loader

      if (!is_accepted) {
        setIsAccepted(false);
        setRejectionMessage(explanation || "Your submission was not accepted. Please refine your details.");
      } else {
        setIsAccepted(true);
        setRejectionMessage("");
        setScore(score);
        setShowConfetti(true);
        setShowSuccess(true);

        const newProgress = suggestionsSubmitted ? 100 : 50;
        setProgress(newProgress);

        setTimeout(() => setShowSuccess(false), 3000);
        setTimeout(() => setShowConfetti(false), 4000);
      }

      setActionsSubmitted(true);
      closeModal();
    } catch (error) {
      console.error("Error performing wildcard action:", error);
      setRejectionMessage("An error occurred while processing your request. Please try again.");
      setShowAiReview(false);
    }
  };

  const handleSubmitSuggestions = async () => {
    try {
      setShowAiReview(true);

      const payload = {
        suggestions: tipInput, // Suggestions
        title: testData.title, // Title from test data
        description: testData.description, // Description from test data
        features: testData.features, // Features array from test data
        pricing: testData.pricing, // Pricing from test data
      };

      const response = await api.post(`/landing-pages/${testId}/feedbacks/`, payload);

      const { is_accepted, score, reason } = response.data;

      if (!is_accepted) {
        setIsAccepted(false);
        setRejectionMessage(reason || "Your feedback was not accepted. Please try again.");
      } else {
        setShowAiReview(false);
        setShowConfetti(true);
        setShowSuccess(true);

        setIsAccepted(true);
        setRejectionMessage("");
        setScore(score);
        setIsEditing(false);
        setSuggestionsSubmitted(true);

        const newProgress = actionsSubmitted ? 100 : 50;
        setProgress(newProgress);

        setTimeout(() => setShowSuccess(false), 3000);
        setTimeout(() => setShowConfetti(false), 4000);
      }

      setShowAiReview(false);
    } catch (error) {
      console.error("Error submitting suggestions:", error);
      setRejectionMessage("An error occurred. Please try again.");
      setShowAiReview(false);
    }
  };

  // const handleSubmitSuggestions = async () => {
  //   try {
  //     setShowAiReview(true);
  //     const response = await api.post(`/landing-pages/${testId}/feedbacks/`, {
  //       suggestions: tipInput,
  //     });

  //     const { is_accepted, score, reason } = response.data;

  //     if (!is_accepted) {
  //       setIsAccepted(false);
  //       setRejectionMessage(reason || "Your feedback was not accepted. Please try again.");
  //     } else {
  //       // Hide AI review first
  //       setShowAiReview(false);

  //       // Show success state
  //       setShowConfetti(true);
  //       setShowSuccess(true);

  //       // Update all other states
  //       setIsAccepted(true);
  //       setRejectionMessage("");
  //       setScore(score);
  //       setIsEditing(false);
  //       setSuggestionsSubmitted(true);

  //       // Update progress
  //       const newProgress = actionsSubmitted ? 100 : 50;
  //       setProgress(newProgress);

  //       // Clean up animations
  //       setTimeout(() => setShowSuccess(false), 3000);
  //       setTimeout(() => setShowConfetti(false), 4000);
  //     }

  //     setShowAiReview(false);
  //   } catch (error) {
  //     console.error("Error submitting suggestions:", error);
  //     setRejectionMessage("An error occurred. Please try again.");
  //     setShowAiReview(false);
  //   }
  // };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  if (loading) {
    return <div className="text-center py-6">Loading...</div>;
  }

  // Handle input changes

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("/profile/", profileData);
      alert("Profile updated successfully!");
      setIsEditing(false); // Close modal
      await fetchProfile(); // Fetch the latest profile data
    } catch (err) {
      console.error("Error updating profile:", err);
      setError("Failed to update profile. Please try again.");
    }
  };

  // Render profile form if incomplete

  return (
    <div className="min-h-screen bg-[#FAFAFA]">
      {isProfileModalOpen && profileData && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
            <h2 className="text-xl font-bold mb-4">Complete Your Profile</h2>
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-2">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  value={profileData.first_name}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  value={profileData.last_name}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Company Name</label>
                <input
                  type="text"
                  name="company_name"
                  value={profileData.company_name}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Company Size</label>
                <select
                  name="company_size"
                  value={profileData.company_size}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg"
                  required
                >
                  <option value="">Select Size</option>
                  <option value="1-10 employees">1-10 employees</option>
                  <option value="11-50 employees">11-50 employees</option>
                  <option value="51-200 employees">51-200 employees</option>
                  <option value="201-1000 employees">201-1000 employees</option>
                  <option value="1000+ employees">1000+ employees</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Role</label>
                <input
                  type="text"
                  name="role"
                  value={profileData.role}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Work Email</label>
                <input
                  type="email"
                  name="work_email"
                  value={profileData.work_email}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg"
                  required
                />
              </div>
              <div className="flex gap-4">
                <button type="submit" className="bg-blue-600 text-white py-2 px-4 rounded-lg">
                  Save
                </button>
                <button
                  type="button"
                  onClick={() => setIsProfileModalOpen(false)} // Close modal
                  className="bg-gray-300 text-black py-2 px-4 rounded-lg"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showConfetti && (
        <ReactConfetti
          width={windowSize.width}
          height={windowSize.height}
          recycle={false}
          numberOfPieces={progress === 100 ? 200 : 100}
          gravity={0.2}
          colors={["#0A84FF", "#30C48D", "#5E5CE6"]}
          tweenDuration={6000}
        />
      )}
      {/* Top Navigation */}
      <nav className="fixed top-0 inset-x-0 h-16 bg-white/70 backdrop-blur-2xl z-50 border-b border-gray-100">
        <div className="h-full max-w-[1200px] mx-auto px-8 flex items-center justify-between">
          <div className="flex items-center gap-6">
            <a href="/" className="text-xl tracking-tight font-semibold">
              feedback
            </a>
            <a href="/better/" className="flex items-center gap-2 text-sm text-gray-500 hover:text-gray-900 transition-all">
              <ArrowLeft className="w-4 h-4" />
              Back
            </a>
          </div>
          <div className="flex items-center gap-4">
            <div className="h-8 flex items-center gap-2 px-3 bg-[#F5F5F7] text-[#1D1D1F] rounded-lg text-sm">
              <Clock className="w-4 h-4" />
              {remainingTime}
            </div>
            <div className="h-8 flex items-center gap-2 px-3 bg-blue-50 text-blue-600 rounded-lg text-sm font-medium">
              <Trophy className="w-4 h-4" />
              25-40 pts per review
            </div>
          </div>
        </div>
      </nav>
      {/* Context Bar */}
      <div className="fixed top-16 inset-x-0 h-[72px] bg-[#090909] z-40">
        <div className="h-full max-w-[1200px] mx-auto px-8 flex items-center justify-between">
          <div>
            <div className="text-sm text-[#A0A0A0]">Currently Reviewing</div>
            <h1 className="mt-1 text-lg font-medium text-white">{testData.title}</h1>
          </div>
          <div className="flex items-center gap-8">
            <div className="flex items-center gap-4">
              <div className="h-1 w-24 bg-[#232323] rounded-full overflow-hidden">
                <div className="h-full bg-[#0A84FF] transition-all duration-700" style={{ width: `${progress}%` }} />
              </div>
              <div className="text-sm text-[#A0A0A0]">{progress}%</div>
            </div>
            <button
              onClick={() => setIsEditing(true)}
              disabled={suggestionsSubmitted}
              className={`h-10 px-5 rounded-lg text-sm font-medium transition-all
                  ${suggestionsSubmitted ? "bg-[#232323] text-[#525252]" : "bg-white text-black hover:bg-[#F5F5F7]"}`}
            >
              Give Feedback
            </button>
          </div>
        </div>
      </div>
      {/* Main Content */}
      <main className="pt-[120px] min-h-screen">
        <div className="max-w-[1200px] mx-auto px-8 py-12">
          <div className="grid lg:grid-cols-[320px,1fr] gap-8">
            {/* Actions Sidebar */}
            <div className="space-y-6">
              <div className="p-6 bg-[#090909] rounded-2xl">
                <div className="mb-6">
                  <h3 className="text-white font-medium">Power Actions</h3>
                  <p className="mt-1 text-sm text-[#A0A0A0]">Signal your interest while improving</p>
                </div>
                <div className="grid gap-3">
                  <button
                    onClick={() => openModal("I'm your Customer")}
                    disabled={actionsSubmitted}
                    className={`group w-full p-4 rounded-xl flex items-center gap-3 transition-all
                        ${actionsSubmitted ? "bg-[#1C1C1E] text-[#525252]" : "bg-[#1C1C1E] text-white hover:bg-[#2C2C2E]"}`}
                  >
                    <div className="w-8 h-8 rounded-lg bg-[#0A84FF]/10 flex items-center justify-center group-hover:bg-[#0A84FF]/20 transition-colors">
                      <Star className="w-4 h-4 text-[#0A84FF]" />
                    </div>
                    <span className="font-medium">I'm Interested</span>
                    <div className="ml-auto flex items-center text-sm text-[#0A84FF]">+30 pts</div>
                  </button>
                  <button
                    onClick={() => openModal("Partner With Me")}
                    disabled={actionsSubmitted}
                    className={`group w-full p-4 rounded-xl flex items-center gap-3 transition-all
                        ${actionsSubmitted ? "bg-[#1C1C1E] text-[#525252]" : "bg-[#1C1C1E] text-white hover:bg-[#2C2C2E]"}`}
                  >
                    <div className="w-8 h-8 rounded-lg bg-[#5E5CE6]/10 flex items-center justify-center group-hover:bg-[#5E5CE6]/20 transition-colors">
                      <Handshake className="w-4 h-4 text-[#5E5CE6]" />
                    </div>
                    <span className="font-medium">Let's Partner</span>
                    <div className="ml-auto flex items-center text-sm text-[#5E5CE6]">+30 pts</div>
                  </button>
                </div>
              </div>

              {!isEditing && (
                <button
                  onClick={() => setIsEditing(true)}
                  disabled={suggestionsSubmitted}
                  className={`w-full h-[52px] rounded-xl flex items-center justify-center gap-2 transition-all
                      ${suggestionsSubmitted ? "bg-[#F5F5F7] text-[#A0A0A0]" : "bg-white text-[#1D1D1F] hover:bg-[#F5F5F7] shadow-sm"}`}
                >
                  <Edit2 className="w-4 h-4" />
                  <span className="font-medium">Review & Earn 25-40 pts</span>
                </button>
              )}
            </div>

            {/* Main Preview/Edit Area */}
            <div className="bg-white rounded-2xl shadow-sm border border-[#E5E7EB]">
              {/* Product Preview Section */}
              {!isEditing ? (
                <div>
                  <div className="aspect-video w-full bg-[#F5F5F7] rounded-t-2xl overflow-hidden">
                    {testData.image ? (
                      <img src={encodeURI(testData.image)} alt={testData.title} className="w-full h-full object-cover" />
                    ) : (
                      <div className="h-full flex items-center justify-center">
                        <p className="text-[#A0A0A0]">Preview image coming soon</p>
                      </div>
                    )}
                  </div>

                  <div className="p-8 space-y-12">
                    <div className="max-w-2xl">
                      <h2 className="text-3xl font-semibold text-[#1D1D1F]">{testData.title}</h2>
                      <p className="mt-4 text-xl leading-relaxed text-[#424245]">{testData.description}</p>
                    </div>

                    <div className="space-y-6">
                      <h3 className="text-xl font-medium text-[#1D1D1F]">Key Features</h3>
                      <ul className="grid gap-4">
                        {testData.features.map((feature, index) => (
                          <li key={index} className="flex items-start gap-4 group">
                            <div className="mt-1 w-8 h-8 rounded-full bg-[#F5F5F7] flex items-center justify-center group-hover:bg-[#E5E7EB] transition-colors">
                              <Check className="w-4 h-4 text-[#1D1D1F]" />
                            </div>
                            <span className="text-[#424245] leading-relaxed">{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <button className="w-full h-14 bg-[#1D1D1F] text-white rounded-xl font-medium hover:bg-black transition-colors">
                      {testData.pricing}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="p-8 space-y-8">
                  <div>
                    <h3 className="text-xl font-medium text-[#1D1D1F] mb-1">Suggest Improvements</h3>
                    <p className="text-[#666]">Help optimize this product page</p>
                  </div>

                  {!isAccepted && rejectionMessage && (
                    <p className="text-sm text-red-600 mb-4">
                      <strong>Low-Quality Feedback:</strong> {rejectionMessage}
                    </p>
                  )}
                  {isAccepted && score > 10 && (
                    <p className="text-sm text-green-600 mb-4">
                      <strong>High-Quality Feedback:</strong> {reason}. <br /> +{score} Points
                    </p>
                  )}
                  {isAccepted && score <= 10 && (
                    <p className="text-sm text-yellow-600 mb-4">
                      <strong>Medium-Quality Feedback:</strong> {reason}. <br /> +{score} Points
                    </p>
                  )}

                  {/* Business Tips Section - Made More Prominent */}
                  <div className="p-6 bg-blue-50 rounded-xl space-y-4">
                    <div className="flex items-center gap-3">
                      <div className="w-8 h-8 rounded-lg bg-blue-100 flex items-center justify-center">
                        <Trophy className="w-4 h-4 text-blue-600" />
                      </div>
                      <div>
                        <h4 className="font-medium text-blue-900">Business Insights (40 pts)</h4>
                        <p className="text-sm text-blue-700">Share valuable business advice</p>
                      </div>
                    </div>
                    <textarea
                      className="w-full p-4 bg-white border-0 rounded-lg focus:ring-2 focus:ring-blue-500 resize-none"
                      rows={4}
                      value={tipInput}
                      onChange={(e) => setTipInput(e.target.value)}
                      placeholder="Share strategic insights that could improve this business. High-quality business advice earns expert points!"
                    />
                  </div>

                  <div className="space-y-6">
                    <div className="space-y-4">
                      <label className="block text-sm font-medium text-[#1D1D1F]">Title</label>
                      <input
                        type="text"
                        className="w-full h-11 px-4 bg-[#F5F5F7] rounded-lg focus:ring-2 focus:ring-[#0A84FF] transition-all"
                        value={testData.title}
                        onChange={(e) => setTestData({ ...testData, title: e.target.value })}
                      />
                    </div>

                    <div className="space-y-4">
                      <label className="block text-sm font-medium text-[#1D1D1F]">Description</label>
                      <textarea
                        className="w-full p-4 bg-[#F5F5F7] rounded-lg focus:ring-2 focus:ring-[#0A84FF] transition-all"
                        rows={4}
                        value={testData.description}
                        onChange={(e) => setTestData({ ...testData, description: e.target.value })}
                      />
                    </div>

                    <div className="space-y-4">
                      <label className="block text-sm font-medium text-[#1D1D1F]">Features</label>
                      {testData.features.map((feature, index) => (
                        <input
                          key={index}
                          type="text"
                          className="w-full h-11 px-4 bg-[#F5F5F7] rounded-lg focus:ring-2 focus:ring-[#0A84FF] transition-all"
                          value={feature}
                          onChange={(e) => {
                            const newFeatures = [...testData.features];
                            newFeatures[index] = e.target.value;
                            setTestData({ ...testData, features: newFeatures });
                          }}
                        />
                      ))}
                    </div>

                    <div className="space-y-4">
                      <label className="block text-sm font-medium text-[#1D1D1F]">Pricing</label>
                      <input
                        type="text"
                        className="w-full h-11 px-4 bg-[#F5F5F7] rounded-lg focus:ring-2 focus:ring-[#0A84FF] transition-all"
                        value={testData.pricing}
                        onChange={(e) => setTestData({ ...testData, pricing: e.target.value })}
                      />
                    </div>
                  </div>

                  <div className="flex gap-3 pt-4">
                    <button
                      onClick={handleSubmitSuggestions}
                      disabled={showAiReview}
                      className={`w-full h-11 rounded-lg font-medium transition-all ${
                        showAiReview ? "bg-gray-300 text-gray-500 cursor-not-allowed" : "bg-blue-600 text-white hover:bg-blue-700"
                      }`}
                    >
                      {showAiReview ? "Submitting..." : "Submit Feedback"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      {/* Wild Card Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="fixed inset-0 flex items-center justify-center p-8 z-[1050]"
        overlayClassName="fixed inset-0 bg-black/30 backdrop-blur-xl z-[1040]"
      >
        <div className="w-full max-w-lg bg-white rounded-2xl shadow-2xl">
          <div className="p-8">
            <div className="flex items-center justify-between mb-6">
              <div>
                <h2 className="text-2xl font-semibold text-[#1D1D1F]">
                  {selectedAction === "I'm your Customer" ? "Express Your Interest" : "Propose Partnership"}
                </h2>
                <p className="mt-1 text-[#666]">
                  {selectedAction === "I'm your Customer"
                    ? "Let us know how this product fits your needs"
                    : "Share your partnership vision"}
                </p>
              </div>
              <div className="flex items-center gap-2 px-3 py-1.5 bg-blue-50 text-blue-600 rounded-full text-sm">
                <Trophy className="w-4 h-4" />
                <span>+30 pts after AI review</span>
              </div>
            </div>

            <div className="space-y-6">
              {selectedAction === "I'm your Customer" ? (
                <div className="space-y-4">
                  <div className="space-y-3">
                    <label className="block text-sm font-medium text-[#1D1D1F]">How would this solve your needs?</label>
                    <textarea
                      className="w-full p-4 bg-[#F5F5F7] rounded-xl focus:ring-2 focus:ring-[#0A84FF] transition-all resize-none"
                      placeholder="We currently struggle with... This would help us by..."
                      rows={3}
                      value={modalInput}
                      onChange={(e) => setModalInput(e.target.value)}
                    />
                  </div>
                </div>
              ) : (
                <div className="space-y-4">
                  <div className="space-y-3">
                    <label className="block text-sm font-medium text-[#1D1D1F]">What kind of partnership do you envision?</label>
                    <textarea
                      className="w-full p-4 bg-[#F5F5F7] rounded-xl focus:ring-2 focus:ring-[#0A84FF] transition-all resize-none"
                      placeholder="We could collaborate on... This would benefit both parties by..."
                      rows={3}
                      value={modalInput}
                      onChange={(e) => setModalInput(e.target.value)}
                    />
                  </div>
                </div>
              )}

              {/* Add this profile-sharing notice */}
              <div className="text-sm text-gray-600 bg-gray-50 p-4 rounded-lg">
                <p>
                  <strong>Note:</strong> Your profile will be shared with the business owner.
                </p>
                {!isEditing ? (
                  <div className="mt-4">
                    {profileData ? (
                      <>
                        <p>
                          <strong>Name:</strong> {profileData?.first_name || "N/A"}
                          {profileData.last_name || "N/A"}
                        </p>
                        <p>
                          <strong>Company:</strong> {profileData?.company_name || "N/A"}
                        </p>
                        <p>
                          <strong>Size:</strong> {profileData?.company_size || "N/A"}
                        </p>
                        <p>
                          <strong>Role:</strong> {profileData?.role || "N/A"}
                        </p>
                        <p>
                          <strong>Email:</strong> {profileData?.work_email || "N/A"}
                        </p>
                      </>
                    ) : (
                      <p>No profile information available.</p>
                    )}
                    <button
                      className="mt-4 text-blue-600 hover:underline"
                      onClick={() => setIsEditing(true)} // Toggle to edit mode
                    >
                      Edit Profile
                    </button>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit} className="mt-4 space-y-4">
                    <div>
                      <label className="block text-sm font-medium">First Name</label>
                      <input
                        type="text"
                        name="first_name"
                        value={profileData?.first_name}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-lg"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium">Last Name</label>
                      <input
                        type="text"
                        name="last_name"
                        value={profileData?.last_name}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-lg"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium">Company Name</label>
                      <input
                        type="text"
                        name="company_name"
                        value={profileData?.company_name}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-lg"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium">Company Size</label>
                      <select
                        name="company_size"
                        value={profileData?.company_size}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-lg"
                        required
                      >
                        <option value="">Select Size</option>
                        <option value="1-10 employees">1-10 employees</option>
                        <option value="11-50 employees">11-50 employees</option>
                        <option value="51-200 employees">51-200 employees</option>
                        <option value="201-1000 employees">201-1000 employees</option>
                        <option value="1000+ employees">1000+ employees</option>
                      </select>
                    </div>
                    <div>
                      <label className="block text-sm font-medium">Role</label>
                      <input
                        type="text"
                        name="role"
                        value={profileData?.role}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-lg"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium">Work Email</label>
                      <input
                        type="email"
                        name="work_email"
                        value={profileData?.work_email}
                        onChange={handleChange}
                        className="w-full p-2 border rounded-lg"
                        required
                      />
                    </div>
                    <div className="flex justify-end gap-2">
                      <button
                        type="button"
                        onClick={() => setIsEditing(false)} // Cancel edit mode
                        className="bg-gray-300 text-black py-2 px-4 rounded-lg"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="bg-blue-600 text-white py-2 px-4 rounded-lg">
                        Save Changes
                      </button>
                    </div>
                  </form>
                )}
              </div>

              <div className="pt-4">
                <div className="pt-4">
                  <button
                    onClick={handleModalSubmit}
                    disabled={showAiReview} // Disable button while validating
                    className={`w-full h-12 flex items-center justify-center gap-2 rounded-xl font-medium transition-all ${
                      showAiReview ? "bg-gray-300 text-gray-500 cursor-not-allowed" : "bg-[#0A84FF] text-white hover:bg-[#0070E0]"
                    }`}
                  >
                    {showAiReview ? <Loader2 className="w-5 h-5 animate-spin" /> : <ArrowRight className="w-4 h-4" />}
                    <span>{showAiReview ? "Submitting..." : "Submit for AI Review"}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="px-8 py-4 bg-[#F5F5F7] rounded-b-2xl border-t border-[#E5E7EB]">
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2 text-sm text-[#666]">
                <Sparkles className="w-4 h-4 text-blue-600" />
                <span>AI verifies all submissions for quality</span>
              </div>
              <div className="h-4 w-px bg-[#E5E7EB]" />
              <div className="text-sm text-[#666]">Points awarded instantly</div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Success Notifications */}
      {showSuccess && (
        <div className="fixed bottom-8 right-8 animate-fade-in">
          <div className="bg-green-50 backdrop-blur-xl rounded-2xl shadow-lg p-6 flex items-center gap-4">
            <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center">
              <Trophy className="w-6 h-6 text-green-600 animate-bounce" />
            </div>
            <div>
              <h3 className="font-medium text-green-800 text-lg">Amazing Feedback!</h3>
              <p className="text-green-600">+{score} points earned ✨</p>
            </div>
          </div>
        </div>
      )}
      {/* AI Review Progress */}
      {showAiReview && (
        <div className="fixed bottom-8 right-8 animate-fade-in">
          <div className="bg-white/80 backdrop-blur-xl rounded-2xl shadow-lg p-6">
            <div className="flex items-center gap-4 mb-3">
              <div className="w-10 h-10 bg-blue-50 rounded-full flex items-center justify-center">
                <Sparkles className="w-5 h-5 text-blue-600 animate-pulse" />
              </div>
              <div>
                <h3 className="font-medium text-[#1D1D1F]">AI Review in Progress</h3>
                <p className="text-sm text-[#666]">Analyzing your submission...</p>
              </div>
            </div>
            <div className="h-1 w-full bg-[#F5F5F7] rounded-full overflow-hidden">
              <div className="h-full bg-blue-600 transition-all duration-700 animate-progress" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
