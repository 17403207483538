import React from "react";
import Header from "../components/Header";
import Hero from "../components/NewHero";
import Problem from "./Problem";
import Footer from "./Footer";
import HowItWorks from "./HowItWorks";
import PricingTables from "./Pricing";
import Faqs from "./Faqs";
import Cta from "./Cta";
import { useState, useEffect } from "react";
import TopBanner from "./TopBanner";
import IframeResizer from "iframe-resizer-react";
import { motion } from "framer-motion";
import { ArrowRight, Zap, Sparkles, ChevronRight } from "lucide-react";

const styles = {
  fontFamily: "Inter, sans-serif",
};

const LandingPage = () => {
  return (
    <div style={styles} className="font-inter antialiased bg-white text-gray-900 tracking-tight ">
      <TopBanner />
      <Header />
      {/* Add the Banner component */}
      <div className="grow ">
        <Hero />

        <HowItWorks />
        <Problem />
        <PricingTables />

        <Faqs />
        <Cta />
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
