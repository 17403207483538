import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import Logo from "../images/thumbnail.png";
import {
  Menu,
  X,
  Search,
  Bell,
  ChevronDown,
  Check,
  MessageCircle,
  Send,
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon,
  ChartBarIcon,
  LucideCloudLightning,
  SearchIcon,
  BriefcaseIcon,
  RocketIcon,
  ArrowRight,
  Calendar,
  Clock,
} from "lucide-react";

const NavLink = ({ href, children }) => (
  <motion.a
    href={href}
    className="text-gray-800 hover:text-blue-600 transition-colors duration-300"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    {children}
  </motion.a>
);

// Sample blog data - in a real application, this would likely come from an API or database
const blogPosts = [
  {
    id: 1,
    title: "How AI Can Validate Your Business Idea in Seconds",
    excerpt:
      "Stop wasting time on endless research. FeedbackbyAI's instant AI-powered business validation helps you assess your ideas quickly and confidently.",
    date: "2024-06-05",
    readTime: "6 min read",
    slug: "ai-validate-business-idea-in-seconds",
    category: "AI & Business Strategy",
  },

  {
    id: 2,
    title: "The Power of AI-Generated Business Reports: What’s Inside a 100+ Page Report?",
    excerpt: "In the fast-moving world of entrepreneurship, having a clear, data-driven business plan is critical.",
    date: "2024-05-20",
    readTime: "7 min read",
    slug: "discovering-customer-needs-ai",
    category: "AI & Product Development",
  },
  {
    id: 3,
    title: "50+ AI Characters: Getting Unbiased Feedback on Your Business Idea",
    excerpt:
      " When it comes to validating your business idea, feedback is everything. But relying on friends and family often leads to biased or overly supportive responses that may not help you grow your idea.",
    date: "2024-05-30",
    readTime: "8 min read",
    slug: "getting-unbiased-feedback-on-your-business-idea-from-ai-characters",
    category: "AI & Automation",
  },
  //   {
  //     id: 4,
  //     title: "How AI-Powered Lead Generation Can Transform Your Sales Strategy",
  //     excerpt:
  //       "Discover how AI-powered lead generation is transforming business growth. Learn about time-saving benefits, increased precision, and improved conversions.",
  //     date: "2024-05-15",
  //     readTime: "5 min read",
  //     slug: "revolutionize-lead-generation",
  //     category: "AI & Business",
  //   },

  //   {
  //     id: 5,
  //     title: "The Power of AI-Generated Business Reports: What’s Inside a 100+ Page Report?",
  //     excerpt: "Get a breakdown of what's inside FeedbackbyAI's comprehensive AI-generated business report and how it can fuel your success.",
  //     date: "2024-06-10",
  //     readTime: "9 min read",
  //     slug: "inside-ai-business-report",
  //     category: "AI & Analytics",
  //   },
  //   {
  //     id: 6,
  //     title: "50+ AI Characters: Getting Unbiased Feedback on Your Business Idea",
  //     excerpt: "FeedbackbyAI uses AI characters to run interviews, providing unbiased and honest feedback on your business ideas.",
  //     date: "2024-06-15",
  //     readTime: "7 min read",
  //     slug: "ai-characters-feedback",
  //     category: "AI & Customer Insights",
  //   },
  //   {
  //     id: 7,
  //     title: "How FeedbackbyAI Helps You Build a Data-Driven Launch Strategy",
  //     excerpt:
  //       "Discover how AI provides a step-by-step roadmap for launching your product, saving time and boosting your chances of success.",
  //     date: "2024-06-20",
  //     readTime: "7 min read",
  //     slug: "build-ai-launch-strategy",
  //     category: "AI & Business Strategy",
  //   },
  //   {
  //     id: 8,
  //     title: "Save 30+ Hours a Month with AI-Powered Lead Generation",
  //     excerpt:
  //       "Discover how automating lead generation can save hours of manual labor every month, giving your team more time to focus on closing deals.",
  //     date: "2024-06-25",
  //     readTime: "8 min read",
  //     slug: "save-time-ai-lead-generation",
  //     category: "AI & Automation",
  //   },
  //   {
  //     id: 9,
  //     title: "Why Honest Feedback is Crucial Before Launching: How AI Uncovers Genuine Concerns",
  //     excerpt: "Learn how AI provides genuine, unbiased feedback that helps address potential concerns before launching your product.",
  //     date: "2024-07-01",
  //     readTime: "6 min read",
  //     slug: "honest-feedback-ai",
  //     category: "AI & Product Development",
  //   },
  //   {
  //     id: 10,
  //     title: "How AI-Powered Business Plans Can Eliminate Guesswork for Startups",
  //     excerpt: "Startups can now eliminate guesswork with data-driven business plans powered by FeedbackbyAI's intelligent reports.",
  //     date: "2024-07-05",
  //     readTime: "10 min read",
  //     slug: "eliminate-guesswork-ai",
  //     category: "AI & Startups",
  //   },
];

const BlogCard = ({ post }) => (
  <div className="bg-white rounded-lg overflow-hidden transition-all duration-300 hover:shadow-lg hover:-translate-y-1">
    <div className="p-6">
      <span className="inline-block px-3 py-1 text-sm font-semibold text-indigo-500 bg-indigo-100 rounded-full mb-4">{post.category}</span>
      <h2 className="text-2xl font-bold mb-3 text-gray-800 line-clamp-2">{post.title}</h2>
      <p className="text-gray-600 mb-4 line-clamp-3">{post.excerpt}</p>
      <div className="flex justify-between items-center text-sm text-gray-500">
        <div className="flex items-center">
          <Calendar size={16} className="mr-1" />
          <span>{post.date}</span>
        </div>
        <div className="flex items-center">
          <Clock size={16} className="mr-1" />
          <span>{post.readTime}</span>
        </div>
      </div>
    </div>
    <div className="px-6 py-4 bg-gray-50 border-t border-gray-100">
      <Link
        to={`/blog/${post.slug}`}
        className="inline-flex items-center text-indigo-600 font-semibold hover:text-indigo-800 transition-colors duration-200"
      >
        Read More <ArrowRight size={16} className="ml-1" />
      </Link>
    </div>
  </div>
);

const BlogHub = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="min-h-screen font-sans text-gray-900">
      {/* Header */}
      <motion.header
        className={`bg-white backdrop-blur-md bg-opacity-80 shadow-sm sticky top-0 z-50 transition-all duration-300 ${
          scrolled ? "py-2" : "py-4"
        }`}
      >
        <nav className="container mx-auto px-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <Link to="/" className="flex items-center text-2xl font-bold text-gray-800">
                <img src={Logo} alt="FeedbackbyAI Logo" className="h-10 w-auto mr-2" />
                <span className="text-2xl font-bold text-blue-600">FeedbackbyAI</span>
              </Link>
            </div>
            <div className="hidden md:flex space-x-8">
              <motion.a
                href="/signin/"
                className="bg-green-500 text-white px-6 py-2 rounded-full font-semibold hover:bg-blue-700 transition-colors duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Signin
              </motion.a>
            </div>
            <motion.button
              className="md:hidden"
              onClick={() => setMenuOpen(!menuOpen)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {menuOpen ? <X /> : <Menu />}
            </motion.button>
          </div>
          <AnimatePresence>
            {menuOpen && (
              <motion.div
                className="mt-4 md:hidden space-y-4"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <NavLink href="#features">Features</NavLink>
                <NavLink href="#how-it-works">How It Works</NavLink>
                <NavLink href="#pricing">Pricing</NavLink>
                <motion.a
                  href="/signup/"
                  className="block bg-blue-600 text-white px-6 py-2 rounded-full font-semibold hover:bg-blue-700 transition-colors duration-300 text-center"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Get Started
                </motion.a>
              </motion.div>
            )}
          </AnimatePresence>
        </nav>
      </motion.header>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center mb-16">
          <h1 className="text-5xl font-extrabold text-gray-900 mb-4">FeedbackbyAI Blog</h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Explore the latest insights on AI-powered business planning, lead generation, and product development.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post) => (
            <BlogCard key={post.id} post={post} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogHub;
