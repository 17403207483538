// import React from "react";
// // import Logo from "../components/Logo"; // Make sure to import the correct path for your Logo component
// import { Link } from "react-router-dom";

// export default function Footer() {
//   return (
//     <footer>
//       <div className="max-w-6xl mx-auto px-4 sm:px-6">
//         {/* Top area: Blocks */}

//         {/* Bottom area */}
//         <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">
//           {/* Social links */}
//           <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
//             <li>
//               <Link
//                 to="https://feedbackbyai1.lemonsqueezy.com/affiliates/"
//                 className="flex justify-center items-center text-gray-600 hover:text-gray-900hover:bg-white-100 rounded-full  transition duration-150 ease-in-out ml-5"
//               >
//                 Affliate Program
//               </Link>
//             </li>

//             <li>
//               <Link
//                 to="/terms/"
//                 className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full  transition duration-150 ease-in-out ml-5"
//               >
//                 Terms
//               </Link>
//             </li>

//             <li>
//               <Link
//                 to="/privacy/"
//                 className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full  transition duration-150 ease-in-out ml-5 "
//               >
//                 Privacy
//               </Link>
//             </li>

//             <li>
//               <Link
//                 to="mailto:business@feedbackbyai.com"
//                 className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full  transition duration-150 ease-in-out ml-5 "
//               >
//                 Support
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="https://feedbackbyai.com/blog/how-to-validate-your-business-idea-10-best-frameworks/"
//                 className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full  transition duration-150 ease-in-out ml-5 "
//               >
//                 Blog
//               </Link>
//             </li>

//             <li>
//               <Link
//                 to="https://twitter.com/feedbackbyai"
//                 className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full  transition duration-150 ease-in-out ml-5 "
//               >
//                 Twitter
//               </Link>
//             </li>

//             {/* Add other social media links */}
//           </ul>

//           {/* Copyrights note */}
//           <div className="text-sm text-gray-600 mr-4">
//             © 2024 FeedbackbyAI. All rights reserved.
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// }
// import React from "react";
// import { Link } from "react-router-dom";

// const FooterLink = ({ to, children }) => (
//   <Link to={to} className="text-gray-600 hover:text-green-600 transition-colors duration-300">
//     {children}
//   </Link>
// );

// export default function Footer() {
//   return (
//     <footer className="bg-white border-t border-gray-200">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6">
//         <div className="py-8 md:py-12 flex flex-wrap justify-between items-center">
//           <ul className="flex flex-wrap justify-center space-x-5 mb-6 md:mb-0">
//             <li>
//               <FooterLink to="https://feedbackbyai1.lemonsqueezy.com/affiliates/">Affiliate Program</FooterLink>
//             </li>
//             <li>
//               <FooterLink to="/terms/">Terms</FooterLink>
//             </li>
//             <li>
//               <FooterLink to="/privacy/">Privacy</FooterLink>
//             </li>
//             <li>
//               <FooterLink to="mailto:business@feedbackbyai.com">Support</FooterLink>
//             </li>
//             <li>
//               <FooterLink to="https://feedbackbyai.com/blog/how-to-validate-your-business-idea-10-best-frameworks/">Blog</FooterLink>
//             </li>
//             <li>
//               <FooterLink to="https://twitter.com/feedbackbyai">Twitter</FooterLink>
//             </li>
//           </ul>
//           <div className="text-sm text-gray-600">© 2024 FeedbackbyAI. All rights reserved.</div>
//         </div>
//       </div>
//     </footer>
//   );
// }

import React from "react";
import { Link } from "react-router-dom";

const FooterLink = ({ to, children, highlighted = false }) => (
  <Link
    to={to}
    className={`transition-colors duration-300 ${highlighted ? "text-black hover:text-blue-800 " : "text-gray-600 hover:text-green-600"}`}
  >
    {children}
  </Link>
);

export default function Footer() {
  return (
    <footer className="bg-white border-t border-gray-200">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-8 md:py-12 flex flex-wrap justify-between items-center">
          <ul className="flex flex-wrap justify-center space-x-5 mb-6 md:mb-0">
            <li>
              <FooterLink to="https://feedbackbyai1.lemonsqueezy.com/affiliates/">Affiliate Program</FooterLink>
            </li>
            <li>
              <FooterLink to="/terms/">Terms</FooterLink>
            </li>
            <li>
              <FooterLink to="/privacy/">Privacy</FooterLink>
            </li>
            <li>
              <FooterLink to="mailto:business@feedbackbyai.com">Support</FooterLink>
            </li>
            <li>
              <FooterLink to="/blog/">Blog</FooterLink>
            </li>
            <li>
              <FooterLink to="https://twitter.com/feedbackbyai">Twitter</FooterLink>
            </li>

            {/* <li>
              <FooterLink to="https://needfinderai.com" highlighted={true}>
                NeedFinderAI
              </FooterLink>
            </li> */}
          </ul>
          <div className="text-sm text-gray-600">© 2024 FeedbackbyAI. All rights reserved.</div>
        </div>
      </div>
    </footer>
  );
}
