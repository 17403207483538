import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Import useParams
import api from "./axiosConfig";
import { Users, Eye, Clock, Star, TrendingUp, Bell, MessageSquare, Building, Mail, PhoneCall, Download, ExternalLink } from "lucide-react";

export default function BetterBy100ResultDashboard() {
  const [stats, setStats] = useState({ views: 0, edits: 0, wildcard_actions: 0 });
  const [signals, setSignals] = useState([]); // Store list of signals
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { landingPageId } = useParams(); // Extract landingPageId from the URL
  // State to toggle email visibility
  const [showEmail, setShowEmail] = useState(false);
  const [allEdits, setAllEdits] = useState([]);
  const [editPagination, setEditPagination] = useState({ next: null, previous: null });
  const [loadingEdits, setLoadingEdits] = useState(true);
  const [toggleStates, setToggleStates] = useState({}); // Manage toggle states for each edit
  const [bestVersion, setBestVersion] = useState(null);

  const handleToggle = (id) => {
    setToggleStates((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle the specific edit's state
    }));
  };

  // Fetch signals from API
  const fetchSignals = async () => {
    try {
      const response = await api.get(`/landing-pages/${landingPageId}/wildcards/`);
      setSignals(response.data.results);
    } catch (err) {
      console.error("Error fetching signals:", err);
      setError("Failed to load Interest Signals. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchBestPerformingVersion = async () => {
    try {
      const response = await api.get(`/landing-pages/${landingPageId}/best-performing-version/`);
      setBestVersion(response.data);
    } catch (error) {
      console.error("Error fetching best performing version:", error);
      setBestVersion({
        headline: "Future-Proof Your Business Planning",
        headlinePreference: 78,
        topFeature: "AI-powered financial projections",
        featureEngagement: 65,
        optimalPrice: "$149/month",
        priceConversion: 42,
      }); // Default mock values in case of API failure
    }
  };

  // useEffect(() => {
  //   if (landingPageId) {
  //     fetchSignals();
  //   }
  // }, [landingPageId]);

  useEffect(() => {
    const fetchStats = async () => {
      console.log("Fetching stats for landingPageId:", landingPageId);
      try {
        const response = await api.get(`/landing-pages/${landingPageId}/stats/`);
        console.log("Stats response:", response.data);
        setStats(response.data);
      } catch (error) {
        console.error("Error fetching stats:", error);
        if (error.response && error.response.status === 403) {
          setError("You are not authorized to view these stats.");
        } else {
          setError("An error occurred while fetching stats.");
        }
      }
    };

    if (landingPageId) {
      fetchSignals();
      fetchStats();
      fetchAllEdits();
      fetchBestPerformingVersion(); // Fetch Best Performing Version
    }
  }, [landingPageId]);

  if (error) {
    return <div className="text-center py-6 text-red-500">{error}</div>;
  }

  const fetchAllEdits = async (url = `/landing-pages/${landingPageId}/view-all-edits/`) => {
    setLoadingEdits(true);
    try {
      const response = await api.get(url);
      const { results, next, previous } = response.data;
      setAllEdits(results);
      setEditPagination({ next, previous });
    } catch (err) {
      console.error("Error fetching all edits:", err);
    } finally {
      setLoadingEdits(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      {/* Navigation */}
      <nav className="bg-white border-b sticky top-0 z-50">
        <div className="max-w-6xl mx-auto px-4 py-4 flex justify-between items-center">
          <a href="/better/" className="text-xl tracking-tight font-semibold">
            FeedbackByAI
          </a>
          <div className="flex items-center gap-4">
            <span className="text-green-500">{stats.edits}/100 edited</span>
            <button className="bg-black text-white px-4 py-2 rounded-lg">Share Results</button>
          </div>
        </div>
      </nav>
      <div className="max-w-6xl mx-auto p-4">
        {/* Top Stats */}
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-6">
          <div className="bg-white p-4 rounded-xl shadow-sm">
            <div>
              <div className="text-sm text-gray-500 mb-1">Total Views</div>
              <div className="text-2xl font-bold">{stats.views}</div>
            </div>
          </div>
          <div className="bg-white p-4 rounded-xl shadow-sm">
            <div className="text-sm text-gray-500 mb-1">Edits Received</div>
            <div className="text-2xl font-bold">{stats.edits}</div>
          </div>
          <div className="bg-white p-4 rounded-xl shadow-sm">
            <div className="text-sm text-gray-500 mb-1">Interest Signals</div>
            <div className="text-2xl font-bold">{stats.wildcard_actions}</div>
          </div>
        </div>

        {/* Main Content Grid */}
        <div className="grid md:grid-cols-2 gap-6">
          {/* Best Performing Version */}
          {/* Best Performing Version */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h2 className="font-bold text-xl mb-4">Best Performing Version</h2>
            {bestVersion ? (
              <div className="space-y-4">
                <div className="p-4 border rounded-lg">
                  <div className="text-sm text-gray-500 mb-1">Headline</div>
                  <div className="font-medium">{`"${bestVersion.headline.text}"`}</div>
                  <div className="text-sm text-green-500 mt-1">{`${bestVersion.headline.percentage}`}</div>
                </div>

                <div className="p-4 border rounded-lg">
                  <div className="text-sm text-gray-500 mb-1">Top Feature</div>
                  <div className="font-medium">{`"${bestVersion.top_feature.text}"`}</div>
                  <div className="text-sm text-green-500 mt-1">{`${bestVersion.top_feature.percentage}`}</div>
                </div>

                <div className="p-4 border rounded-lg">
                  <div className="text-sm text-gray-500 mb-1">Optimal Price</div>
                  <div className="font-medium">{bestVersion.optimal_price.text}</div>
                  <div className="text-sm text-green-500 mt-1">{`${bestVersion.optimal_price.percentage}`}</div>
                </div>

                <button className="w-full bg-black text-white px-4 py-2 rounded-lg flex items-center justify-center gap-2">
                  <Download className="w-4 h-4" />
                  Export Best Version
                </button>
              </div>
            ) : (
              <div className="text-sm text-gray-500">Loading best performing version...</div>
            )}
          </div>

          {/* Interest Signals */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="font-bold text-xl">Interest Signals</h2>
              <button className="text-sm text-blue-500">View All</button>
            </div>
            <div className="space-y-3">
              {signals.map((signal, i) => {
                const { action, details, user_profile } = signal.action_data || {};
                const { email } = signal.user || {};
                const fullName = `${user_profile?.first_name || "Unknown"} ${user_profile?.last_name || "User"}`.trim();

                return (
                  <div key={i} className="flex flex-col p-4 bg-white border rounded-lg shadow-md transition-transform hover:scale-[1.02]">
                    {/* Header Section */}
                    <div className="flex items-center justify-between mb-2">
                      <div>
                        <h3 className="text-lg font-bold text-gray-800">{action || "Unknown Action"}</h3>
                        <p className="text-sm text-gray-500">Reached out by: {fullName}</p>
                      </div>
                      {/* Toggle Email Button */}
                      <button
                        onClick={() => setShowEmail(!showEmail)}
                        className={`text-sm px-4 py-2 rounded-lg transition ${
                          showEmail ? "bg-red-500 text-white" : "bg-blue-500 text-white"
                        }`}
                      >
                        {showEmail ? "Hide Email" : "View Email"}
                      </button>
                    </div>

                    {/* User Profile Section */}
                    {user_profile && (
                      <div className="text-sm text-gray-600 mt-2">
                        <p>
                          <span className="font-semibold">Company Name:</span> {user_profile.company_name || "Unknown Company"}
                        </p>
                        <p>
                          <span className="font-semibold">Company Size:</span> {user_profile.company_size || "Size Unknown"}
                        </p>
                        <p>
                          <span className="font-semibold">Role:</span> {user_profile.role || "Role Unknown"}
                        </p>
                      </div>
                    )}

                    {/* Details Section */}
                    <div className="text-sm text-gray-700 mb-2 mt-2">
                      <p className="mb-1">
                        <span className="font-semibold">Details:</span> {details || "No additional details provided."}
                      </p>
                    </div>

                    {/* Show Email */}
                    {showEmail && (
                      <div className="mt-2 text-sm text-gray-700">
                        <p>
                          <span className="font-semibold">Email:</span>{" "}
                          <span className="text-blue-600">{email || "Email not provided"}</span>
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}

              {/* Locked Signals */}
              <div className="p-3 border-2 border-dashed border-gray-200 rounded-lg">
                <div className="text-center text-gray-500">
                  <p className="mb-2">
                    {signals.length > 3 ? `${signals.length - 3} more signals available` : "No more signals available"}
                  </p>
                  {signals.length > 3 && <button className="bg-black text-white px-4 py-2 rounded-lg text-sm">Upgrade to View All</button>}
                </div>
              </div>
            </div>
          </div>

          {/* All Edits */}
          <div className="bg-white rounded-xl shadow-sm p-6 md:col-span-2">
            <h2 className="font-bold text-xl mb-4">All Edits</h2>
            <div className="space-y-4">
              {allEdits.map((edit) => {
                const hasSuggestions = edit.changes.some((change) => change.startsWith("Suggestion:"));

                return (
                  <div key={edit.id} className="border rounded-lg p-4">
                    <div className="flex justify-between items-start mb-3">
                      <div>
                        <div className="text-sm text-gray-500 mb-1">{edit.edited_by}</div>
                        <div className="flex items-center gap-2 text-sm text-gray-500">
                          <Building className="w-3 h-3" />
                          <span>{edit.company_size}</span>
                        </div>
                      </div>
                      <div>
                        {hasSuggestions && (
                          <button className="text-blue-500 text-sm" onClick={() => handleToggle(edit.id)}>
                            {toggleStates[edit.id] ? "Hide Suggestions" : "View Suggestions"}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="space-y-2">
                      {toggleStates[edit.id] && hasSuggestions
                        ? edit.changes
                            .filter((change) => change.startsWith("Suggestion:"))
                            .map((suggestion, idx) => (
                              <div key={idx} className="text-sm">
                                <span className="text-gray-500">Suggestion: </span>
                                {suggestion.replace("Suggestion: ", "")}
                              </div>
                            ))
                        : edit.changes
                            .filter((change) => !change.startsWith("Suggestion:"))
                            .map((change, idx) => {
                              if (change.startsWith("title:")) {
                                return (
                                  <div key={idx} className="text-sm">
                                    <span className="text-gray-500">Changed headline to: </span>
                                    {change.replace("title: ", "")}
                                  </div>
                                );
                              } else if (change.startsWith("price:")) {
                                return (
                                  <div key={idx} className="text-sm">
                                    <span className="text-gray-500">Suggested price: </span>
                                    {change.replace("price: ", "")}
                                  </div>
                                );
                              } else if (change.startsWith("features:")) {
                                return (
                                  <div key={idx} className="text-sm">
                                    <span className="text-gray-500">Changed features to: </span>
                                    {change.replace("features: ", "")}
                                  </div>
                                );
                              } else {
                                return (
                                  <div key={idx} className="text-sm">
                                    {change}
                                  </div>
                                );
                              }
                            })}
                    </div>
                  </div>
                );
              })}
              {/* Locked Edits */}
              <div className="border-2 border-dashed border-gray-200 rounded-lg p-4">
                <div className="text-center text-gray-500">
                  <p className="mb-2">20 more edits available</p>
                  <button className="bg-black text-white px-4 py-2 rounded-lg text-sm">Upgrade to View All</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
