import React, { useState } from "react";
import { Upload, Plus, ArrowRight, X, CheckCircle, AlertTriangle, ArrowLeft } from "lucide-react";
import axios from "./axiosConfig";

export default function CreateTest() {
  const [coverImage, setCoverImage] = useState(null);
  const [productName, setProductName] = useState("");
  const [category, setCategory] = useState("SaaS");
  const [description, setDescription] = useState("");
  const [features, setFeatures] = useState(["", "", ""]);
  const [currentPricing, setCurrentPricing] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleFeatureChange = (index, value) => {
    const updatedFeatures = [...features];
    updatedFeatures[index] = value;
    setFeatures(updatedFeatures);
  };

  const addFeature = () => {
    setFeatures([...features, ""]);
  };

  const handleSubmit = async () => {
    // Basic validation
    if (!productName.trim() || !description.trim() || !currentPricing.trim() || features.every((feature) => !feature.trim())) {
      setErrorMessage("Please fill out Product Name, Description, Pricing, and at least one Feature before submitting.");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({
        title: productName,
        category,
        description,
        features,
        pricing: currentPricing,
      })
    );
    if (coverImage) {
      formData.append("image", coverImage);
    }

    try {
      const response = await axios.post("/landing-pages/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const { message, rejection_reason, expiration_time, error } = response.data;

      if (error) {
        setErrorMessage(`AI analysis failed: ${error}. Please try again later.`);
        return;
      }

      if (expiration_time) {
        setSuccessMessage(`${message} Expires at: ${new Date(expiration_time).toLocaleString()}`);
      } else {
        setErrorMessage(`${message} Reason: ${rejection_reason}`);
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.error || "Failed to create test. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Success Modal */}
      {successMessage && (
        <div className="fixed inset-0 bg-black/70 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl p-8 max-w-md w-full text-center">
            <CheckCircle className="w-12 h-12 text-green-500 mx-auto mb-4" />
            <h3 className="text-xl font-bold mb-4">Success</h3>
            <p>{successMessage}</p>
            <button onClick={() => setSuccessMessage("")} className="mt-6 bg-black text-white py-2 px-4 rounded-lg">
              OK
            </button>
          </div>
        </div>
      )}

      {/* Error Modal */}
      {errorMessage && (
        <div className="fixed inset-0 bg-black/70 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl p-8 max-w-md w-full text-center">
            <AlertTriangle className="w-12 h-12 text-red-500 mx-auto mb-4" />
            <h3 className="text-xl font-bold mb-4">Error</h3>
            <p>{errorMessage}</p>
            <button onClick={() => setErrorMessage("")} className="mt-6 bg-black text-white py-2 px-4 rounded-lg">
              Close
            </button>
          </div>
        </div>
      )}

      <nav className="bg-white border-b">
        <div className="max-w-3xl mx-auto px-4 py-4 flex flex-col">
          {/* Title */}
          <h1 className="font-bold text-xl mb-2">Create Test</h1>
          {/* Back Button */}
          <button onClick={() => (window.location.href = "/better/")} className="flex items-center gap-2 text-gray-500 hover:text-black">
            <ArrowLeft className="w-5 h-5" />
            <span className="text-sm font-medium">Back</span>
          </button>
        </div>
      </nav>

      <div className="max-w-3xl mx-auto p-4">
        <div className="bg-white rounded-xl shadow-sm p-8">
          {/* Cover Image */}
          <div className="mb-8">
            {coverImage ? (
              <div className="relative">
                <img src={URL.createObjectURL(coverImage)} alt="Cover" className="w-full h-48 object-cover rounded-lg" />
                <button className="absolute top-2 right-2 bg-black/50 p-2 rounded-full text-white" onClick={() => setCoverImage(null)}>
                  <X className="w-4 h-4" />
                </button>
              </div>
            ) : (
              <div className="border-2 border-dashed border-gray-200 rounded-lg p-8 text-center">
                <label>
                  <Upload className="w-8 h-8 text-gray-400 mx-auto mb-2" />
                  <p className="text-gray-500">Upload product image (optional)</p>
                  <input type="file" accept="image/*" className="hidden" onChange={(e) => setCoverImage(e.target.files[0])} />
                </label>
              </div>
            )}
          </div>

          {/* Form */}
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium mb-2">Product Name</label>
              <input
                type="text"
                className="w-full p-3 border rounded-lg"
                placeholder="e.g., AI Business Planner"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Category</label>
              <select className="w-full p-3 border rounded-lg" value={category} onChange={(e) => setCategory(e.target.value)}>
                <option>SaaS</option>
                <option>Marketing Tools</option>
                <option>E-commerce</option>
                <option>Productivity</option>
                <option>Other</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Description</label>
              <textarea
                className="w-full p-3 border rounded-lg"
                rows={3}
                placeholder="What does your product do? What feedback do you need?"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Key Features</label>
              <div className="space-y-2">
                {features.map((feature, index) => (
                  <div key={index} className="flex gap-2">
                    <input
                      type="text"
                      className="flex-1 p-3 border rounded-lg"
                      placeholder={`Feature ${index + 1}`}
                      value={feature}
                      onChange={(e) => handleFeatureChange(index, e.target.value)}
                    />
                    {index === features.length - 1 && (
                      <button type="button" className="text-blue-500 p-3" onClick={addFeature}>
                        <Plus className="w-5 h-5" />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Current Pricing</label>
              <input
                type="text"
                className="w-full p-3 border rounded-lg"
                placeholder="e.g., $99/month"
                value={currentPricing}
                onChange={(e) => setCurrentPricing(e.target.value)}
              />
            </div>

            <div className="pt-6 border-t">
              <button
                onClick={handleSubmit}
                disabled={loading}
                className={`w-full bg-black text-white py-3 rounded-lg font-semibold flex items-center justify-center gap-2 ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {loading ? "Verifying your Submission..." : "Launch Test"} <ArrowRight className="w-5 h-5" />
              </button>
              <p className="text-center text-sm text-gray-500 mt-4">Preview first 3 edits free, then $39 or help 3 businesses</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
