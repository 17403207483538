import React from "react";
import { HiClock, HiLightningBolt, HiCurrencyDollar, HiUserGroup, HiCube, HiAdjustments } from "react-icons/hi";
import FeatureImage from "../images/f3.png";
import FeatureImage2 from "../images/f1.png";

const FeatureCard = ({ icon, title, description }) => (
  <div className="flex items-start space-x-4">
    <div className="flex-shrink-0">
      <div className="bg-green-100 rounded-full p-3">{icon}</div>
    </div>
    <div>
      <h3 className="text-lg font-semibold text-gray-800 mb-1">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

export default function Problem() {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-green-50">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="mb-20">
          <h2 className="text-4xl lg:text-5xl font-bold text-gray-900 mb-6 text-center">
            100+ Page In-Depth Report: Your Data-Driven Launch Roadmap
          </h2>
          <p className="text-xl text-gray-700 mb-12 text-center max-w-3xl mx-auto">
            Our AI analyzes your idea and generates a comprehensive business plan with market analysis, ideal customer profiles, financial
            projections, and a step-by-step launch roadmap.
            {/* Stop wasting time on guesswork. Get the analysis, the ideal customer profile, and the step-by-step plan you need. */}
          </p>

          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="space-y-8">
              <FeatureCard
                icon={<HiClock className="h-6 w-6 text-green-600" />}
                title="Save Time"
                description="Weeks of research and guesswork done in minutes."
              />
              <FeatureCard
                icon={<HiLightningBolt className="h-6 w-6 text-green-600" />}
                title="Strategic Insights"
                description="Make informed decisions. Pick and choose the best ideas."
              />
              <FeatureCard
                icon={<HiCurrencyDollar className="h-6 w-6 text-green-600" />}
                title="Increased Revenues"
                description="Launch with clarity and enjoy higher earnings."
              />
            </div>
            <div className="flex justify-center md:justify-end">
              <img src={FeatureImage} alt="Feature illustration" className="rounded-lg shadow-xl max-w-full h-auto" />
            </div>
          </div>
        </div>

        <div className="mt-24">
          <h2 className="text-4xl lg:text-5xl font-bold text-gray-900 mb-6 text-center">
            50+ AI Characters: Get Honest Feedback Friends Won't Give
          </h2>
          <p className="text-xl text-gray-700 mb-12 text-center max-w-3xl mx-auto">
            Get unbiased feedback from 50+ AI characters representing diverse demographics and expertise. Uncover potential challenges and
            refine your business strategy.
            {/* AI interviews uncover hidden concerns about your idea, so you can address them BEFORE launch. */}
          </p>

          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="order-2 md:order-1">
              <img src={FeatureImage2} alt="AI Characters illustration" className="rounded-lg shadow-xl max-w-full h-auto" />
            </div>
            <div className="space-y-8 order-1 md:order-2">
              <FeatureCard
                icon={<HiCube className="h-6 w-6 text-green-600" />}
                title="Instant Clarity"
                description="Instantly uncover genuine concerns about your business idea."
              />
              <FeatureCard
                icon={<HiUserGroup className="h-6 w-6 text-green-600" />}
                title="Save Time"
                description="Avoid time-consuming user interviews."
              />
              <FeatureCard
                icon={<HiAdjustments className="h-6 w-6 text-green-600" />}
                title="Sharper Business Ideas"
                description="Launch sharply in your target market."
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
