import React from "react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { FcGoogle } from "react-icons/fc";
import { HiLightningBolt, HiUserGroup, HiDocumentText } from "react-icons/hi";
import api from "./axiosConfig";
import IframeResizer from "iframe-resizer-react";

const FeatureItem = ({ icon, text }) => (
  <div className="flex items-center space-x-2">
    {icon}
    <span className="text-sm md:text-base text-gray-700">{text}</span>
  </div>
);

export default function NewHero() {
  const redirectToSignUp = () => {
    window.location.href = "/signup";
  };

  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const token = result.user.accessToken;
      const response = await api.post("/google-login/", { token });
      if (response.status === 200) {
        localStorage.setItem("access", response.data.access);
        window.location.href = "/dashboard/";
      }
    } catch (error) {
      console.error("Error with Google Sign Up:", error.message);
    }
  };

  return (
    <div className="bg-gradient-to-b from-green-50 to-white" id="hero">
      <section className="max-w-6xl mx-auto px-4 sm:px-6 py-20 md:py-28">
        <div className="text-center mb-16">
          <h1
            className="text-5xl sm:text-5xl md:text-5xl lg:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
            style={{ color: "#1A202C" }}
          >
            {/* Validate Your Business Idea In Seconds */}
            {/* Validate Your Next Big Idea In Seconds */}
            Validate Your Next Big Idea In Seconds
            {/* Generate the Most Comprehensive AI Business Plan on the Internet */}
          </h1>
          <p className="text-xl md:text-2xl text-gray-700 mb-8 max-w-3xl mx-auto">
            {/* Harness AI to validate your concept, identify your ideal customers, and craft a bulletproof business plan in minutes. */}
            {/* Stop wasting time on guesswork and endless research. Let AI run instant user interviews with 50+ AI Characters and craft a 100+
            Page Business Report based on your business idea potential. */}
            {/* Get a 100+ page report with in-depth analysis, 50+ AI Characters User interviews, and a data-driven roadmap to launch your
            business with confidence. */}
            Stop wasting time on guesswork and endless research. Let AI run instant user interviews with 50+ AI Characters and craft a 100+
            Page Business Report based on your business idea potential.
          </p>

          <div className="flex justify-center space-x-8 mb-12">
            {/* <FeatureItem icon={<HiLightningBolt className="text-green-500 text-2xl" />} text="Instant AI-Powered Analysis" /> */}
            <FeatureItem icon={<HiDocumentText className="text-green-500 text-2xl" />} text="100+ Page Business Report" />
            <FeatureItem icon={<HiUserGroup className="text-green-500 text-2xl" />} text="50+ AI Expert Interviews" />
            <FeatureItem icon={<HiLightningBolt className="text-green-500 text-2xl" />} text="Trusted by 10,000+ Businesses" />
          </div>

          <div className="flex flex-col sm:flex-row justify-center gap-4 mb-8">
            <button
              onClick={redirectToSignUp}
              className="px-8 py-3 text-lg font-semibold text-white bg-green-600 rounded-full hover:bg-green-700 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 shadow-lg"
            >
              Start Your Free Trial
            </button>
            <button
              onClick={handleGoogleSignUp}
              className="flex items-center justify-center px-8 py-3 text-lg font-semibold bg-white text-gray-800 border border-gray-300 rounded-full hover:bg-gray-50 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50 shadow-lg"
            >
              <FcGoogle className="text-2xl mr-2" />
              Sign Up with Google
            </button>
          </div>

          <a
            href="/previewx/"
            rel="noopener noreferrer"
            className="text-green-600 hover:text-green-700 font-semibold underline inline-block mb-12"
          >
            View Sample Business Report
          </a>
        </div>

        <div className="max-w-4xl mx-auto mb-20">
          <div className="aspect-w-16 aspect-h-9 rounded-2xl overflow-hidden shadow-2xl">
            <iframe
              className="w-full h-full"
              src="https://www.youtube.com/embed/7VSRdf-JzCU?si=-qk1qmIFvNP5vQbM"
              title="FeedbackbyAI Demo"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <div className="text-center">
          <h3 className="text-3xl md:text-4xl font-bold text-gray-800 mb-8">Trusted by 10,000+ Businesses</h3>
          <p className="text-xl text-gray-600 mb-12">
            Join thousands of visionaries who've turned their ideas into successful businesses with FeedbackbyAI.
          </p>
          <IframeResizer src="https://embed-v2.testimonial.to/w/feedbackbyai" style={{ width: "1px", minWidth: "100%" }} />
        </div>
      </section>
    </div>
  );
}
