import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import api from "./axiosConfig";
import DashboardHeader from "./DashboardHeader";
import ReportFooter from "./ReportFooter";
import Modal from "react-modal";
import {
  ChevronDown,
  Sparkles,
  Radio,
  Eye,
  MessageSquare,
  Clock,
  Target,
  Gift,
  Star,
  ChevronRight,
  X,
  ExternalLink,
  Megaphone,
  Activity,
  Users,
  Calendar,
  Building,
  TrendingUp,
  Mail,
} from "lucide-react";
import TopBanner from "./TopBanner";

const CustomModal = ({ isOpen, onRequestClose, content }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="fixed inset-0 z-50 flex items-center justify-center"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ duration: 0.3 }}
        className="bg-white rounded-2xl shadow-xl max-w-lg w-full mx-4 p-6"
      >
        <h3 className="text-xl font-semibold text-gray-900 mb-4">Full Input</h3>
        <div className="max-h-96 overflow-y-auto p-4 bg-gray-50 rounded-lg mb-4">
          <p className="text-sm text-gray-700 whitespace-pre-wrap">{content}</p>
        </div>
        <div className="flex justify-end space-x-3">
          <button
            onClick={handleCopy}
            className={`px-4 py-2 rounded-lg text-white font-medium transition-colors ${
              isCopied ? "bg-green-600" : "bg-blue-600 hover:bg-blue-700"
            }`}
          >
            {isCopied ? "Copied!" : "Copy Full Text"}
          </button>
          <button
            onClick={onRequestClose}
            className="px-4 py-2 bg-gray-200 rounded-lg text-gray-700 font-medium hover:bg-gray-300 transition-colors"
          >
            Close
          </button>
        </div>
      </motion.div>
    </Modal>
  );
};

export default function ReportDashboard() {
  const location = useLocation();
  const [businessIdea, setBusinessIdea] = useState("");
  const [language, setLanguage] = useState("english");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [latestFullReports, setLatestFullReports] = useState([]);
  const [isFetchingReports, setIsFetchingReports] = useState(false);
  const [message, setMessage] = useState("");
  const [showRechargeLink, setShowRechargeLink] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [showBoothForm, setShowBoothForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    async function fetchReports() {
      setIsFetchingReports(true);
      try {
        const response = await api.get("user-latest-report/");
        if (response.status === 200) {
          setLatestFullReports(response.data);
        }
      } catch (error) {
        console.error("Error fetching reports:", error);
      } finally {
        setIsFetchingReports(false);
      }
    }

    fetchReports();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    setMessage("");
    setShowRechargeLink(false);
    setIsSubmitting(true);

    const timeoutId = setTimeout(() => {
      alert("Submission is taking longer than expected. Please try again.");
      setIsSubmitting(false);
    }, 10000);

    const confirmReport = window.confirm("This will deduct one report credit. Are you sure you want to continue?");
    if (!confirmReport) {
      clearTimeout(timeoutId);
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await api.post("create-report/", {
        business_idea_input: businessIdea,
        language,
      });
      clearTimeout(timeoutId);

      if (response.status === 200 || response.status === 201) {
        window.location.href = `/full-report/${response.data.report_id}`;
      }
    } catch (error) {
      clearTimeout(timeoutId);
      if (error.response) {
        const errorMessage = error.response.data.message;
        setMessage(errorMessage || "An error occurred. Please try again.");
        if (errorMessage.includes("Not enough credits")) {
          setShowRechargeLink(true);
        }
      } else {
        setMessage("An unexpected error occurred.");
      }
      console.error("Failed to submit form:", error.response?.data);
    } finally {
      setIsSubmitting(false);
    }
  }

  const openModal = (report) => {
    setSelectedReport(report);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedReport(null);
  };

  function PriorityAccessModal({ isOpen, onClose }) {
    const [form, setForm] = useState({
      businessName: "",
      contactName: "",
      email: "",
      needs: [],
      budget: "299",
    });

    const handleSubmit = (e) => {
      e.preventDefault();
      // Handle form submission
      console.log(form);
      onClose();
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-2xl shadow-2xl max-w-lg w-full p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">Get Priority Access</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X className="w-5 h-5" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                placeholder="Business Name"
                className="w-full p-3 rounded-xl border"
                required
                value={form.businessName}
                onChange={(e) => setForm({ ...form, businessName: e.target.value })}
              />
              <input
                type="text"
                placeholder="Contact Name"
                className="w-full p-3 rounded-xl border"
                required
                value={form.contactName}
                onChange={(e) => setForm({ ...form, contactName: e.target.value })}
              />
            </div>

            <input
              type="email"
              placeholder="Email"
              className="w-full p-3 rounded-xl border"
              required
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />

            <div>
              <label className="block text-sm font-medium mb-2">Looking for</label>
              <div className="flex flex-wrap gap-2">
                {["Customers", "Partners", "Investors"].map((need) => (
                  <button
                    key={need}
                    type="button"
                    onClick={() => {
                      const needs = form.needs.includes(need) ? form.needs.filter((n) => n !== need) : [...form.needs, need];
                      setForm({ ...form, needs });
                    }}
                    className={`px-3 py-1 rounded-full text-sm ${
                      form.needs.includes(need) ? "bg-blue-100 text-blue-800" : "bg-gray-100 text-gray-800"
                    }`}
                  >
                    {need}
                  </button>
                ))}
              </div>
            </div>

            <button type="submit" className="w-full bg-gradient-to-r from-blue-600 to-blue-700 text-white py-3 rounded-xl font-medium">
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }

  const PreviewModal = ({ isOpen, onClose }) => {
    const [activeTab, setActiveTab] = useState("preview");

    // Close modal when clicking outside
    useEffect(() => {
      const handleEscKey = (e) => {
        if (e.key === "Escape") onClose();
      };

      window.addEventListener("keydown", handleEscKey);
      return () => window.removeEventListener("keydown", handleEscKey);
    }, [onClose]);

    const handleOutsideClick = (e) => {
      if (e.target === e.currentTarget) onClose();
    };

    const spotlight = {
      id: 1,
      title: "ChefPrep", // Catchy and relevant name
      subtitle: "Revolutionizing Meal Planning for Busy Professionals", // Clear value proposition
      description:
        "ChefPrep provides personalized meal plans, grocery delivery, and cooking assistance, making healthy eating effortless and enjoyable.", // Expanded description
      category: "Food & Beverage",
      badge: "New York City, USA", // Updated location
      looking: [
        {
          title: "Restaurant Partnerships",
          details: "Collaborate with local restaurants to offer meal kits and delivery services",
        },
        {
          title: "Ingredient Suppliers",
          details: "Source high-quality, sustainable ingredients",
        },
      ], // More specific needs
      offering: [
        {
          title: "Premium Ingredients",
          details: "Access to a curated selection of high-quality, locally sourced ingredients",
        },
        {
          title: "Menu Innovation",
          details: "Collaborate with our chefs to develop unique and exciting dishes",
        },
        {
          title: "Marketing Support",
          details: "Benefit from our marketing campaigns and social media promotion",
        },
      ], // Stronger value propositions
      creator: {
        name: "The ChefPrep Team",
        role: "Passionate About Food and Technology",
        avatar: "/api/placeholder/64/64",
        responseTime: "Within 24 hours", // More realistic response time
        responseRate: "98%",
      },
      metrics: {
        views: 3456, // Updated metrics
        connections: 65, // Changed to "connections"
        websiteClicks: 23, // Added websiteClicks
        meetingRequests: 8, // Added meetingRequests
        daysLeft: 7,
        viewsToday: 493,
      },
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black/40 backdrop-blur-md flex items-center justify-center p-4 z-50" onClick={handleOutsideClick}>
        <div className="bg-white rounded-2xl shadow-2xl max-w-4xl w-full" onClick={(e) => e.stopPropagation()}>
          <div className="border-b p-4 flex justify-between items-center">
            <div className="flex gap-6">
              <TabButton active={activeTab === "preview"} onClick={() => setActiveTab("preview")} text="Profile Preview" />
              <TabButton active={activeTab === "benefits"} onClick={() => setActiveTab("benefits")} text="Platform Benefits" />
            </div>
            <button onClick={onClose} className="hover:bg-gray-100 p-2 rounded-full transition-colors">
              <X className="w-5 h-5" />
            </button>
          </div>

          <div className="max-h-[80vh] overflow-y-auto custom-scrollbar">
            {activeTab === "preview" ? <PreviewTab spotlight={spotlight} /> : <BenefitsTab />}
          </div>
        </div>
      </div>
    );
  };

  const TabButton = ({ active, onClick, text }) => (
    <button
      onClick={onClick}
      className={`relative px-4 py-2 transition-colors ${active ? "text-emerald-600" : "text-gray-600 hover:text-gray-900"}`}
    >
      {text}
      {active && <div className="absolute bottom-[-17px] left-0 w-full h-[2px] bg-emerald-600" />}
    </button>
  );

  const PreviewTab = ({ spotlight }) => (
    <div className="p-6 space-y-6">
      <div className="relative h-72 bg-gradient-to-br from-emerald-900 via-emerald-800 to-green-900 rounded-xl overflow-hidden group transition-all duration-300 hover:shadow-lg">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(16,185,129,0.2),transparent)]" />
        <div className="absolute inset-0 bg-black/20 group-hover:bg-black/10 transition-colors" />
        <div className="absolute bottom-8 left-8 text-white">
          <div className="flex items-center space-x-3 mb-4">
            <span className="bg-amber-500 text-white px-3 py-1 rounded-full text-sm flex items-center shadow-lg">
              <Star className="w-4 h-4 mr-1" fill="white" />
              Featured
            </span>
            <span className="bg-white/20 backdrop-blur-sm px-3 py-1 rounded-full text-sm shadow-lg">{spotlight.category}</span>
            <span className="bg-emerald-500 px-3 py-1 rounded-full text-sm shadow-lg">{spotlight.badge}</span>
          </div>
          <h2 className="text-3xl font-bold mb-2 drop-shadow-lg">{spotlight.title}</h2>
          <p className="text-xl opacity-90 drop-shadow-md">{spotlight.subtitle}</p>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        {" "}
        {/* Changed to grid-cols-4 */}
        <MetricCard
          title="Profile Views"
          value={spotlight.metrics.views.toLocaleString()}
          trend={`+${spotlight.metrics.viewsToday.toLocaleString()} today`}
        />
        <MetricCard
          title="Connections"
          value={spotlight.metrics.connections.toLocaleString()}
          trend={`${spotlight.metrics.connections} connections made`}
        />
        {/* <MetricCard
          title="Website Clicks"
          value={spotlight.metrics.websiteClicks.toLocaleString()} // Using websiteClicks
          trend={`${spotlight.metrics.websiteClicks} clicks`} // Updated trend
        /> */}
        <MetricCard
          title="Meeting Requests"
          value={spotlight.metrics.meetingRequests.toLocaleString()} // Using meetingRequests
          trend={`${spotlight.metrics.meetingRequests} requests`} // Updated trend
        />
      </div>

      <div className="flex items-center justify-between bg-white p-6 rounded-xl border shadow-sm hover:shadow-md transition-shadow">
        <div className="flex items-center space-x-4">
          <img src={spotlight.creator.avatar} alt={spotlight.creator.name} className="w-14 h-14 rounded-full ring-4 ring-white shadow-lg" />
          <div>
            <p className="font-bold text-lg">{spotlight.creator.name}</p>
            <p className="text-gray-600">{spotlight.creator.role}</p>
          </div>
        </div>
        <div className="flex gap-3">
          <ActionButton icon={<MessageSquare />} text="Message" primary />
          <ActionButton icon={<Calendar />} text="Book Meeting" secondary />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <RequirementBox title="Looking For" items={spotlight.looking} color="emerald" icon={<Target />} />
        <RequirementBox title="What We Offer" items={spotlight.offering} color="emerald" icon={<Gift />} />
      </div>

      <div className="flex justify-between items-center pt-6 border-t">
        <div className="flex items-center text-gray-500">
          <Clock className="w-4 h-4 mr-1" />
          <span className="text-sm">Responds in {spotlight.creator.responseTime}</span>
        </div>
        <button className="bg-emerald-600 text-white px-8 py-3 rounded-xl font-medium hover:bg-emerald-700 transition-colors shadow-sm hover:shadow-md">
          Connect Now
        </button>
      </div>
    </div>
  );

  const BenefitsTab = () => (
    <div className="p-6 space-y-6">
      <div className="bg-emerald-50 rounded-xl p-6 mb-6">
        <h3 className="text-2xl font-bold text-emerald-800 mb-2">Why Spotlight•E?</h3>
        <p className="text-emerald-700">
          {" "}
          {/* Get discovered by key decision-makers on FeedbackByAI. Connect with potential investors, partners, and customers. Generate leads,
          boost sales, and accelerate your growth. */}
          {/* Get discovered by investors, partners, and customers. Grow your business faster with Spotlight•E. */}
          {/* Showcase your business to 10,000+ businesses worldwide and forge valuable partnerships. */}
          Unlock global growth. Showcase your business to 10,000+ businesses worldwide and forge valuable partnerships.
        </p>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <BenefitCard
          icon={<Users className="w-6 h-6" />}
          title="Targeted Visibility"
          description="Reach the right audience among 10,000+ businesses worldwide. Get noticed by those who matter."
        />
        <BenefitCard
          icon={<Building className="w-6 h-6" />}
          title="Qualified Leads"
          description="Connect with investors, partners, and customers who are actively seeking businesses like yours."
        />
        <BenefitCard
          icon={<TrendingUp className="w-6 h-6" />}
          title="Actionable Insights"
          description="Track views, clicks, messages, and meeting requests to measure your success and optimize your strategy."
        />
        <BenefitCard
          icon={<Mail className="w-6 h-6" />}
          title="Increased Exposure"
          description="Maximize your reach with features across our platform."
        />
      </div>

      <div className="bg-gradient-to-br from-emerald-50 to-emerald-100 rounded-xl p-6 mt-6">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-xl font-semibold text-emerald-800 mb-2">Ready to Get Started?</h3>
          </div>
          <a
            href="/spotlighte/create/"
            className="bg-emerald-600 text-white px-8 py-3 rounded-xl font-medium hover:bg-emerald-700 transition-colors shadow-sm hover:shadow-md"
          >
            Create Free Profile Now
          </a>
        </div>
      </div>
    </div>
  );

  const MetricCard = ({ title, value, trend }) => (
    <div className="bg-gray-50 p-4 rounded-xl hover:shadow-md transition-shadow">
      <div className="text-sm text-gray-600 mb-1">{title}</div>
      <div className="text-2xl font-bold">{value}</div>
      <div className="text-emerald-600 text-sm">{trend}</div>
    </div>
  );

  const ActionButton = ({ icon, text, primary }) => (
    <button
      className={`px-4 py-2 rounded-lg transition-all flex items-center gap-2 ${
        primary ? "bg-emerald-600 text-white hover:bg-emerald-700" : "bg-emerald-100 text-emerald-700 hover:bg-emerald-200"
      }`}
    >
      {React.cloneElement(icon, { className: "w-4 h-4" })}
      {text}
    </button>
  );

  const BenefitCard = ({ icon, title, description }) => (
    <div className="bg-white p-6 rounded-xl border hover:shadow-md transition-shadow">
      <div className="text-emerald-600 mb-4">{icon}</div>
      <h4 className="font-semibold text-lg mb-2">{title}</h4>
      <p className="text-gray-600">{description}</p>
    </div>
  );

  const RequirementBox = ({ title, items, color, icon }) => (
    <div className="bg-emerald-50/50 rounded-xl p-6 border border-emerald-100 hover:shadow-md transition-shadow">
      <div className="flex items-center text-emerald-600 mb-4">
        {React.cloneElement(icon, { className: "w-5 h-5" })}
        <h3 className="font-semibold ml-2">{title}</h3>
      </div>
      <ul className="space-y-3">
        {items.map((item, i) => (
          <li key={i} className="flex items-start text-gray-700">
            <span className="w-2 h-2 bg-emerald-600 rounded-full mt-2 mr-2"></span>
            <div>
              <div className="font-medium">{item.title}</div>
              {item.amount && <div className="text-sm text-gray-600">{item.amount}</div>}
              <div className="text-sm text-gray-600">{item.details}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div className="font-sans bg-gradient-to-b from-green-50 to-white min-h-screen">
      <TopBanner />
      <DashboardHeader />

      {/* Add Virtual Booth Section HERE - Right after header */}
      {/* <div className="max-w-3xl mx-auto px-3">
        <div className="bg-gradient-to-br from-emerald-950 via-emerald-900 to-green-900 rounded-2xl relative overflow-hidden shadow-2xl border border-white/10">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_10%_0%,rgba(16,185,129,0.2),transparent)]" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_90%_90%,rgba(16,185,129,0.15),transparent)]" />
          <div className="absolute top-0 left-0 right-0 h-[1px] bg-gradient-to-r from-transparent via-emerald-400/40 to-transparent" />
          <div className="relative px-8 py-8">
            <div className="space-y-6">
              <div className="flex flex-wrap items-center gap-3">
                <div className="inline-flex items-center gap-2 bg-gradient-to-r from-emerald-500/15 to-emerald-500/5 rounded-full px-4 py-1.5 border border-emerald-400/20 shadow-lg shadow-emerald-900/20">
                  <Sparkles className="h-3.5 w-3.5 text-emerald-300 animate-pulse" />
                  <span className="text-emerald-300 text-xs font-medium tracking-wider">Introducing: Spotlight•E</span>
                </div>
                <div className="inline-flex items-center bg-emerald-400/10 rounded-full px-3 py-1 border border-emerald-400/10">
                  <span className="text-emerald-300 text-xs font-medium">✨ Launching This Week</span>
                </div>
              </div>

              <div className="space-y-3.5">
                <h2 className="text-3xl text-white font-semibold tracking-tight">✨ Showcase Your Startup to 10,000+ Businesses</h2>
                <p className="text-white/90 text-base leading-relaxed">
                  Put your startup in front of 10,000+ businesses ready to partner, collaborate, and invest. Instantly see who's viewing,
                  clicking, and connecting with your profile—all in one simple dashboard.
                </p>
              </div>

              <div className="flex items-center gap-4 pt-1">
                <a
                  href="/spotlighte/create/"
                  className="group relative bg-white hover:bg-white/95 text-emerald-950 px-7 py-3.5 rounded-xl text-sm font-semibold tracking-wide transition-all duration-300 flex items-center gap-2 hover:shadow-xl hover:shadow-emerald-900/20 ring-1 ring-white/20"
                >
                  <div className="absolute inset-0 bg-gradient-to-r from-emerald-500/10 via-transparent to-transparent rounded-xl animate-pulse" />
                  <span className="relative">Create Your Free Profile Now</span>
                  <ChevronRight className="w-4 h-4 group-hover:translate-x-1 transition-transform relative" />
                </a>
                <button
                  onClick={() => setShowPreview(true)}
                  className="text-white/90 hover:text-white text-sm font-medium flex items-center gap-2 transition-colors hover:bg-white/5 px-3 py-2 rounded-lg"
                >
                  Preview SpotLight•E
                  <ExternalLink className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div class="max-w-3xl mx-auto px-3">
        <div class="bg-gradient-to-br from-blue-950 via-blue-900 to-indigo-950 rounded-xl relative overflow-hidden">
          <div class="absolute top-0 left-0 right-0 h-[1px] bg-gradient-to-r from-transparent via-blue-400/30 to-transparent"></div>

          <div class="relative px-6 py-5">
            <div class="space-y-4">
              <div class="flex items-center gap-2">
                <Sparkles class="h-4 w-4 text-amber-400" />
                <span class="text-amber-400 text-xs font-medium tracking-wider">Launching This Week</span>
              </div>

              <div class="space-y-1.5">
                <h2 class="text-xl text-white font-semibold">
                  📢 Reach 10,000+ Businesses Worldwide with Spotlight•E ✨{/* 📢 Reach 10,000+ Businesses with Spotlight•E ✨ */}
                  {/* 🚀 Get Your Startup Seen By 10,000+ Businesses */}
                </h2>
                <p class="text-white/80 text-sm">
                  Get your business discovered by key decision-makers on FeedbackByAI. Connect with potential investors, partners, and
                  customers. Generate leads, boost sales, and accelerate your growth.
                  {/* Get your business in front of key decision-makers on FeedbackByAI. Connect with potential investors, partners, and
                  customers. Generate leads and drive business growth. */}
                  {/* Spotlight•E puts your startup in front of investors, partners, and customers on FeedbackByAI. Track every interaction -
                  views, clicks, messages, and meeting requests. */}
                </p>
              </div>

              <div class="space-y-1">
                <p class="text-white/70 text-sm font-medium">✅ Free 7-day trial! Then just $95/month.</p>
              </div>

              <div class="flex items-center gap-4">
                <a
                  href="/spotlighte/create/"
                  class="group relative bg-white hover:bg-white/95 text-blue-950 px-7 py-3.5 rounded-xl text-sm font-semibold tracking-wide transition-all duration-300 flex items-center gap-2 hover:shadow-xl hover:shadow-blue-900/20 ring-1 ring-white/20"
                >
                  <div class="absolute inset-0 bg-gradient-to-r from-blue-500/10 via-transparent to-transparent rounded-xl animate-pulse"></div>
                  <span class="relative">Create Your Free Spotlight Profile</span>
                  <ChevronRight class="w-4 h-4 group-hover:translate-x-1 transition-transform relative" />
                </a>

                <button
                  onClick={() => setShowPreview(true)}
                  class="text-white/90 hover:text-white text-sm font-medium flex items-center gap-2 transition-colors hover:bg-white/5 px-3 py-2 rounded-lg"
                >
                  See How It Works
                  <ExternalLink class="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <main className="max-w-4xl mx-auto px-4 py-12">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl md:text-5xl font-bold text-green-800 text-center mb-8"
        >
          Analyze My Business Idea
        </motion.h1>

        <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">Get comprehensive analysis to refine your business concept.</p>

        <div className="bg-white rounded-2xl shadow-xl p-8 mb-12">
          {message && (
            <div
              className={`mb-6 p-4 rounded-lg ${
                showRechargeLink ? "bg-yellow-50 border-l-4 border-yellow-400" : "bg-red-50 border-l-4 border-red-400"
              }`}
            >
              <p className="text-sm font-medium text-gray-800">{message}</p>
              {showRechargeLink && (
                <Link to="/credits/" className="inline-block mt-2 text-green-600 hover:text-green-800 font-semibold">
                  Recharge credits →
                </Link>
              )}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="businessIdea" className="block text-sm font-medium text-gray-700 mb-2">
                Describe your business idea
              </label>
              <textarea
                id="businessIdea"
                value={businessIdea}
                onChange={(e) => setBusinessIdea(e.target.value)}
                placeholder="Detail your business concept, including unique features, target market, pricing strategy, and potential challenges. The more comprehensive your description, the more tailored our analysis will be."
                rows="7"
                className="w-full p-4 text-gray-700 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200"
                required
              />
            </div>

            <div>
              <label htmlFor="language" className="block text-sm font-medium text-gray-700 mb-2">
                Select Report Language
              </label>
              <div className="relative">
                <select
                  id="language"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-lg appearance-none bg-white"
                >
                  <option value="english">English</option>
                  <option value="hindi">Hindi</option>
                  <option value="spanish">Spanish</option>
                  <option value="french">French</option>
                  <option value="arabic">Arabic</option>
                  <option value="russian">Russian</option>
                  <option value="portuguese">Portuguese</option>
                  <option value="indonesian">Indonesian</option>
                  <option value="german">German</option>
                  <option value="japanese">Japanese</option>
                  <option value="swahili">Swahili</option>
                  <option value="turkish">Turkish</option>
                  <option value="korean">Korean</option>
                  <option value="italian">Italian</option>
                  <option value="vietnamese">Vietnamese</option>
                  <option value="polish">Polish</option>
                  <option value="ukrainian">Ukrainian</option>
                  <option value="dutch">Dutch</option>
                  <option value="greek">Greek</option>
                  <option value="hebrew">Hebrew</option>
                  <option value="thai">Thai</option>
                  <option value="persian">Persian</option>
                  <option value="czech">Czech</option>
                  <option value="swedish">Swedish</option>
                  <option value="romanian">Romanian</option>
                  <option value="hungarian">Hungarian</option>
                  <option value="danish">Danish</option>
                  <option value="finnish">Finnish</option>
                  <option value="slovak">Slovak</option>
                  <option value="norwegian">Norwegian</option>
                  <option value="serbian">Serbian</option>
                  <option value="bulgarian">Bulgarian</option>
                  <option value="mandarin">Mandarin</option>
                  {/* Add more language options here */}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <ChevronDown className="h-4 w-4" />
                </div>
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full bg-gradient-to-r from-green-500 to-green-600 text-white text-lg font-semibold py-3 rounded-lg
                ${isSubmitting ? "opacity-75 cursor-not-allowed" : "hover:from-green-600 hover:to-green-700"}
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-all duration-300 transform hover:-translate-y-1`}
            >
              {isSubmitting ? (
                <span className="flex items-center justify-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Generating Your Report...
                </span>
              ) : (
                "Generate My Business Report!"
              )}
            </button>
          </form>
        </div>

        <div className="bg-white rounded-2xl shadow-lg p-8">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">My Previous Full Reports</h2>
          <p className="text-sm text-gray-600 mb-4">
            *Opening a new report deducts report credits automatically. Select the correct report if you have multiple reports.
          </p>
          {isFetchingReports ? (
            <div className="flex justify-center items-center h-24">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-green-500"></div>
            </div>
          ) : (
            <ul className="space-y-4">
              {latestFullReports.length > 0 ? (
                latestFullReports.map((report) => (
                  <li key={report.report_id} className="border-b border-gray-200 pb-4">
                    <Link to={`/full-report/${report.report_id}`} className="text-blue-500 hover:text-green-800 font-medium">
                      {report.input.substring(0, 100) + (report.input.length > 100 ? "..." : "")}
                    </Link>
                    {report.input.length > 100 && (
                      <button onClick={() => openModal(report)} className="ml-4 text-sm text-gray-500 hover:text-gray-700">
                        View More
                      </button>
                    )}
                  </li>
                ))
              ) : (
                <p className="text-gray-600">No previous reports found. Generate your first comprehensive analysis now!</p>
              )}
            </ul>
          )}
        </div>
      </main>
      <ReportFooter />
      <CustomModal isOpen={isModalOpen} onRequestClose={closeModal} content={selectedReport?.input} />

      <Modal
        isOpen={showBoothForm}
        onClose={() => setShowBoothForm(false)}
        className="bg-white rounded-2xl shadow-xl max-w-2xl mx-auto p-6"
      >
        <h3 className="text-2xl font-bold mb-6">Create Your Virtual Booth</h3>

        <div className="space-y-6">
          <div>
            <label className="font-medium">What You Do</label>
            <input type="text" placeholder="One line about your business" className="w-full mt-2 p-3 border rounded-lg" />
          </div>

          <div>
            <label className="font-medium">Looking For</label>
            <select className="w-full mt-2 p-3 border rounded-lg">
              <option>Suppliers</option>
              <option>Distributors</option>
              <option>Partners</option>
              <option>Buyers</option>
            </select>
          </div>

          <div>
            <label className="font-medium">Best Deals Welcome</label>
            <input type="text" placeholder="Deal size you prefer" className="w-full mt-2 p-3 border rounded-lg" />
          </div>
        </div>

        <div className="flex justify-end mt-8">
          <button className="bg-green-600 text-white px-8 py-3 rounded-lg font-medium">Launch My Booth</button>
        </div>
      </Modal>
      {showModal && <PriorityAccessModal isOpen={showModal} onClose={() => setShowModal(false)} />}

      <PreviewModal isOpen={showPreview} onClose={() => setShowPreview(false)} />
    </div>
  );
}
