import React, { useState, useEffect } from "react";
import axios from "axios";
import DashboardHeader from "./DashboardHeader";
import { Link } from "react-router-dom";
import TopBanner from "./TopBanner";
import Satisfaction from "../images/satisfaction.png";
import SerpAiLogo from "../images/serpai.png";
import TopAiToolsLogo from "../images/topaitools.png";
import ThereIsAnAIForThatLogo from "../images/thereisanaiforthat.png";
import ToolifyLogo from "../images/toolify.png";
import AiHuntLogo from "../images/aihunt.png";
import ReactCountryFlag from "react-country-flag";
import { getName } from "country-list";
import ReportFooter from "./ReportFooter";
import IframeResizer from "iframe-resizer-react";

const pppGroups = {
  "0.1-0.2": {
    countries: ["Iran", "Sudan"],
    // discount: 70,
    discount: 30,
    code: "PDTOMQOR30",
    // code: "PDNTSAAG70", // Replace with actual coupon code
  },
  "0.2-0.3": {
    countries: [
      "Afghanistan",
      "Egypt",
      "Kyrgyzstan",
      "Sri Lanka",
      "Burundi",
      "Bhutan",
      "Georgia",
      "Laos",
      "Lebanon",
      "Libya",
      "Myanmar",
      "Pakistan",
      "Sierra Leone",
      "Tajikistan",
      "Nepal",
      "Suriname",
      "Uzbekistan",
    ],
    // discount: 60,
    discount: 30,
    code: "PDTOMQOR30",
    // code: "PDLJXOLV60", // Replace with actual coupon code
  },
  "0.3-0.4": {
    countries: [
      "Bangladesh",
      "Burkina Faso",
      "Benin",
      "Colombia",
      "Ethiopia",
      "Cambodia",
      "Armenia",
      "Angola",
      "Azerbaijan",
      "Bolivia",
      "Belarus",
      "Algeria",
      "Eritrea",
      "Ghana",
      "Gambia",
      "Guinea-Bissau",
      "Guyana",
      "Indonesia",
      "Kenya",
      "Kazakhstan",
      "Moldova",
      "North Macedonia",
      "Mali",
      "Malawi",
      "Malaysia",
      "Mozambique",
      "Nigeria",
      "Nicaragua",
      "Philippines",
      "Paraguay",
      "Russia",
      "Rwanda",
      "Thailand",
      "Tanzania",
      "Ukraine",
      "Uganda",
      "Vietnam",
      // "India",
      "Madagascar",
      "Mongolia",
      "Mauritania",
      "Mauritius",
      "Somalia",
      "East Timor",
      "Tunisia",
      "Turkey",
      "Zambia",
    ],
    // discount: 50,
    discount: 30,
    code: "PDTOMQOR30",
    // code: "PDJWCEVV50", // Replace with actual coupon code
  },
  "0.4-0.5": {
    countries: [
      "Albania",
      "Bosnia and Herzegovina",
      "Bulgaria",
      "Botswana",
      "Cameroon",
      "Cape Verde",
      "Equatorial Guinea",
      "Honduras",
      "Jordan",
      "Argentina",
      "Bahrain",
      "Brazil",
      "Democratic Republic of the Congo",
      "Ivory Coast",
      "Dominican Republic",
      "Fiji",
      "Guinea",
      "Iraq",
      "Jamaica",
      "Comoros",
      "Liberia",
      "Lesotho",
      "Montenegro",
      "Oman",
      "Panama",
      "Mexico",
      "Namibia",
      "Saudi Arabia",
      "Peru",
      "Poland",
      "Romania",
      "Serbia",
      "Seychelles",
      "Senegal",
      "El Salvador",
      "Turkmenistan",
      "Taiwan",
      "Brunei",
      "Morocco",
      "Maldives",
      "Niger",
      "Eswatini",
      "Chad",
      "Togo",
      "Yemen",
      "South Africa",
    ],
    discount: 30,
    code: "PDTOMQOR30",
    // discount: 40,
    // code: "PDPEMKVY40", // Replace with actual coupon code
  },
  "0.5-0.6": {
    countries: [
      "United Arab Emirates",
      "Djibouti",
      "Ecuador",
      "Guatemala",
      "Croatia",
      "Lithuania",
      "Central African Republic",
      "Chile",
      "Costa Rica",
      "Czech Republic",
      "Gabon",
      "Grenada",
      "Haiti",
      "Hungary",
      "Kuwait",
      "Latvia",
      "Sao Tome and Principe",
      "Trinidad and Tobago",
      "Saint Vincent and the Grenadines",
    ],
    // discount: 30,
    discount: 20,
    code: "PDCMDMGO20",
    // code: "PDTOMQOR30",
    // code: "PDTOMQOR30", // Replace with actual coupon code
  },
  "0.6-0.7": {
    countries: [
      "Estonia",
      "Greece",
      "Saint Kitts and Nevis",
      "Belize",
      "Republic of the Congo",
      "China",
      "Kiribati",
      "Saint Lucia",
      "Portugal",
      "Malta",
      "Qatar",
      "Slovenia",
      "Slovakia",
      "Papua New Guinea",
      "Singapore",
      "Dominica",
      "Macau",
      "Tonga",
      "Uruguay",
      "Venezuela",
      "Samoa",
    ],
    discount: 10,
    code: "PDCMDMGO10", // Replace with actual coupon code
    // discount: 20,
    // code: "PDCMDMGO20", // Replace with actual coupon code
  },
  "0.7-0.8": {
    countries: [
      "Spain",
      "Hong Kong",
      "Aruba",
      "Cyprus",
      "Italy",
      "South Korea",
      "Antigua and Barbuda",
      "San Marino",
      "Zimbabwe",
      "Japan",
      "United States",
      "Germany",
      "United Kingdom",
      "France",
      "Canada",
      "Australia",
      "Netherlands",
      "Sweden",
      "Switzerland",
      "Belgium",
      "Norway",
    ],
    discount: 10,
    code: "PDCMDMGO10", // Replace with actual coupon code
  },
  // "0.8-0.9": {
  //   countries: [
  //     "Belgium",
  //     "Bahamas",
  //     "Germany",
  //     "France",
  //     "Palau",
  //     "Solomon Islands",
  //     "Nauru",
  //   ],
  //   discount: 10,
  //   code: "COUPON10", // Replace with actual coupon code
  // },
  // Add more groups as needed
};

const UnpurchasedBanner = () => {
  return (
    <div className="bg-green-50 border-l-4 border-green-500 rounded-lg text-gray-800 px-6 py-8 shadow-sm my-8 mx-4">
      <h3 className="font-semibold text-2xl md:text-3xl mb-4">Unlock Full Potential Of Your Business Idea</h3>
      <p className="text-lg mb-6">100+ Page Comprehensive Analysis to turn your rough concept into a bulletproof business plan.</p>
      <p className="text-xl font-medium italic mb-6">⭐⭐⭐⭐⭐ Trusted by over 10,000 businesses, investors, and analysts</p>
      <div className="flex flex-wrap justify-center md:justify-start items-center gap-8 mt-6">
        <img src={TopAiToolsLogo} alt="TopAiTools Logo" className="h-10" />
        <img src={SerpAiLogo} alt="Serp.ai Logo" className="h-10" />
        <img src={ThereIsAnAIForThatLogo} alt="ThereIsAnAIForThat Logo" className="h-10" />
        <img src={ToolifyLogo} alt="ToolifyLogo" className="h-10" />
      </div>
    </div>
  );
};

function Credits() {
  const [questionCredits, setQuestionCredits] = useState(0);
  const [reportCredits, setReportCredits] = useState(0);
  const [minireportCredits, setMiniReportCredits] = useState(0);
  const [email, setEmail] = useState([""]);
  const [userID, setUserID] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userCountry, setUserCountry] = useState("");
  const [discountInfo, setDiscountInfo] = useState({});

  const environment = process.env.REACT_APP_ENV;

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      const token = localStorage.getItem("access"); // Assuming token is stored in localStorage
      if (!token) {
        alert("Please log in first!");
        setIsLoading(false);
        return;
      }

      try {
        // Fetch user email and ID
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}get-user-email/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          const { email, id } = response.data;
          setEmail(email);
          setUserID(id);

          // Fetch user location
          const locationResponse = await axios.get("https://ipinfo.io?token=54225501c526ff");
          const countryCode = locationResponse.data.country;
          const countryName = getName(countryCode);
          setUserCountry(countryName);
          findDiscountInfo(countryName);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
    fetchUserCredits();
  }, []); // This effect runs once on component mount.

  const calculatePricing = (basePrice) => {
    if (!discountInfo || !discountInfo.discount) return { originalPrice: basePrice, discountedPrice: basePrice };

    const discountedPrice = basePrice * (1 - discountInfo.discount / 100);
    return {
      originalPrice: basePrice,
      discountedPrice: discountedPrice.toFixed(2),
    };
  };

  const findDiscountInfo = (countryName) => {
    let discountDetails = {};
    Object.entries(pppGroups).forEach(([_, groupInfo]) => {
      if (groupInfo.countries.includes(countryName)) {
        discountDetails = { ...groupInfo, countryName }; // Include countryName
      }
    });
    setDiscountInfo(discountDetails);
  };

  // Define the different parts that change between environments
  const urlConfig = {
    questionsId:
      process.env.REACT_APP_ENV === "production" ? "afc176d7-b7f1-4de7-bd5d-806c10d241b5" : "86ba0ed1-9ce1-4322-a03c-f625fa8cda80",
    reportId: process.env.REACT_APP_ENV === "production" ? "ae705003-1f8d-4a13-80d5-73adf1742630" : "5c6763da-aae6-4cb1-80ac-9fcc2fb17545",
    characterId:
      process.env.REACT_APP_ENV === "production" ? "855feb8c-be09-47c2-9f4d-cb7c3bd6071d" : "114482fb-ae03-4b52-ab0c-d979d538eae7",
  };

  // Base URL
  const baseUrl = "https://feedbackbyai1.lemonsqueezy.com/checkout/buy/";

  const fetchUserCredits = async () => {
    const token = localStorage.getItem("access");
    if (!token) {
      alert("Please log in first!");
      return;
    }
    const bearer_token = "Bearer " + token;
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}get-user-credits/`, {
        headers: {
          Authorization: bearer_token,
        },
      });
      const data = response.data;
      setQuestionCredits(data.question_credits);
      setReportCredits(data.report_credits);
      setMiniReportCredits(data.mini_report_credits);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching user credits:", error);
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <TopBanner />
      <DashboardHeader />

      <div className="min-h-screen px-4 py-20 md:px-16 ">
        <UnpurchasedBanner />

        <div className="max-w-8xl mx-auto bg-gradient-to-b from-gray-50 to-green-100 rounded-xl  p-6 md:p-8 space-y-6">
          {isLoading ? (
            <div className="text-center text-2xl font-semibold">Loading...</div>
          ) : (
            <>
              {/* Credits Section */}
              <div className="space-y-6 mt-10">
                <h1 className="text-4xl font-extrabold text-center">Your Credits</h1>
                <div className="md:grid md:grid-cols-2 gap-8 bg-white shadow-lg p-8 rounded-xl">
                  <CreditBox title="Question Credits" credit={questionCredits} />
                  <CreditBox title="Report Credits" credit={reportCredits} />

                  {/* <CreditBox
                    title="Mini Report Credits"
                    credit={minireportCredits}
                  /> */}
                </div>
              </div>

              <div className="bg-indigo-100 border-t-4  rounded-lg  px-6 py-4 shadow-lg mx-4">
                <h3 className="font-bold text-lg md:text-xl mb-2">Loved Our Service?</h3>
                <p className="text-md md:text-lg text-indigo-800 mb-6">
                  Share your experience and get a <strong>discount coupon code</strong> as a thank you!
                </p>
                <a
                  href="https://testimonial.to/feedbackbyai"
                  className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded"
                >
                  Submit Testimonial
                </a>
              </div>

              {/* Affiliate Program Card */}
              <div className="mt-10">
                <div className="bg-blue-100 border-t-4 border-blue-500 rounded-lg text-blue-900 px-6 py-4 shadow-lg mx-4">
                  <h3 className="font-bold text-lg md:text-xl mb-2">Join Our Affiliate Program</h3>
                  <p className="text-sm md:text-base">
                    Earn While Helping Others Better Their Business! Join our affiliate program and get rewarded for every one you help.
                    {/* p.s. Also join Our Affiliate Program – Earn While Helping Others Better Their Business! */}
                  </p>
                  <a
                    href="https://feedbackbyai1.lemonsqueezy.com/affiliates/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block mt-4 font-bold bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 transition-colors duration-300"
                  >
                    Learn More &amp; Join Now
                  </a>
                </div>
              </div>
              {/* Suggest a Feature  */}

              <div className="mt-10">
                <div className="border-t-4 bg-indigo-100 rounded-lg text-blue-900 px-6 py-4 shadow-lg mx-4">
                  <h3 className="font-bold text-lg md:text-xl mb-2">Suggest a Feature</h3>
                  <p className="text-sm md:text-base">
                    Some feature missing on FeedbackbyAI? Suggest it here.
                    {/* p.s. Also join Our Affiliate Program – Earn While Helping Others Better Their Business! */}
                  </p>
                  <a
                    href="https://form.jotform.com/240401848839462"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block mt-4 font-bold bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 transition-colors duration-300"
                  >
                    Suggest Feature
                  </a>
                </div>
              </div>

              <div className="space-y-8 ">
                {/* 100% Satisifaction Guarantee */}
                <section className="bg-white py-10">
                  <div className="max-w-4xl mx-auto px-4 sm:px-6 text-center">
                    <div className="flex flex-col items-center justify-center space-y-6">
                      <img
                        className="h-48 w-48 md:h-48 md:w-48" // Increase the size of the image
                        src={Satisfaction} // Make sure to use the correct path to your image
                        alt="100% Satisfaction Guarantee Badge"
                      />

                      <p className="mt-8 text-gray-600 text-xl italic">
                        If you're not satisfied with our report, we'll refund you 100% without any questions asked! But we're sure you'll{" "}
                        <span className="text-green-500">love our advice </span> for success in entrepreneurship!
                      </p>
                      <p className="mt-4 text-gray-600 text-md italic font-semibold">
                        *Refund valid within 24 hours of purchase, provided less than 5 sections of the report are generated.
                      </p>
                    </div>
                  </div>
                </section>

                <h1 className="text-4xl font-bold text-center mt-20" id="pricing-card">
                  {/* Unlock Full Potential Of Your Business Idea */}
                  Comprehensive Roadmap For Your Business Idea
                </h1>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
                  {[
                    {
                      price: "$59",
                      plan: "Starter",
                      basePrice: 59, // Add base price here
                      benefits: [
                        "1 Full 100+ Page Business Report",
                        // "Free Preview of Mini Report",
                        "25 AI Character Feedbacks",
                        " Save/Download Your Report as Docs & PDF",

                        " Access to all 50+ AI Experts",
                        // "1 Free Corporate Astrology Consultation",
                        // "✅  Save $21.50",
                      ],
                      linkTo: {
                        local: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/5bd7a26c-6298-46f6-9f17-9f100cfb9d8a?checkout[email]=${email}&checkout[custom][report_credits]=1&checkout[custom][question_credits]=25&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
                        production: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/5bd7a26c-6298-46f6-9f17-9f100cfb9d8a?checkout[email]=${email}&checkout[custom][report_credits]=1&checkout[custom][question_credits]=25&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
                      },

                      // this in live product-->  linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/ff1e004b-3ef0-4478-8837-9f31a31e9b97?checkout[email]=${email}&checkout[custom][report_credits]=1&checkout[custom][question_credits]=50`,
                      // linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/58f0ed06-c27d-40d7-9ebf-3d821c703fa9?checkout[email]=${email}&checkout[custom][report_credits]=1&checkout[custom][question_credits]=50`,
                    },

                    {
                      price: "$99",
                      plan: "Select",
                      basePrice: 99, // Add base price here
                      benefits: [
                        "2 Full 100+ Page Business Report",
                        // "Free Preview of Mini Report",
                        "40 AI Character Feedbacks",
                        // " Save/Download Your Report as PDF",
                        " Save/Download Your Report as Docs & PDF",
                        " Access to all 50+ AI Experts",
                        // "1 Free Corporate Astrology Consultation",
                        // "✅  Save $21.50",
                      ],
                      linkTo: {
                        local: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/e9992d3f-639a-4820-8371-d57b5ab99ec4?checkout[email]=${email}&checkout[custom][report_credits]=2&checkout[custom][question_credits]=40&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
                        production: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/e9992d3f-639a-4820-8371-d57b5ab99ec4?checkout[email]=${email}&checkout[custom][report_credits]=2&checkout[custom][question_credits]=40&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
                      },

                      // this in live product-->  linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/ff1e004b-3ef0-4478-8837-9f31a31e9b97?checkout[email]=${email}&checkout[custom][report_credits]=1&checkout[custom][question_credits]=50`,
                      // linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/58f0ed06-c27d-40d7-9ebf-3d821c703fa9?checkout[email]=${email}&checkout[custom][report_credits]=1&checkout[custom][question_credits]=50`,
                    },

                    // {
                    //   price: "$104",
                    //   plan: "Growth",
                    //   basePrice: 104, // Add base price here
                    //   benefits: [
                    //     "3 Full 100+ Page Business Reports",
                    //     // "Free Preview of Mini Report",
                    //     "50 AI Character Feedbacks",
                    //     " Save/Download Your Report as Docs & PDF",
                    //     "Access to all 50+ AI Experts",
                    //     // "1 Free Corporate Astrology Consultation",
                    //     // " ✅ Save $96",
                    //   ],
                    //   linkTo: {
                    //     local: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/4945e48e-bde4-4616-a83c-7d9ebc6e19fe?checkout[email]=${email}&checkout[custom][report_credits]=3&checkout[custom][question_credits]=50&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
                    //     production: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/60daf73b-479a-439d-a045-4ccef5a02512?checkout[email]=${email}&checkout[custom][report_credits]=3&checkout[custom][question_credits]=50&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
                    //   },

                    //   // this in live product-->  linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/ff1e004b-3ef0-4478-8837-9f31a31e9b97?checkout[email]=${email}&checkout[custom][report_credits]=3&checkout[custom][question_credits]=75`,
                    //   // linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/4945e48e-bde4-4616-a83c-7d9ebc6e19fe?checkout[email]=${email}&checkout[custom][report_credits]=3&checkout[custom][question_credits]=75`,
                    // },
                    {
                      price: "$248",
                      plan: "Pro",
                      basePrice: 248, // Add base price here
                      benefits: [
                        "Save $350",
                        "10 Full 100+ Page Business Reports",
                        // "Free Preview of Mini Report",
                        "100 AI Character Feedbacks",
                        " Save/Download Your Report as Docs & PDF",
                        "Access to all 50+ AI Experts",
                        // "1 Free Corporate Astrology Consultation",
                      ],
                      linkTo: {
                        local: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/c8e93455-8f64-49ff-a7d8-ae517e9c0ea5?checkout[email]=${email}&checkout[custom][report_credits]=10&checkout[custom][question_credits]=100&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
                        production: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/ee88bf6b-38ae-4eac-9e11-d2ca8d38a051?checkout[email]=${email}&checkout[custom][report_credits]=10&checkout[custom][question_credits]=100&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
                      },

                      // this in live product-->  linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/ff1e004b-3ef0-4478-8837-9f31a31e9b97?checkout[email]=${email}&checkout[custom][report_credits]=5&checkout[custom][question_credits]=100`,
                      // linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/c8e93455-8f64-49ff-a7d8-ae517e9c0ea5?checkout[email]=${email}&checkout[custom][report_credits]=5&checkout[custom][question_credits]=100`,
                    },

                    // {
                    //   price: "$491",
                    //   plan: "Ultimate",
                    //   basePrice: 491, // Add base price here
                    //   benefits: [
                    //     "Save $984",
                    //     "25 Full 100+ Page Business Reports",
                    //     // "Free Preview of Mini Report",
                    //     "250 AI Character Feedbacks",
                    //     " Save/Download Your Report as Docs & PDF",
                    //     "Access to all 50+ AI Experts",
                    //     // "1 Free Corporate Astrology Consultation",
                    //   ],
                    //   linkTo: {
                    //     local: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/25a5a875-f9f2-44d0-9ed0-5591f8285391?checkout[email]=${email}&checkout[custom][report_credits]=25&checkout[custom][question_credits]=250&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
                    //     production: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/25a5a875-f9f2-44d0-9ed0-5591f8285391?checkout[email]=${email}&checkout[custom][report_credits]=25&checkout[custom][question_credits]=250&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
                    //   },

                    // this in live product-->  linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/ff1e004b-3ef0-4478-8837-9f31a31e9b97?checkout[email]=${email}&checkout[custom][report_credits]=5&checkout[custom][question_credits]=100`,
                    // linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/c8e93455-8f64-49ff-a7d8-ae517e9c0ea5?checkout[email]=${email}&checkout[custom][report_credits]=5&checkout[custom][question_credits]=100`,
                    // },
                  ].map((plan, index) => (
                    <PricingCard
                      key={index}
                      {...plan}
                      linkTo={plan.linkTo[environment]}
                      isHighlighted={index === 2}
                      originalPrice={calculatePricing(plan.basePrice).originalPrice}
                      price={calculatePricing(plan.basePrice).discountedPrice}
                      discountInfo={discountInfo}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="text-center">
        <h3 className="text-center text-4xl lg:text-5xl text-gray-800 mb-4 font-bold mt-5">Wall of Love</h3>
        <IframeResizer src="https://embed-v2.testimonial.to/w/feedbackbyai" style={{ width: "1px", minWidth: "100%" }} />
      </div>
      <ReportFooter />
    </div>
  );
}

const CreditBox = ({ title, credit }) => (
  <div className="flex flex-col items-center space-y-2 bg-white p-6 rounded-2xl shadow-sm">
    <h2 className="text-lg font-medium text-gray-600">{title}</h2>
    <div className="text-4xl font-light text-green-600">{credit}</div>
  </div>
);

// Define the Feature component for list items with conditional styling for numbers
// Define the Feature component for list items with specific styling
const Feature = ({ children }) => (
  <li className="flex items-center mb-3">
    <svg className="w-5 h-5 text-green-500 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
    </svg>
    <span className="text-gray-700">{children}</span>
  </li>
);
function PricingCard({ plan, price, originalPrice, discountInfo, benefits, linkTo, isHighlighted = false }) {
  const highlightedClasses = isHighlighted ? "border border-green-500 bg-green-50 shadow-2xl" : "shadow-lg";

  // const hasDiscount = discountInfo && discountInfo.code;
  // Determine if there's a discount
  const hasDiscount = discountInfo && discountInfo.discount;
  const pricingMessage = hasDiscount
    ? `🇮🇳 Special Pricing for ${discountInfo.countryName} - ${discountInfo.discount}% off (Use Code ${discountInfo.code})`
    : "";

  // Modified linkTo to include the discount code dynamically
  const finalLinkTo = hasDiscount ? `${linkTo}&checkout%5Bdiscount_code%5D=${discountInfo.code}` : linkTo;

  return (
    <div
      className={`max-w-sm mx-auto bg-white rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 ${highlightedClasses}`}
    >
      {isHighlighted && (
        <div className="text-center py-2 bg-green-100">
          <span className="text-green-600 font-semibold">Best Value Plan!</span>
        </div>
      )}
      <div className="px-6 py-8">
        <div className="text-center">
          <span className="text-3xl font-bold text-gray-900">{plan}</span>
          <div className="mt-2">
            <span className={`text-2xl font-bold ${isHighlighted ? "text-green-600" : "text-gray-900"}`}>
              {plan === "Free" ? "Free" : `$${price}`}
            </span>
            {originalPrice && hasDiscount && <span className="ml-2 text-xl font-bold line-through text-gray-500">${originalPrice}</span>}
          </div>
        </div>
        {pricingMessage && (
          <div className="text-center mt-4">
            <span className="text-green-600 font-semibold">{pricingMessage}</span>
          </div>
        )}
        {/* ... other code */}
        <hr className="my-6 border-gray-200" />
        {/* ... Rest of the component */}
        <ul className="mb-4 space-y-3">
          {benefits.map((benefit, idx) => {
            // Check if the benefit contains a number and highlight it
            const isHighlight = /\d+/.test(benefit);
            return (
              <Feature key={idx} highlight={isHighlight}>
                {benefit}
              </Feature>
            );
          })}
        </ul>
      </div>
      {price !== "$0" && (
        <div className="px-6 pt-6 pb-8 bg-gray-50 rounded-b-lg">
          <Link to={finalLinkTo}>
            <button
              className={`btn-sm block w-full px-4 py-3 text-sm font-semibold text-center text-white ${
                isHighlighted
                  ? "bg-green-600 hover:bg-green-700"
                  : "bg-gradient-to-r from-green-600 to-green-600 hover:bg-gradient-to-r hover:from-green-600 hover:to-green-700 animate-pulse"
              }`}
            >
              {isHighlighted ? "Choose This Plan" : "Buy Now"}
            </button>
          </Link>
        </div>
      )}
    </div>
  );
}

function RechargeOption({ description, price, linkTo }) {
  return (
    <div className="flex justify-between items-center shadow-sm p-4 rounded-lg bg-white">
      <span className="text-xl font-semibold">{description}</span>
      <Link to={linkTo}>
        <button className="bg-green-600 hover:bg-green-700 text-white px-6 py-3 rounded-md transition-colors duration-300 ease-in-out">
          Recharge
        </button>
      </Link>
    </div>
  );
}

export default Credits;
