import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReportNavbar from "./ReportNavbar";
import TableOfContentDemo from "./TableOfContentDemo";
import api from "./axiosConfig";
import SectionDemo from "./SectionDemo";
// import tableOfContentData from "./TableOfContent.json";
import tableOfContentData from "./TableofContentDemo.json";
import SerpAiLogo from "../images/serpai.png";
import TopAiToolsLogo from "../images/topaitools.png";
import ThereIsAnAIForThatLogo from "../images/thereisanaiforthat.png";
import ToolifyLogo from "../images/toolify.png";
import AiHuntLogo from "../images/aihunt.png";
import ReportDemoNavbar from "./ReportDemoNavbar";
import TopBanner from "./TopBanner";

const UnpurchasedBanner = () => {
  return (
    <div className="bg-blue-50 border-l-4 border-green-500 rounded-lg text-gray-800 px-6 py-8 shadow-sm my-8 mx-4">
      <h3 className="font-semibold text-2xl md:text-3xl mb-4">Unlock Full Potential Of Your Business Idea</h3>
      <p className="text-lg mb-6">100+ Page Comprehensive Analysis to turn your rough concept into a bulletproof business plan.</p>
      <p className="text-xl font-medium italic mb-6">⭐⭐⭐⭐⭐ Trusted by over 10,000 businesses, investors, and analysts</p>
      <div className="flex flex-wrap justify-center md:justify-start items-center gap-8 mt-6">
        <img src={TopAiToolsLogo} alt="TopAiTools Logo" className="h-10" />
        <img src={SerpAiLogo} alt="Serp.ai Logo" className="h-10" />
        <img src={ThereIsAnAIForThatLogo} alt="ThereIsAnAIForThat Logo" className="h-10" />
        <img src={ToolifyLogo} alt="ToolifyLogo" className="h-10" />
      </div>
    </div>
  );
};

const ReportDemo = () => {
  const [currentSection, setCurrentSection] = useState(1);
  const [purchased, setPurchased] = useState(false);
  const [sectionsToLoad, setSectionsToLoad] = useState([]);
  const { reportId } = useParams();
  const [userEmail, setUserEmail] = useState("");
  const [credits, setCredits] = useState(0);

  useEffect(() => {
    // existing useEffect logic

    // Display an alert if not purchased and restricted actions are attempted
    if (!purchased) {
      const showAlert = () => {
        alert("Hold on! You are not allowed to do this action.");
      };

      const handleRightClick = (event) => {
        event.preventDefault();
        showAlert();
      };

      const disableCopyShortcut = (event) => {
        if ((event.ctrlKey || event.metaKey) && event.key === "c") {
          event.preventDefault();
          showAlert();
        }
      };

      document.addEventListener("contextmenu", handleRightClick);
      document.addEventListener("keydown", disableCopyShortcut);

      return () => {
        document.removeEventListener("contextmenu", handleRightClick);
        document.removeEventListener("keydown", disableCopyShortcut);
      };
    }
  }, [purchased]);

  useEffect(() => {
    // Handle scroll to determine the current section
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 0 && rect.bottom >= 0) {
          setCurrentSection(index + 1);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const shouldLoadSection = (sectionNumber) => {
    // const sectionNum = parseInt(sectionNumber, 10); // Parse section number to integer
    // return purchased || sectionNum <= 3; // Allow first three sections without purchase
    return true;
  };

  return (
    <div className="font-inter antialiased bg-white text-gray-900 tracking-tight">
      <TopBanner />
      <ReportDemoNavbar purchased={purchased} />
      <div className="h-1 bg-gray-200 fixed top-0 left-0 z-50 w-full">
        <div
          style={{
            width: `${(currentSection / tableOfContentData.sections.length) * 100}%`,
          }}
          className="h-1 bg-gradient-to-r from-blue-600 to-blue-400"
        ></div>
      </div>
      <div className="flex flex-col md:flex-row grow">
        <div className="md:w-1/4 w-full p-4 md:p-0">
          <TableOfContentDemo currentSection={currentSection} purchased={purchased} />
        </div>
        <div className="md:w-3/4 w-full p-4 md:pt-0">
          {/* {!purchased && <UnpurchasedBanner />} */}
          {<UnpurchasedBanner />}

          {tableOfContentData.sections.map((section, sectionIndex) =>
            section.content.map((content, contentIndex) => (
              <React.Fragment key={content.number}>
                {shouldLoadSection(content.number) && (
                  <SectionDemo
                    sectionTitle={content.text}
                    sectionNumber={content.number} // Passing section number separately
                    // reportId={4}
                    reportId={18}
                    id={content.number}
                    purchased={purchased}
                    userEmail={userEmail}
                    defaultOpen={content.number <= 6} // Open the first five sections
                    // defaultOpen={sectionIndex === 0 && contentIndex === 0} // Open only the first section
                  />
                )}
              </React.Fragment>
            ))
          )}
          {/* {!purchased && <BlurComponent />} */}
        </div>
      </div>
    </div>
  );
};

export default ReportDemo;
