// import React, { useState, useEffect } from "react";
// import api from "./axiosConfig";
// import { Plus, ArrowRight, Users, Clock, ChevronRight } from "lucide-react";
// import { useNavigate } from "react-router-dom"; // Import useNavigate

// function FAQSection() {
//   const faqs = [
//     {
//       question: "What is BETTERBY100?",
//       answer:
//         "A one-click platform that gets your product in front of 100 real businesses. They'll improve your messaging, validate pricing, and become potential leads — all within 24 hours.",
//     },
//     {
//       question: "Will this work for my business?",
//       answer:
//         "Whether you're launching a new product or optimizing an existing one, BETTERBY100 helps validate your market fit through real business feedback. Our testers span from startups to enterprises across all industries.",
//     },
//     {
//       question: "How fast will I get results?",
//       answer:
//         "Your test begins instantly. Most tests complete within 24 hours, with early insights available as soon as businesses start providing feedback. Access results in real-time as they come in.",
//     },
//     {
//       question: "Is my product information secure?",
//       answer:
//         "We protect your data through limited test duration (24h only), basic email verification, and showing only essential landing page content. Our speed-focused approach naturally limits exposure.",
//     },
//     {
//       question: "What does it cost?",
//       answer:
//         "Start with a free test to experience the platform. After that, it's $39 per test, or earn free credits by helping other businesses improve their products. Help 3 = Get 1 free test.",
//     },
//   ];

//   return (
//     <section className="bg-gray-50">
//       <div className="max-w-4xl mx-auto px-6 py-24">
//         <h2 className="text-3xl font-semibold text-center mb-16">Everything you need to know</h2>

//         <div className="grid divide-y divide-gray-200">
//           {faqs.map((faq, index) => (
//             <div key={index} className="py-8">
//               <div className="max-w-3xl">
//                 <h3 className="text-xl font-semibold text-gray-900 mb-3">{faq.question}</h3>
//                 <p className="text-lg leading-relaxed text-gray-600">{faq.answer}</p>
//               </div>
//             </div>
//           ))}
//         </div>

//         <div className="mt-16 pt-16 border-t border-gray-200 text-center">
//           <p className="text-gray-600 mb-6 text-lg">Need more details?</p>
//           <a href="mailto:help@betterby100.com" className="text-xl text-blue-600 font-medium hover:text-blue-700 transition-colors">
//             help@betterby100.com
//           </a>
//         </div>
//       </div>
//     </section>
//   );
// }
// export default function BetterBy100Marketplace() {
//   const [tests, setTests] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate(); // Initialize useNavigate hook

//   // useEffect(() => {
//   //   const fetchTests = async () => {
//   //     try {
//   //       const response = await api.get("/landing-pages/");
//   //       const normalizedTests = response.data.map((test) => {
//   //         let parsedData;

//   //         // Parse the JSON string in `data`
//   //         try {
//   //           parsedData = JSON.parse(test.data);
//   //         } catch (e) {
//   //           console.error("Error parsing test data:", e);
//   //           parsedData = {};
//   //         }

//   //         return {
//   //           id: test.id,
//   //           title: parsedData.title || "Untitled Test",
//   //           category: parsedData.category || "General",
//   //           description: parsedData.description || "No description provided.",
//   //           features: parsedData.features || [],
//   //           pricing: parsedData.pricing || "Not specified",
//   //           isActive: test.is_active,
//   //           stats: test.stats || {},
//   //           createdAt: test.created_at,
//   //           updatedAt: test.updated_at,
//   //         };
//   //       });

//   //       setTests(normalizedTests);
//   //     } catch (error) {
//   //       console.error("Error fetching tests:", error);
//   //     } finally {
//   //       setLoading(false);
//   //     }
//   //   };

//   //   fetchTests();
//   // }, []);

//   useEffect(() => {
//     const fetchTests = async () => {
//       try {
//         const response = await api.get("/landing-pages/");
//         const normalizedTests = response.data.map((test) => {
//           let parsedData;

//           // Ensure the `data` field is a dictionary
//           if (typeof test.data === "string") {
//             try {
//               parsedData = JSON.parse(test.data);
//             } catch (e) {
//               console.error("Error parsing test data:", e);
//               parsedData = {};
//             }
//           } else {
//             parsedData = test.data || {};
//           }

//           // Return a normalized test object
//           return {
//             id: test.id,
//             title: parsedData.title || "Untitled Test",
//             category: parsedData.category || "General",
//             description: parsedData.description || "No description provided.",
//             features: parsedData.features || [],
//             pricing: parsedData.pricing || "Not specified",
//             isActive: test.is_active,
//             stats: {
//               views: test.stats?.views || 0,
//               editors: test.stats?.edits || 0,
//               actions: test.stats?.wildcard_actions || 0,
//             },
//             createdAt: new Date(test.created_at).toLocaleString(),
//             updatedAt: new Date(test.updated_at).toLocaleString(),
//             image: test.image || null,
//           };
//         });

//         setTests(normalizedTests);
//       } catch (error) {
//         console.error("Error fetching tests:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchTests();
//   }, []);

//   const mockTests = [
//     {
//       id: 1,
//       title: "AI-Powered CRM Solution",
//       description: "Looking for feedback on pricing and features. Help us validate our enterprise pricing tiers.",
//       category: "SaaS",
//       editors: 45,
//       timeLeft: "12h",
//     },
//     {
//       id: 2,
//       title: "Project Management Tool",
//       description: "Need feedback on our new feature set and UX flow. Focus on team collaboration.",
//       category: "Productivity",
//       editors: 67,
//       timeLeft: "8h",
//     },
//     {
//       id: 3,
//       title: "E-commerce Analytics",
//       description: "Testing new dashboard layouts and metric displays. Seeking input on data visualization.",
//       category: "Analytics",
//       editors: 23,
//       timeLeft: "16h",
//     },
//   ];

//   const testList = loading ? mockTests : tests.length > 0 ? tests : mockTests;

//   return (
//     <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
//       <nav className="bg-white/90 backdrop-blur-lg border-b border-gray-100 sticky top-0 z-10">
//         <div className="max-w-7xl mx-auto px-6 py-4 flex items-center justify-between">
//           <div className="flex items-center gap-8">
//             <a href="/dashboard/" className="font-semibold text-2xl tracking-tight">
//               FeedbackByAI
//             </a>
//             {/* <div className="flex gap-6 text-gray-600">
//               <a href="/features" className="hover:text-gray-900">
//                 Features
//               </a>
//               <a href="/pricing" className="hover:text-gray-900">
//                 Pricing
//               </a>
//               <a href="/about" className="hover:text-gray-900">
//                 About
//               </a>
//             </div> */}
//           </div>
//           <button
//             onClick={() => navigate("/better/create/")}
//             className="bg-blue-600 text-white px-6 py-3 rounded-full text-sm font-medium hover:bg-blue-700 transition-all duration-300 flex items-center gap-2"
//           >
//             Start Testing <Plus className="w-4 h-4" />
//           </button>
//         </div>
//       </nav>

//       {/* Hero Section */}

//       <section className="bg-gradient-to-br from-gray-900 via-gray-800 to-black text-white pt-20 pb-32 px-6 relative overflow-hidden">
//         <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_30%,#1a1a1a_0%,transparent_70%)] opacity-50"></div>
//         <div className="max-w-4xl mx-auto text-center relative z-10">
//           <div className="inline-flex items-center gap-2 bg-blue-600/10 border border-blue-500/20 rounded-full px-4 py-2 mb-8">
//             <span className="animate-pulse relative flex h-2 w-2">
//               <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
//               <span className="relative inline-flex rounded-full h-2 w-2 bg-blue-500"></span>
//             </span>
//             <span className="text-blue-400">One Click. 100 Businesses. 24 Hours.</span>
//           </div>
//           <div className="space-y-6 mb-12">
//             <h1 className="text-7xl font-bold tracking-tight">
//               <span className="bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-400">BETTERBY100</span>
//             </h1>
//             <p className="text-3xl font-medium text-gray-400">Test Your Product With 100 Real Businesses</p>
//             <p className="text-xl text-gray-400 max-w-2xl mx-auto leading-relaxed">
//               Let real businesses improve your messaging, features, and pricing. Get qualified leads who want to buy, partner, or be early
//               adopters.
//             </p>
//           </div>

//           <div className="flex flex-col items-center gap-8">
//             <button
//               onClick={() => navigate("/better/create/")}
//               className="group bg-white text-gray-900 px-8 py-4 rounded-full text-lg font-medium hover:bg-blue-50 transition-all duration-300 flex items-center gap-3 shadow-lg hover:shadow-xl"
//             >
//               Start Free Test
//               <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
//             </button>

//             <div className="flex items-center gap-12 text-sm text-gray-400">
//               <div className="flex items-center gap-3">
//                 <Clock className="w-5 h-5" />
//                 <span className="font-medium">24h Results</span>
//               </div>
//               <div className="flex items-center gap-3">
//                 <Users className="w-5 h-5" />
//                 <span className="font-medium">100 Businesses</span>
//               </div>
//               <div className="flex items-center gap-3">
//                 <Plus className="w-5 h-5" />
//                 <span className="font-medium">First Test Free</span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       {/* How It Works */}
//       <section className="bg-white -mt-16 relative z-20 mb-20 mx-6 rounded-3xl shadow-xl">
//         <div className="max-w-6xl mx-auto px-8 py-16">
//           <h2 className="text-3xl font-semibold text-center mb-16">How It Works</h2>
//           <div className="grid md:grid-cols-3 gap-12">
//             {[
//               {
//                 step: "1",
//                 title: "Create Landing Page",
//                 desc: "Launch your test in 2 minutes with your features, pricing & goals",
//                 icon: Plus,
//               },
//               {
//                 step: "2",
//                 title: "Get Real Feedback",
//                 desc: "100 businesses suggest improvements to your messaging & pricing",
//                 icon: Users,
//               },
//               {
//                 step: "3",
//                 title: "24h Results",
//                 desc: "Get optimized messaging, pricing validation & qualified leads",
//                 icon: Clock,
//               },
//             ].map((item) => (
//               <div key={item.step} className="relative">
//                 <div className="bg-blue-50 w-12 h-12 rounded-full flex items-center justify-center mb-6">
//                   <item.icon className="w-6 h-6 text-blue-600" />
//                 </div>
//                 <h3 className="text-xl font-semibold mb-3">{item.title}</h3>
//                 <p className="text-gray-600 leading-relaxed">{item.desc}</p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>

//       {/* Active Tests */}
//       <section className="max-w-6xl mx-auto px-6 mb-20">
//         <div className="flex justify-between items-end mb-12">
//           <div>
//             <h2 className="text-3xl font-semibold text-gray-900 mb-3">Active Tests</h2>
//             <p className="text-lg text-gray-600">Help improve products, earn test credits</p>
//           </div>
//           <div className="px-4 py-2 bg-green-50 text-green-600 rounded-full text-sm font-medium">{testList.length} live tests</div>
//         </div>

//         <div className="grid gap-4">
//           {testList.map((test) => (
//             <div
//               key={test.id}
//               className="group bg-white rounded-2xl p-8 hover:shadow-xl transition-all duration-300 cursor-pointer border border-gray-100"
//               onClick={() => navigate(`/edit/${test.id}`)}
//             >
//               <div className="flex flex-col lg:flex-row gap-8 items-start">
//                 <div className="flex-1 space-y-4">
//                   <div className="flex flex-wrap items-center gap-4">
//                     <div className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm font-medium">{test.category}</div>
//                     <div className="flex items-center gap-2 text-sm text-gray-500">
//                       <Clock className="w-4 h-4" />
//                       <span>{test.timeLeft || "24h"} remaining</span>
//                     </div>
//                   </div>

//                   <h3 className="text-2xl font-semibold text-gray-900 group-hover:text-blue-600 transition-colors">{test.title}</h3>
//                   <p className="text-lg text-gray-600 leading-relaxed">{test.description}</p>

//                   <div className="flex items-center gap-6">
//                     <div className="w-full bg-gray-100 rounded-full h-2.5">
//                       <div
//                         className="bg-blue-600 h-2.5 rounded-full transition-all duration-500"
//                         style={{ width: `${test.stats?.editors || 0}%` }}
//                       ></div>
//                     </div>
//                     <div className="text-sm font-medium whitespace-nowrap">{test.stats?.editors || 0}/100</div>
//                   </div>
//                 </div>

//                 <button className="lg:w-48 w-full bg-blue-600 text-white px-6 py-4 rounded-full text-base font-medium hover:bg-blue-700 transition-all duration-300 flex items-center justify-center gap-2 group-hover:shadow-lg">
//                   Edit & Earn <ChevronRight className="w-4 h-4" />
//                 </button>
//               </div>
//             </div>
//           ))}
//         </div>
//       </section>

//       <FAQSection />

//       {/* Pricing */}
//       <section className="max-w-6xl mx-auto px-6 py-20">
//         <div className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-3xl p-12 text-white">
//           <div className="max-w-2xl mx-auto text-center mb-12">
//             <h2 className="text-3xl font-semibold mb-4">Simple Pricing</h2>
//             <p className="text-lg text-gray-300">Start free, upgrade when ready</p>
//           </div>

//           <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
//             <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-8 space-y-6">
//               <h3 className="text-xl font-medium">Start Testing</h3>
//               <div className="text-4xl font-semibold">Free</div>
//               <ul className="space-y-4">
//                 {["Create your test", "See first 3 edits", "Preview results"].map((item, i) => (
//                   <li key={i} className="flex items-center gap-3">
//                     <Plus className="w-5 h-5 text-blue-400" />
//                     <span className="text-gray-300">{item}</span>
//                   </li>
//                 ))}
//               </ul>
//               <button
//                 onClick={() => navigate("/better/create/")}
//                 className="w-full bg-white text-gray-900 px-6 py-4 rounded-full text-lg font-medium hover:bg-gray-100 transition-all duration-300"
//               >
//                 Start Free
//               </button>
//             </div>

//             <div className="bg-blue-600 rounded-2xl p-8 space-y-6">
//               <h3 className="text-xl font-medium">Full Access</h3>
//               <div className="text-4xl font-semibold">
//                 $39<span className="text-2xl font-normal opacity-70">/test</span>
//               </div>
//               <p className="text-blue-100">Or help 3 businesses improve their tests</p>
//               <button className="w-full bg-white text-blue-600 px-6 py-4 rounded-full text-lg font-medium hover:bg-blue-50 transition-all duration-300">
//                 Help & Earn Credits
//               </button>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Footer */}
//       <footer className="border-t border-gray-200 bg-white">
//         <div className="max-w-6xl mx-auto px-6 py-12">
//           <div className="grid md:grid-cols-3 gap-8">
//             <div>
//               <h4 className="font-semibold text-lg mb-4">BETTERBY100</h4>
//               <p className="text-gray-600">Market testing for modern businesses</p>
//             </div>
//             <div>
//               <h4 className="font-semibold text-lg mb-4">Contact</h4>
//               <a href="mailto:help@betterby100.com" className="text-blue-600 hover:text-blue-700">
//                 help@betterby100.com
//               </a>
//             </div>
//             <div>
//               <h4 className="font-semibold text-lg mb-4">Legal</h4>
//               <div className="space-y-2">
//                 <a href="#" className="block text-gray-600 hover:text-gray-900">
//                   Terms of Service
//                 </a>
//                 <a href="#" className="block text-gray-600 hover:text-gray-900">
//                   Privacy Policy
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </footer>
//     </div>
//   );
// }

// import React, { useState } from "react";
// import { Zap, ChevronDown, ArrowRight, Clock, Users, Star } from "lucide-react";

// const FAQItem = ({ question, answer }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   return (
//     <div className="border-b border-gray-800">
//       <button onClick={() => setIsOpen(!isOpen)} className="w-full py-6 flex items-center justify-between text-left">
//         <span className="text-lg font-medium">{question}</span>
//         <ChevronDown className={`h-5 w-5 transition-transform ${isOpen ? "rotate-180" : ""}`} />
//       </button>
//       {isOpen && <div className="pb-6 text-gray-400">{answer}</div>}
//     </div>
//   );
// };

// export default function ComingSoon() {
//   return (
//     <div className="min-h-screen bg-black text-white">
//       {/* Nav */}
//       <nav className="text-center py-8">
//         <div className="flex items-center justify-center">
//           <Zap className="h-8 w-8 text-emerald-400" />
//           <span className="ml-2 text-2xl font-bold">FirstLook</span>
//         </div>
//       </nav>

//       {/* Hero */}
//       <div className="max-w-3xl mx-auto px-4 pt-20 pb-16 text-center">
//         <h1 className="text-7xl font-bold mb-8 bg-gradient-to-r from-emerald-400 to-green-400 bg-clip-text text-transparent">
//           The Future of B2B Deals
//         </h1>
//         <p className="text-2xl text-gray-400 mb-12 leading-relaxed">
//           Get your best deal in front of 10,000 hungry businesses. First 20 companies list free.
//         </p>
//         <div className="max-w-md mx-auto">
//           <form className="flex items-center bg-gray-900 rounded-full p-2">
//             <input
//               type="email"
//               placeholder="Enter your email"
//               className="flex-1 bg-transparent px-4 py-2 outline-none placeholder-gray-500"
//             />
//             <button className="bg-emerald-500 text-black px-6 py-2 rounded-full font-medium hover:bg-emerald-400 transition-colors">
//               Join Waitlist
//             </button>
//           </form>
//         </div>
//       </div>

//       {/* Stats */}
//       <div className="max-w-4xl mx-auto px-4 py-16">
//         <div className="grid grid-cols-3 gap-8">
//           {[
//             { icon: Users, label: "Businesses Waiting", value: "10,000+" },
//             { icon: Clock, label: "Launch Date", value: "Next Week" },
//             { icon: Star, label: "Free Spots", value: "20" },
//           ].map((stat, i) => (
//             <div key={i} className="text-center">
//               <stat.icon className="h-8 w-8 mx-auto mb-4 text-emerald-400" />
//               <p className="text-3xl font-bold mb-2">{stat.value}</p>
//               <p className="text-gray-400">{stat.label}</p>
//             </div>
//           ))}
//         </div>
//       </div>

//       {/* Features */}
//       <div className="bg-gradient-to-b from-gray-900 to-black">
//         <div className="max-w-4xl mx-auto px-4 py-24 text-center">
//           <h2 className="text-3xl font-bold mb-16">What You'll Get</h2>
//           <div className="grid md:grid-cols-3 gap-8">
//             {[
//               "Lifetime Access Deals",
//               "Bulk Purchase Rights",
//               "Early Partner Programs",
//               "Revenue Share Deals",
//               "First Mover Advantage",
//               "Instant Deal Flow",
//             ].map((feature, i) => (
//               <div key={i} className="bg-gray-800/50 p-6 rounded-2xl backdrop-blur-sm">
//                 <p className="text-lg">{feature}</p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>

//       {/* FAQ */}
//       <div className="max-w-2xl mx-auto px-4 py-24">
//         <h2 className="text-3xl font-bold mb-12 text-center">Frequently Asked Questions</h2>
//         <div className="space-y-2">
//           <FAQItem
//             question="What kind of deals can I list?"
//             answer="Any B2B deal including lifetime access, bulk purchases, early partner programs, and revenue share opportunities."
//           />
//           <FAQItem
//             question="How long will my deal be visible?"
//             answer="Your deal will be visible to our 10,000 businesses for 7 days from launch."
//           />
//           <FAQItem
//             question="What does 'free listing' include?"
//             answer="Free listings include deal promotion, lead collection, and direct contact with interested partners."
//           />
//           <FAQItem
//             question="When exactly are you launching?"
//             answer="We're launching next Tuesday. First 20 companies get free listings."
//           />
//           <FAQItem
//             question="Who are the 10,000 businesses?"
//             answer="Our audience consists of verified business owners, decision-makers, and investors across multiple industries."
//           />
//         </div>
//       </div>

//       {/* CTA */}
//       <div className="max-w-3xl mx-auto px-4 py-24 text-center">
//         <h2 className="text-4xl font-bold mb-8">Ready to List Your Deal?</h2>
//         <p className="text-xl text-gray-400 mb-12">Join 20 companies getting free access to 10,000 potential partners.</p>
//         <button className="bg-emerald-500 text-black px-8 py-4 rounded-full text-lg font-medium hover:bg-emerald-400 transition-colors">
//           Join Waitlist Now
//         </button>
//       </div>

//       {/* Footer */}
//       <footer className="border-t border-gray-800">
//         <div className="max-w-4xl mx-auto px-4 py-12 text-center text-gray-400">
//           <p>From the team behind AI Business Planner</p>
//           <p className="mt-2">Already trusted by 10,000+ businesses</p>
//         </div>
//       </footer>

//       {/* Fixed Banner */}
//       <div className="fixed bottom-0 inset-x-0 bg-gray-900/80 backdrop-blur-lg border-t border-gray-800">
//         <div className="max-w-5xl mx-auto px-4 py-4 flex items-center justify-center space-x-8">
//           <span className="text-emerald-400 font-medium">14 spots left</span>
//           <span className="text-gray-400">Launch: Next Tuesday</span>
//           <button className="bg-emerald-500 text-black px-6 py-2 rounded-full text-sm font-medium hover:bg-emerald-400 transition-colors">
//             Join Waitlist
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// import React, { useState, useEffect } from "react";
// import { Zap, ArrowRight, Users, Timer, Star, ChevronDown, Activity, Lock } from "lucide-react";

// const CountdownTimer = () => {
//   // [Previous timer code remains same]
// };

// const FAQItem = ({ question, answer }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   return (
//     <div className="border-b border-gray-800">
//       <button onClick={() => setIsOpen(!isOpen)} className="w-full py-6 flex items-center justify-between text-left">
//         <span className="text-lg font-medium">{question}</span>
//         <ChevronDown className={`h-5 w-5 transition-transform ${isOpen ? "rotate-180" : ""}`} />
//       </button>
//       {isOpen && <div className="pb-6 text-gray-400">{answer}</div>}
//     </div>
//   );
// };

// export default function ComingSoon() {
//   return (
//     <div className="min-h-screen bg-black text-white">
//       {/* Nav */}
//       <nav className="border-b border-gray-800">
//         <div className="max-w-7xl mx-auto px-4 py-6 flex justify-center">
//           <div className="flex items-center">
//             <Zap className="h-8 w-8 text-emerald-400" />
//             <span className="ml-2 text-2xl font-bold">FirstLook</span>
//           </div>
//         </div>
//       </nav>

//       {/* Hero */}
//       {/* Hero */}
//       <div className="max-w-4xl mx-auto px-4 pt-20 pb-16 text-center">
//         <div className="inline-block mb-6 bg-gradient-to-r from-emerald-500/20 to-green-500/20 rounded-full px-4 py-2">
//           <span className="text-emerald-400 font-medium">Pre-Launch: First 20 Companies List Free</span>
//         </div>

//         <h1 className="text-7xl font-bold mb-8 bg-gradient-to-r from-emerald-400 via-green-400 to-emerald-400 bg-clip-text text-transparent">
//           Your Private Window into Tomorrow's Best B2B Deals
//         </h1>

//         <p className="text-2xl text-gray-400 mb-12">
//           Get exclusive first access to partnership opportunities and massive discounts before 10,000+ qualified businesses. Where being
//           first means everything.
//         </p>
//       </div>

//       {/* What is FirstLook */}
//       <div className="bg-gradient-to-b from-black to-gray-900">
//         <div className="max-w-4xl mx-auto px-4 py-24">
//           <h2 className="text-3xl font-bold mb-12 text-center">Step Ahead of 10,000 Businesses</h2>

//           <div className="grid md:grid-cols-2 gap-16">
//             <div>
//               <p className="text-xl text-gray-300 leading-relaxed mb-8">
//                 FirstLook gives you a private window to discover and claim the best B2B opportunities before they go public. Every deal is
//                 hand-picked, verified, and offers minimum 40% off regular terms.
//               </p>
//               <p className="text-xl text-gray-300 leading-relaxed">
//                 You get exactly 7 days to act on each opportunity. When 10,000 businesses are waiting to see new deals, being first isn't
//                 just an advantage—it's everything.
//               </p>
//             </div>

//             <div className="space-y-6">
//               <div className="flex items-start">
//                 <Lock className="h-6 w-6 text-emerald-400 mt-1 mr-4" />
//                 <div>
//                   <h3 className="text-lg font-medium mb-2">Private Access Only</h3>
//                   <p className="text-gray-400">Never available to general public</p>
//                 </div>
//               </div>

//               <div className="flex items-start">
//                 <Users className="h-6 w-6 text-emerald-400 mt-1 mr-4" />
//                 <div>
//                   <h3 className="text-lg font-medium mb-2">Verified Audience</h3>
//                   <p className="text-gray-400">10,000 decision makers ready to act</p>
//                 </div>
//               </div>

//               <div className="flex items-start">
//                 <Timer className="h-6 w-6 text-emerald-400 mt-1 mr-4" />
//                 <div>
//                   <h3 className="text-lg font-medium mb-2">7-Day Advantage</h3>
//                   <p className="text-gray-400">Your exclusive window to act first</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Deal Standards */}
//       <div className="max-w-5xl mx-auto px-4 py-24">
//         <h2 className="text-3xl font-bold mb-16 text-center">Every FirstLook Deal Guaranteed</h2>
//         <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//           {[
//             {
//               title: "40%+ Off Regular Terms",
//               description: "Clear, significant value on every deal",
//             },
//             {
//               title: "Verified & Validated",
//               description: "Every business manually checked",
//             },
//             {
//               title: "Direct Connection",
//               description: "No middlemen, straight to decision makers",
//             },
//           ].map((deal, i) => (
//             <div key={i} className="bg-gray-900 p-8 rounded-2xl">
//               <h3 className="text-xl font-medium mb-4">{deal.title}</h3>
//               <p className="text-gray-400">{deal.description}</p>
//             </div>
//           ))}
//         </div>
//       </div>

//       {/* Waitlist Section */}
//       <div className="max-w-3xl mx-auto px-4 py-24 text-center">
//         <h2 className="text-4xl font-bold mb-6">Be Among The First</h2>
//         <p className="text-xl text-gray-400 mb-12">First 20 companies list free. Get early access to 10,000 potential partners.</p>

//         <div className="max-w-md mx-auto">
//           <form className="p-1 bg-gradient-to-r from-emerald-500 to-green-500 rounded-full">
//             <div className="flex bg-black rounded-full p-2">
//               <input
//                 type="email"
//                 placeholder="Enter your business email"
//                 className="flex-1 bg-transparent px-4 py-3 outline-none placeholder-gray-500"
//               />
//               <button className="bg-gradient-to-r from-emerald-500 to-green-500 text-black px-8 py-3 rounded-full font-medium hover:opacity-90 transition-opacity">
//                 Join Waitlist
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>

//       {/* FAQ Section */}

//       {/* FAQ Section with enhanced questions */}
//       <div className="max-w-2xl mx-auto px-4 py-24">
//         <h2 className="text-3xl font-bold mb-12 text-center">Common Questions</h2>
//         <div className="space-y-2">
//           <FAQItem
//             question="What makes a good FirstLook deal?"
//             answer="Every deal must offer minimum 40% off regular terms, be previously unoffered, and come from a verified business with a working product/service."
//           />
//           <FAQItem
//             question="How does the 7-day window work?"
//             answer="Each deal goes live Monday 9am EST and stays active for exactly 7 days. This creates urgency and ensures quick decisions."
//           />
//           <FAQItem
//             question="Who are the 10,000 businesses?"
//             answer="Decision makers from our existing platform - founders, CEOs, and business owners actively looking for new opportunities."
//           />
//           <FAQItem
//             question="What's included in free listing?"
//             answer="Full promotion to 10,000 businesses, deal verification, lead collection, and direct connection with interested partners. No hidden fees."
//           />
//           <FAQItem
//             question="How do you verify deals?"
//             answer="We manually check company background, product validity, regular pricing, and ensure all terms are clearly documented."
//           />
//         </div>
//       </div>

//       {/* Trust Bar */}
//       <div className="border-t border-gray-800">
//         <div className="max-w-5xl mx-auto px-4 py-12 text-center">
//           <p className="text-xl text-gray-400">
//             From the team behind FeedbackByAI
//             <br />
//             <span className="text-white">Already trusted by 10,000+ businesses</span>
//           </p>
//         </div>
//       </div>

//       {/* Fixed Bottom */}
//       <div className="fixed bottom-0 inset-x-0 bg-black/80 backdrop-blur-xl border-t border-gray-800">
//         <div className="max-w-5xl mx-auto px-4 py-4 flex items-center justify-between">
//           <div className="flex items-center space-x-8">
//             <span className="text-emerald-400">14 free spots left</span>
//             <span className="text-gray-400">Launch: Next Tuesday</span>
//           </div>
//           <button className="bg-gradient-to-r from-emerald-500 to-green-500 text-black px-6 py-3 rounded-full text-sm font-medium hover:opacity-90 transition-opacity">
//             Join Waitlist
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

import { useState } from "react";
import { Star, Eye, Clock, MessageSquare, Target, Gift } from "lucide-react";

export default function SpotlightList() {
  const spotlights = [
    {
      id: 1,
      title: "Global Grub",
      description: "Discover & Share Authentic Recipes from Around the World",
      image: "/api/placeholder/1600/800",
      category: "Food & Beverage",
      highlights: ["500K+ social following", "100K+ recipes", "Featured in publications"],
      looking: ["Seed Funding ($200K-500K)", "Marketing & Growth Partner"],
      offering: ["10+ Years Culinary Expertise", "500K+ Social Media Following"],
      creator: {
        name: "Aisha Khan",
        role: "Food Blogger & Recipe Curator",
        avatar: "/api/placeholder/64/64",
      },
      views: 2467,
      interested: 47,
      daysLeft: 2,
    },
    {
      id: 2,
      title: "EcoCharge",
      description: "Revolutionary Solar-Powered Mobile Charging Network",
      image: "/api/placeholder/1600/800",
      category: "CleanTech",
      highlights: ["Patent pending", "Successful pilot", "Corporate partners"],
      looking: ["Series A ($2M)", "Distribution Partners"],
      offering: ["Proven Technology", "B2B Contracts"],
      creator: {
        name: "Michael Chen",
        role: "Clean Tech Engineer",
        avatar: "/api/placeholder/64/64",
      },
      views: 1893,
      interested: 31,
      daysLeft: 3,
    },
    {
      id: 3,
      title: "HealthAI",
      description: "AI-Powered Health Diagnostics Platform",
      image: "/api/placeholder/1600/800",
      category: "Healthcare",
      highlights: ["FDA approval pending", "Clinical trials", "AI patents"],
      looking: ["Medical Partners", "Series B ($5M)"],
      offering: ["Proprietary AI", "Hospital Network"],
      creator: {
        name: "Dr. Sarah Lee",
        role: "Healthcare Innovator",
        avatar: "/api/placeholder/64/64",
      },
      views: 3242,
      interested: 58,
      daysLeft: 4,
    },
  ];

  return (
    <div className="max-w-5xl mx-auto bg-gray-50 min-h-screen p-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold">Featured Opportunities</h1>
        <button className="bg-blue-600 text-white px-6 py-3 rounded-lg">Post Spotlight</button>
      </div>

      <div className="space-y-6">
        {spotlights.map((item) => (
          <div key={item.id} className="bg-white rounded-xl shadow-sm border overflow-hidden">
            <div className="h-64 relative">
              <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent">
                <div className="absolute bottom-6 left-6 text-white">
                  <div className="flex items-center space-x-3 mb-4">
                    <span className="bg-amber-500 px-3 py-1 rounded-full text-sm font-medium flex items-center">
                      <Star className="w-4 h-4 mr-1" />
                      Featured
                    </span>
                    <span className="bg-white/20 backdrop-blur-sm px-3 py-1 rounded-full text-sm">{item.category}</span>
                  </div>
                  <h2 className="text-2xl font-bold mb-2">{item.title}</h2>
                  <p className="text-lg opacity-90">{item.description}</p>
                </div>
              </div>
            </div>

            <div className="p-6 space-y-6">
              <div className="flex items-center justify-between pt-2">
                <div className="flex items-center space-x-4">
                  <img src={item.creator.avatar} alt={item.creator.name} className="w-12 h-12 rounded-full" />
                  <div>
                    <p className="font-bold">{item.creator.name}</p>
                    <p className="text-gray-600 text-sm">{item.creator.role}</p>
                  </div>
                </div>
                <div className="flex items-center space-x-6 text-gray-500">
                  <div className="flex items-center">
                    <Eye className="w-4 h-4 mr-1" />
                    <span>{item.views.toLocaleString()}</span>
                  </div>
                  <div className="flex items-center">
                    <MessageSquare className="w-4 h-4 mr-1" />
                    <span>{item.interested}</span>
                  </div>
                  <div className="bg-blue-50 text-blue-700 px-3 py-1 rounded-lg flex items-center">
                    <Clock className="w-4 h-4 mr-1" />
                    <span>{item.daysLeft} days left</span>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div className="bg-blue-50/50 rounded-xl p-6 border border-blue-100">
                  <div className="flex items-center text-blue-600 mb-3">
                    <Target className="w-5 h-5 mr-2" />
                    <h3 className="font-semibold">Looking For</h3>
                  </div>
                  <ul className="space-y-2">
                    {item.looking.map((need) => (
                      <li key={need} className="flex items-start text-gray-700">
                        <span className="w-2 h-2 bg-blue-600 rounded-full mt-2 mr-2"></span>
                        {need}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="bg-green-50/50 rounded-xl p-6 border border-green-100">
                  <div className="flex items-center text-green-600 mb-3">
                    <Gift className="w-5 h-5 mr-2" />
                    <h3 className="font-semibold">What We Offer</h3>
                  </div>
                  <ul className="space-y-2">
                    {item.offering.map((offer) => (
                      <li key={offer} className="flex items-start text-gray-700">
                        <span className="w-2 h-2 bg-green-600 rounded-full mt-2 mr-2"></span>
                        {offer}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="flex justify-between items-center pt-4 border-t">
                <button className="text-blue-600 hover:text-blue-700">View Details →</button>
                <button className="bg-blue-600 text-white px-6 py-2 rounded-lg">Connect Now</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

// export default SpotlightProduct;

// import React, { useState } from "react";
// import { Star, Clock, AlertCircle, Sparkles, Upload, Target, Gift, Plus, ChevronRight } from "lucide-react";

// export default function CreateSpotlight() {
//   const [formData, setFormData] = useState({
//     name: "",
//     pitch: "",
//     description: "",
//     category: "",
//     location: "",
//     founder: {
//       name: "",
//       role: "",
//       bio: "",
//       photo: null,
//     },
//     coverImage: null,
//     traction: [""],
//     looking: [{ type: "", amount: "" }],
//     offering: [""],
//     duration: 1,
//   });
//   const [step, setStep] = useState("form"); // form, payment, success
//   const [showConnectModal, setShowConnectModal] = useState(false);
//   const [previewMode, setPreviewMode] = useState(false);

//   const handleImageUpload = (e, type) => {
//     const file = e.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         if (type === "cover") {
//           setFormData({ ...formData, coverImage: reader.result });
//         } else {
//           setFormData({
//             ...formData,
//             founder: { ...formData.founder, photo: reader.result },
//           });
//         }
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const addField = (field) => {
//     if (field === "looking") {
//       setFormData({
//         ...formData,
//         looking: [...formData.looking, { type: "", amount: "" }],
//       });
//     } else if (field === "offering") {
//       setFormData({
//         ...formData,
//         offering: [...formData.offering, ""],
//       });
//     } else if (field === "traction") {
//       setFormData({
//         ...formData,
//         traction: [...formData.traction, ""],
//       });
//     }
//   };

//   const updateField = (field, value, index = null, subfield = null) => {
//     if (index !== null) {
//       if (field === "looking") {
//         const newLooking = [...formData.looking];
//         newLooking[index] = {
//           ...newLooking[index],
//           [subfield]: value,
//         };
//         setFormData({ ...formData, looking: newLooking });
//       } else {
//         const newArray = [...formData[field]];
//         newArray[index] = value;
//         setFormData({ ...formData, [field]: newArray });
//       }
//     } else if (field === "founder") {
//       setFormData({
//         ...formData,
//         founder: { ...formData.founder, [subfield]: value },
//       });
//     } else {
//       setFormData({ ...formData, [field]: value });
//     }
//   };

//   const PreviewCard = () => (
//     <div className="bg-white rounded-xl shadow-lg overflow-hidden border">
//       <div className="relative h-48">
//         {formData.coverImage ? (
//           <img src={formData.coverImage} alt="Cover" className="w-full h-full object-cover" />
//         ) : (
//           <div className="absolute inset-0 bg-gray-100 flex items-center justify-center">
//             <Upload className="w-8 h-8 text-gray-400" />
//           </div>
//         )}
//         <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent">
//           <div className="absolute bottom-6 left-6 text-white">
//             <div className="flex items-center space-x-3 mb-3">
//               <span className="bg-amber-500 text-white px-3 py-1 rounded-full text-sm flex items-center">
//                 <Star className="w-4 h-4 mr-1" fill="white" />
//                 Featured
//               </span>
//               {formData.category && (
//                 <span className="bg-white/20 backdrop-blur-sm px-3 py-1 rounded-full text-sm">{formData.category}</span>
//               )}
//               {formData.location && <span className="bg-blue-500 px-3 py-1 rounded-full text-sm">{formData.location}</span>}
//             </div>
//             <h2 className="text-2xl font-bold mb-1">{formData.name || "Your Startup Name"}</h2>
//             <p className="text-lg opacity-90">{formData.pitch || "Your One-line Pitch"}</p>
//           </div>
//         </div>
//       </div>

//       <div className="p-6 space-y-6">
//         {/* Founder Info */}
//         <div className="flex items-center space-x-4">
//           <div className="w-12 h-12 bg-gray-100 rounded-full overflow-hidden">
//             {formData.founder.photo ? (
//               <img src={formData.founder.photo} alt={formData.founder.name} className="w-full h-full object-cover" />
//             ) : (
//               <div className="w-full h-full flex items-center justify-center">
//                 <Upload className="w-4 h-4 text-gray-400" />
//               </div>
//             )}
//           </div>
//           <div>
//             <p className="font-bold">{formData.founder.name || "Founder Name"}</p>
//             <p className="text-gray-600 text-sm">{formData.founder.role || "Role"}</p>
//           </div>
//         </div>

//         {/* Description */}
//         {formData.description && <p className="text-gray-600 line-clamp-3">{formData.description}</p>}

//         {/* Looking For & Offering */}
//         <div className="grid grid-cols-2 gap-6">
//           <div className="bg-blue-50/50 rounded-xl p-6 border border-blue-100">
//             <div className="flex items-center text-blue-600 mb-4">
//               <Target className="w-5 h-5" />
//               <h3 className="font-semibold ml-2">Looking For</h3>
//             </div>
//             <ul className="space-y-3">
//               {formData.looking.map(
//                 (item, i) =>
//                   item.type && (
//                     <li key={i} className="flex items-start text-gray-700">
//                       <span className="w-2 h-2 bg-blue-600 rounded-full mt-2 mr-2"></span>
//                       <span>
//                         {item.type} {item.amount && `• ${item.amount}`}
//                       </span>
//                     </li>
//                   )
//               )}
//             </ul>
//           </div>

//           <div className="bg-green-50/50 rounded-xl p-6 border border-green-100">
//             <div className="flex items-center text-green-600 mb-4">
//               <Gift className="w-5 h-5" />
//               <h3 className="font-semibold ml-2">We Offer</h3>
//             </div>
//             <ul className="space-y-3">
//               {formData.offering.map(
//                 (item, i) =>
//                   item && (
//                     <li key={i} className="flex items-start text-gray-700">
//                       <span className="w-2 h-2 bg-green-600 rounded-full mt-2 mr-2"></span>
//                       <span>{item}</span>
//                     </li>
//                   )
//               )}
//             </ul>
//           </div>
//         </div>

//         {/* Traction */}
//         {formData.traction.some((t) => t) && (
//           <div className="border-t pt-4">
//             <h4 className="font-medium mb-3">Traction & Achievements</h4>
//             <ul className="space-y-2">
//               {formData.traction.map(
//                 (item, i) =>
//                   item && (
//                     <li key={i} className="flex items-center gap-2 text-gray-600">
//                       <span className="w-1.5 h-1.5 bg-blue-600 rounded-full"></span>
//                       {item}
//                     </li>
//                   )
//               )}
//             </ul>
//           </div>
//         )}
//       </div>
//     </div>
//   );

//   return (
//     <div className="min-h-screen bg-gray-50">
//       <div className="max-w-6xl mx-auto px-4 py-12">
//         {/* Header */}
//         <div className="text-center mb-12">
//           <div className="inline-flex items-center gap-2 bg-blue-50 text-blue-700 px-4 py-1.5 rounded-full text-sm font-medium mb-4">
//             <Sparkles className="w-4 h-4" />
//             Early Access: $95/week (Regular $195)
//           </div>
//           <h1 className="text-4xl font-bold text-gray-900 mb-3">Create Your Spotlight</h1>
//           <p className="text-lg text-gray-600">Connect with investors, partners & customers</p>
//         </div>

//         {/* Main Form */}
//         <div className="bg-white rounded-2xl shadow-xl p-8">
//           <div className="grid lg:grid-cols-2 gap-12">
//             {/* Form Section */}
//             <div className="space-y-8">
//               {/* Basic Info */}
//               <section className="space-y-4">
//                 <h3 className="text-lg font-semibold">Basic Information</h3>
//                 <input
//                   type="text"
//                   placeholder="Startup Name"
//                   value={formData.name}
//                   onChange={(e) => updateField("name", e.target.value)}
//                   className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                 />
//                 <input
//                   type="text"
//                   placeholder="One-line Pitch"
//                   value={formData.pitch}
//                   onChange={(e) => updateField("pitch", e.target.value)}
//                   className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                 />
//                 <textarea
//                   placeholder="Detailed Description"
//                   value={formData.description}
//                   onChange={(e) => updateField("description", e.target.value)}
//                   rows="4"
//                   className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                 />
//                 <div className="grid grid-cols-2 gap-4">
//                   <input
//                     type="text"
//                     placeholder="Category"
//                     value={formData.category}
//                     onChange={(e) => updateField("category", e.target.value)}
//                     className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                   />
//                   <input
//                     type="text"
//                     placeholder="Location"
//                     value={formData.location}
//                     onChange={(e) => updateField("location", e.target.value)}
//                     className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                   />
//                 </div>
//               </section>

//               {/* Founder Info */}
//               <section className="space-y-4">
//                 <h3 className="text-lg font-semibold">Founder Information</h3>
//                 <div className="grid grid-cols-2 gap-4">
//                   <input
//                     type="text"
//                     placeholder="Founder Name"
//                     value={formData.founder.name}
//                     onChange={(e) => updateField("founder", e.target.value, null, "name")}
//                     className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                   />
//                   <input
//                     type="text"
//                     placeholder="Role/Title"
//                     value={formData.founder.role}
//                     onChange={(e) => updateField("founder", e.target.value, null, "role")}
//                     className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                   />
//                 </div>
//                 <textarea
//                   placeholder="Bio"
//                   value={formData.founder.bio}
//                   onChange={(e) => updateField("founder", e.target.value, null, "bio")}
//                   rows="3"
//                   className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                 />
//                 <div className="border-2 border-dashed rounded-xl p-6 text-center">
//                   <input
//                     type="file"
//                     accept="image/*"
//                     onChange={(e) => handleImageUpload(e, "founder")}
//                     className="hidden"
//                     id="founderPhoto"
//                   />
//                   <label htmlFor="founderPhoto" className="cursor-pointer">
//                     <Upload className="w-6 h-6 mx-auto mb-2 text-gray-400" />
//                     <p className="text-sm text-gray-500">Upload Founder Photo</p>
//                   </label>
//                 </div>
//               </section>

//               {/* Cover Image */}
//               <section className="space-y-4">
//                 <h3 className="text-lg font-semibold">Cover Image</h3>
//                 <div className="border-2 border-dashed rounded-xl p-8 text-center">
//                   <input type="file" accept="image/*" onChange={(e) => handleImageUpload(e, "cover")} className="hidden" id="coverImage" />
//                   <label htmlFor="coverImage" className="cursor-pointer">
//                     <Upload className="w-8 h-8 mx-auto mb-2 text-gray-400" />
//                     <p className="text-sm text-gray-500">Upload Cover Image (16:9)</p>
//                   </label>
//                 </div>
//               </section>

//               {/* Looking For */}
//               <section className="space-y-4">
//                 <h3 className="text-lg font-semibold">What You're Looking For</h3>
//                 {formData.looking.map((item, index) => (
//                   <div key={index} className="grid grid-cols-2 gap-4">
//                     <input
//                       type="text"
//                       placeholder="Type (e.g., Investment)"
//                       value={item.type}
//                       onChange={(e) => updateField("looking", e.target.value, index, "type")}
//                       className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                     />
//                     <input
//                       type="text"
//                       placeholder="Amount/Details"
//                       value={item.amount}
//                       onChange={(e) => updateField("looking", e.target.value, index, "amount")}
//                       className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                     />
//                   </div>
//                 ))}
//                 <button onClick={() => addField("looking")} className="text-blue-600 text-sm font-medium flex items-center gap-1">
//                   <Plus className="w-4 h-4" /> Add Another Need
//                 </button>
//               </section>

//               {/* Offering */}
//               <section className="space-y-4">
//                 <h3 className="text-lg font-semibold">What You Offer</h3>
//                 {formData.offering.map((item, index) => (
//                   <input
//                     key={index}
//                     type="text"
//                     placeholder="e.g., 'Proprietary Technology'"
//                     value={item}
//                     onChange={(e) => updateField("offering", e.target.value, index)}
//                     className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                   />
//                 ))}
//                 <button onClick={() => addField("offering")} className="text-blue-600 text-sm font-medium flex items-center gap-1">
//                   <Plus className="w-4 h-4" /> Add Offering
//                 </button>
//               </section>

//               {/* Traction */}
//               <section className="space-y-4">
//                 <h3 className="text-lg font-semibold">Traction & Achievements</h3>
//                 {formData.traction.map((item, index) => (
//                   <input
//                     key={index}
//                     type="text"
//                     placeholder="e.g., '10K+ Monthly Users'"
//                     value={item}
//                     onChange={(e) => updateField("traction", e.target.value, index)}
//                     className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                   />
//                 ))}
//                 <button onClick={() => addField("traction")} className="text-blue-600 text-sm font-medium flex items-center gap-1">
//                   <Plus className="w-4 h-4" /> Add Achievement
//                 </button>
//               </section>

//               {/* Duration */}
//               <section className="bg-blue-50 rounded-xl p-6 space-y-4">
//                 <h3 className="font-semibold">Spotlight Duration</h3>
//                 <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
//                   {[1, 4, 8, 12].map((weeks) => (
//                     <button
//                       key={weeks}
//                       onClick={() => updateField("duration", weeks)}
//                       className={`bg-white p-4 rounded-xl border-2 transition-all ${
//                         formData.duration === weeks ? "border-blue-500" : "border-transparent hover:border-blue-300"
//                       }`}
//                     >
//                       <div className="font-medium">
//                         {weeks} Week{weeks > 1 ? "s" : ""}
//                       </div>
//                       <div className="text-sm text-gray-600">${95 * weeks}</div>
//                     </button>
//                   ))}
//                 </div>
//               </section>
//             </div>

//             {/* Preview Panel */}
//             <div className="lg:border-l lg:pl-12">
//               <div className="sticky top-8 space-y-6">
//                 <h3 className="text-lg font-semibold">Live Preview</h3>
//                 <PreviewCard />

//                 <div className="bg-blue-50 rounded-xl p-6">
//                   <h4 className="font-medium mb-4">What Happens Next?</h4>
//                   <ul className="space-y-3">
//                     <li className="flex items-start gap-3 text-sm">
//                       <Clock className="w-4 h-4 mt-1 text-blue-600" />
//                       <span>Review within 24-48 hours</span>
//                     </li>

//                     <li className="flex items-start gap-3 text-sm">
//                       <Target className="w-4 h-4 mt-1 text-blue-600" />
//                       <span>Featured across our platform</span>
//                     </li>
//                     <li className="flex items-start gap-3 text-sm">
//                       <AlertCircle className="w-4 h-4 mt-1 text-blue-600" />
//                       <span>Email to all 10k+ business sent once/week</span>
//                     </li>
//                     <li className="flex items-start gap-3 text-sm">
//                       <Gift className="w-4 h-4 mt-1 text-blue-600" />
//                       <span>Track views, clicks & messages</span>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>

//           {/* Submit Button */}
//           <div className="mt-12 max-w-xl mx-auto">
//             <button className="w-full bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white py-4 rounded-xl font-medium shadow-lg transition-all">
//               Pay & Submit for Review • ${formData.duration * 95}
//             </button>
//             <p className="text-center text-sm text-gray-500 mt-4">Your spotlight will be reviewed within 24-48 hours</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// import React, { useState, useEffect } from "react";
// import { Star, Clock, AlertCircle, Sparkles, Upload, Target, Gift, Plus } from "lucide-react";
// import axios from "axios";

// export default function CreateSpotlight() {
//   const [formData, setFormData] = useState({
//     name: "",
//     pitch: "",
//     description: "",
//     category: "",
//     location: "",
//     founder: {
//       name: "",
//       role: "",
//       bio: "",
//       photo: null,
//     },
//     coverImage: null,
//     traction: [""],
//     looking: [{ type: "", amount: "" }],
//     offering: [""],
//     duration: 1,
//   });
//   const [userData, setUserData] = useState({ email: "", id: "" });
//   const [environment, setEnvironment] = useState("local");
//   const [step, setStep] = useState("form"); // "form", "payment", "success"
//   const [previewMode, setPreviewMode] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState("");

//   const lemonSqueezyUrls = {
//     local: {
//       1: "https://feedbackbyai1.lemonsqueezy.com/buy/b04375e6-03c6-41f0-b38a-12db842ac8df",
//       2: "https://feedbackbyai1.lemonsqueezy.com/buy/77eb5d12-68d9-4522-8c4e-5fe09cc6be25",
//       3: "https://feedbackbyai1.lemonsqueezy.com/buy/ac622e09-a54b-464a-af37-049a02d78fe8",
//       5: "https://feedbackbyai1.lemonsqueezy.com/buy/b0a4ed91-1a58-4e95-9703-b20ea303e628",
//     },
//     production: {
//       1: "https://feedbackbyai1.lemonsqueezy.com/buy/94e27f93-2a8b-442f-b12e-0ac946b34736",
//       2: "https://feedbackbyai1.lemonsqueezy.com/buy/95a249ed-5f15-4aa8-957c-6fda68b8b870",
//       3: "https://feedbackbyai1.lemonsqueezy.com/buy/d446dc95-1c70-4e2b-adaf-85ed01a780c4",
//       5: "https://feedbackbyai1.lemonsqueezy.com/buy/380e8c03-7d75-4fb3-a34b-31f36ab8d2dd",
//     },
//   };

//   useEffect(() => {
//     // Fetch user data
//     const fetchUserData = async () => {
//       const token = localStorage.getItem("access");
//       if (!token) {
//         alert("Please log in first!");
//         return;
//       }
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-user-data/`, {
//           headers: { Authorization: `Bearer ${token}` },
//         });
//         const { email, id } = response.data;
//         setUserData({ email, id });
//       } catch (error) {
//         console.error("Error fetching user data:", error);
//       }
//     };

//     fetchUserData();
//   }, []);

//   const handleImageUpload = (e, type) => {
//     const file = e.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         if (type === "cover") {
//           setFormData({ ...formData, coverImage: file });
//         } else {
//           setFormData({
//             ...formData,
//             founder: { ...formData.founder, photo: file },
//           });
//         }
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const addField = (field) => {
//     if (field === "looking") {
//       setFormData({
//         ...formData,
//         looking: [...formData.looking, { type: "", amount: "" }],
//       });
//     } else if (field === "offering") {
//       setFormData({
//         ...formData,
//         offering: [...formData.offering, ""],
//       });
//     } else if (field === "traction") {
//       setFormData({
//         ...formData,
//         traction: [...formData.traction, ""],
//       });
//     }
//   };

//   const updateField = (field, value, index = null, subfield = null) => {
//     if (index !== null) {
//       if (field === "looking") {
//         const newLooking = [...formData.looking];
//         newLooking[index] = {
//           ...newLooking[index],
//           [subfield]: value,
//         };
//         setFormData({ ...formData, looking: newLooking });
//       } else {
//         const newArray = [...formData[field]];
//         newArray[index] = value;
//         setFormData({ ...formData, [field]: newArray });
//       }
//     } else if (field === "founder") {
//       setFormData({
//         ...formData,
//         founder: { ...formData.founder, [subfield]: value },
//       });
//     } else {
//       setFormData({ ...formData, [field]: value });
//     }
//   };

//   const handleSubmit = async () => {
//     setLoading(true);
//     setError("");
//     const token = localStorage.getItem("access");
//     if (!token) {
//       alert("Please log in first!");
//       setLoading(false);
//       return;
//     }

//     const form = new FormData();
//     form.append(
//       "data",
//       JSON.stringify({
//         name: formData.name,
//         pitch: formData.pitch,
//         description: formData.description,
//         category: formData.category,
//         location: formData.location,
//         founder: {
//           name: formData.founder.name,
//           role: formData.founder.role,
//           bio: formData.founder.bio,
//         },
//         traction: formData.traction,
//         looking: formData.looking,
//         offering: formData.offering,
//         duration: formData.duration,
//       })
//     );
//     if (formData.coverImage) form.append("cover_image", formData.coverImage);
//     if (formData.founder.photo) form.append("founder_photo", formData.founder.photo);

//     try {
//       const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/spotlight/create/`, form, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       alert("Spotlight created successfully!");
//       setStep("payment");
//     } catch (error) {
//       console.error("Error creating spotlight:", error);
//       setError("Failed to create spotlight. Please try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handlePayment = () => {
//     const paymentUrl = lemonSqueezyUrls[environment][formData.duration].concat(
//       `?checkout[email]=${userData.email}&checkout[custom][userId]=${userData.id}&checkout[custom][spotlight_days]=${formData.duration * 7}`
//     );
//     window.location.href = paymentUrl;
//   };

//   const PreviewCard = () => (
//     <div className="bg-white rounded-xl shadow-lg overflow-hidden border">
//       <div className="relative h-48">
//         {formData.coverImage ? (
//           <img src={formData.coverImage} alt="Cover" className="w-full h-full object-cover" />
//         ) : (
//           <div className="absolute inset-0 bg-gray-100 flex items-center justify-center">
//             <Upload className="w-8 h-8 text-gray-400" />
//           </div>
//         )}
//         <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent">
//           <div className="absolute bottom-6 left-6 text-white">
//             <div className="flex items-center space-x-3 mb-3">
//               <span className="bg-amber-500 text-white px-3 py-1 rounded-full text-sm flex items-center">
//                 <Star className="w-4 h-4 mr-1" fill="white" />
//                 Featured
//               </span>
//               {formData.category && (
//                 <span className="bg-white/20 backdrop-blur-sm px-3 py-1 rounded-full text-sm">{formData.category}</span>
//               )}
//               {formData.location && <span className="bg-blue-500 px-3 py-1 rounded-full text-sm">{formData.location}</span>}
//             </div>
//             <h2 className="text-2xl font-bold mb-1">{formData.name || "Your Startup Name"}</h2>
//             <p className="text-lg opacity-90">{formData.pitch || "Your One-line Pitch"}</p>
//           </div>
//         </div>
//       </div>

//       <div className="p-6 space-y-6">
//         {/* Founder Info */}
//         <div className="flex items-center space-x-4">
//           <div className="w-12 h-12 bg-gray-100 rounded-full overflow-hidden">
//             {formData.founder.photo ? (
//               <img src={formData.founder.photo} alt={formData.founder.name} className="w-full h-full object-cover" />
//             ) : (
//               <div className="w-full h-full flex items-center justify-center">
//                 <Upload className="w-4 h-4 text-gray-400" />
//               </div>
//             )}
//           </div>
//           <div>
//             <p className="font-bold">{formData.founder.name || "Founder Name"}</p>
//             <p className="text-gray-600 text-sm">{formData.founder.role || "Role"}</p>
//           </div>
//         </div>

//         {/* Description */}
//         {formData.description && <p className="text-gray-600 line-clamp-3">{formData.description}</p>}

//         {/* Looking For & Offering */}
//         <div className="grid grid-cols-2 gap-6">
//           <div className="bg-blue-50/50 rounded-xl p-6 border border-blue-100">
//             <div className="flex items-center text-blue-600 mb-4">
//               <Target className="w-5 h-5" />
//               <h3 className="font-semibold ml-2">Looking For</h3>
//             </div>
//             <ul className="space-y-3">
//               {formData.looking.map(
//                 (item, i) =>
//                   item.type && (
//                     <li key={i} className="flex items-start text-gray-700">
//                       <span className="w-2 h-2 bg-blue-600 rounded-full mt-2 mr-2"></span>
//                       <span>
//                         {item.type} {item.amount && `• ${item.amount}`}
//                       </span>
//                     </li>
//                   )
//               )}
//             </ul>
//           </div>

//           <div className="bg-green-50/50 rounded-xl p-6 border border-green-100">
//             <div className="flex items-center text-green-600 mb-4">
//               <Gift className="w-5 h-5" />
//               <h3 className="font-semibold ml-2">We Offer</h3>
//             </div>
//             <ul className="space-y-3">
//               {formData.offering.map(
//                 (item, i) =>
//                   item && (
//                     <li key={i} className="flex items-start text-gray-700">
//                       <span className="w-2 h-2 bg-green-600 rounded-full mt-2 mr-2"></span>
//                       <span>{item}</span>
//                     </li>
//                   )
//               )}
//             </ul>
//           </div>
//         </div>

//         {/* Traction */}
//         {formData.traction.some((t) => t) && (
//           <div className="border-t pt-4">
//             <h4 className="font-medium mb-3">Traction & Achievements</h4>
//             <ul className="space-y-2">
//               {formData.traction.map(
//                 (item, i) =>
//                   item && (
//                     <li key={i} className="flex items-center gap-2 text-gray-600">
//                       <span className="w-1.5 h-1.5 bg-blue-600 rounded-full"></span>
//                       {item}
//                     </li>
//                   )
//               )}
//             </ul>
//           </div>
//         )}
//       </div>
//     </div>
//   );

//   return (
//     <div className="min-h-screen bg-gray-50">
//       <div className="max-w-6xl mx-auto px-4 py-12">
//         {/* Header */}
//         <div className="text-center mb-12">
//           <div className="inline-flex items-center gap-2 bg-blue-50 text-blue-700 px-4 py-1.5 rounded-full text-sm font-medium mb-4">
//             <Sparkles className="w-4 h-4" />
//             Early Access: $95/week (Regular $195)
//           </div>
//           <h1 className="text-4xl font-bold text-gray-900 mb-3">Create Your Spotlight</h1>
//           <p className="text-lg text-gray-600">Connect with investors, partners & customers</p>
//         </div>

//         {/* Main Form */}
//         <div className="bg-white rounded-2xl shadow-xl p-8">
//           <div className="grid lg:grid-cols-2 gap-12">
//             {/* Form Section */}
//             <div className="space-y-8">
//               {/* Basic Info */}
//               <section className="space-y-4">
//                 <h3 className="text-lg font-semibold">Basic Information</h3>
//                 <input
//                   type="text"
//                   placeholder="Startup Name"
//                   value={formData.name}
//                   onChange={(e) => updateField("name", e.target.value)}
//                   className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                 />
//                 <input
//                   type="text"
//                   placeholder="One-line Pitch"
//                   value={formData.pitch}
//                   onChange={(e) => updateField("pitch", e.target.value)}
//                   className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                 />
//                 <textarea
//                   placeholder="Detailed Description"
//                   value={formData.description}
//                   onChange={(e) => updateField("description", e.target.value)}
//                   rows="4"
//                   className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                 />
//                 <div className="grid grid-cols-2 gap-4">
//                   <input
//                     type="text"
//                     placeholder="Category"
//                     value={formData.category}
//                     onChange={(e) => updateField("category", e.target.value)}
//                     className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                   />
//                   <input
//                     type="text"
//                     placeholder="Location"
//                     value={formData.location}
//                     onChange={(e) => updateField("location", e.target.value)}
//                     className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                   />
//                 </div>
//               </section>

//               {/* Founder Info */}
//               <section className="space-y-4">
//                 <h3 className="text-lg font-semibold">Founder Information</h3>
//                 <div className="grid grid-cols-2 gap-4">
//                   <input
//                     type="text"
//                     placeholder="Founder Name"
//                     value={formData.founder.name}
//                     onChange={(e) => updateField("founder", e.target.value, null, "name")}
//                     className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                   />
//                   <input
//                     type="text"
//                     placeholder="Role/Title"
//                     value={formData.founder.role}
//                     onChange={(e) => updateField("founder", e.target.value, null, "role")}
//                     className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                   />
//                 </div>
//                 <textarea
//                   placeholder="Bio"
//                   value={formData.founder.bio}
//                   onChange={(e) => updateField("founder", e.target.value, null, "bio")}
//                   rows="3"
//                   className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                 />
//                 <div className="border-2 border-dashed rounded-xl p-6 text-center">
//                   <input
//                     type="file"
//                     accept="image/*"
//                     onChange={(e) => handleImageUpload(e, "founder")}
//                     className="hidden"
//                     id="founderPhoto"
//                   />
//                   <label htmlFor="founderPhoto" className="cursor-pointer">
//                     <Upload className="w-6 h-6 mx-auto mb-2 text-gray-400" />
//                     <p className="text-sm text-gray-500">Upload Founder Photo</p>
//                   </label>
//                 </div>
//               </section>

//               {/* Cover Image */}
//               <section className="space-y-4">
//                 <h3 className="text-lg font-semibold">Cover Image</h3>
//                 <div className="border-2 border-dashed rounded-xl p-8 text-center">
//                   <input type="file" accept="image/*" onChange={(e) => handleImageUpload(e, "cover")} className="hidden" id="coverImage" />
//                   <label htmlFor="coverImage" className="cursor-pointer">
//                     <Upload className="w-8 h-8 mx-auto mb-2 text-gray-400" />
//                     <p className="text-sm text-gray-500">Upload Cover Image (16:9)</p>
//                   </label>
//                 </div>
//               </section>

//               {/* Looking For */}
//               <section className="space-y-4">
//                 <h3 className="text-lg font-semibold">What You're Looking For</h3>
//                 {formData.looking.map((item, index) => (
//                   <div key={index} className="grid grid-cols-2 gap-4">
//                     <input
//                       type="text"
//                       placeholder="Type (e.g., Investment)"
//                       value={item.type}
//                       onChange={(e) => updateField("looking", e.target.value, index, "type")}
//                       className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                     />
//                     <input
//                       type="text"
//                       placeholder="Amount/Details"
//                       value={item.amount}
//                       onChange={(e) => updateField("looking", e.target.value, index, "amount")}
//                       className="p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                     />
//                   </div>
//                 ))}
//                 <button onClick={() => addField("looking")} className="text-blue-600 text-sm font-medium flex items-center gap-1">
//                   <Plus className="w-4 h-4" /> Add Another Need
//                 </button>
//               </section>

//               {/* Offering */}
//               <section className="space-y-4">
//                 <h3 className="text-lg font-semibold">What You Offer</h3>
//                 {formData.offering.map((item, index) => (
//                   <input
//                     key={index}
//                     type="text"
//                     placeholder="e.g., 'Proprietary Technology'"
//                     value={item}
//                     onChange={(e) => updateField("offering", e.target.value, index)}
//                     className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                   />
//                 ))}
//                 <button onClick={() => addField("offering")} className="text-blue-600 text-sm font-medium flex items-center gap-1">
//                   <Plus className="w-4 h-4" /> Add Offering
//                 </button>
//               </section>

//               {/* Traction */}
//               <section className="space-y-4">
//                 <h3 className="text-lg font-semibold">Traction & Achievements</h3>
//                 {formData.traction.map((item, index) => (
//                   <input
//                     key={index}
//                     type="text"
//                     placeholder="e.g., '10K+ Monthly Users'"
//                     value={item}
//                     onChange={(e) => updateField("traction", e.target.value, index)}
//                     className="w-full p-3 rounded-xl border focus:ring-2 focus:ring-blue-500"
//                   />
//                 ))}
//                 <button onClick={() => addField("traction")} className="text-blue-600 text-sm font-medium flex items-center gap-1">
//                   <Plus className="w-4 h-4" /> Add Achievement
//                 </button>
//               </section>

//               {/* Duration */}
//               <section className="bg-blue-50 rounded-xl p-6 space-y-4">
//                 <h3 className="font-semibold">Spotlight Duration</h3>
//                 <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
//                   {[1, 2, 3, 5].map((weeks) => (
//                     <button
//                       key={weeks}
//                       onClick={() => updateField("duration", weeks)}
//                       className={`bg-white p-4 rounded-xl border-2 transition-all ${
//                         formData.duration === weeks ? "border-blue-500" : "border-transparent hover:border-blue-300"
//                       }`}
//                     >
//                       <div className="font-medium">
//                         {weeks} Week{weeks > 1 ? "s" : ""}
//                       </div>
//                       <div className="text-sm text-gray-600">${95 * weeks}</div>
//                     </button>
//                   ))}
//                 </div>
//               </section>
//             </div>

//             {/* Preview Panel */}
//             <div className="lg:border-l lg:pl-12">
//               <div className="sticky top-8 space-y-6">
//                 <h3 className="text-lg font-semibold">Live Preview</h3>
//                 <PreviewCard />

//                 <div className="bg-blue-50 rounded-xl p-6">
//                   <h4 className="font-medium mb-4">What Happens Next?</h4>
//                   <ul className="space-y-3">
//                     <li className="flex items-start gap-3 text-sm">
//                       <Clock className="w-4 h-4 mt-1 text-blue-600" />
//                       <span>Review within 24-48 hours</span>
//                     </li>

//                     <li className="flex items-start gap-3 text-sm">
//                       <Target className="w-4 h-4 mt-1 text-blue-600" />
//                       <span>Featured across our platform</span>
//                     </li>
//                     <li className="flex items-start gap-3 text-sm">
//                       <AlertCircle className="w-4 h-4 mt-1 text-blue-600" />
//                       <span>Email to all 10k+ business sent once/week</span>
//                     </li>
//                     <li className="flex items-start gap-3 text-sm">
//                       <Gift className="w-4 h-4 mt-1 text-blue-600" />
//                       <span>Track views, clicks & messages</span>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>

//           {/* Submit Button */}
//           <div className="mt-12 max-w-xl mx-auto">
//             {step === "form" && (
//               <button
//                 onClick={handleSubmit}
//                 className="w-full bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white py-4 rounded-xl font-medium shadow-lg transition-all"
//                 disabled={loading}
//               >
//                 {loading ? "Submitting..." : `Pay & Submit for Review • $${formData.duration * 95}`}
//               </button>
//             )}
//             {step === "payment" && (
//               <button
//                 onClick={handlePayment}
//                 className="w-full bg-gradient-to-r from-green-600 to-green-700 hover:from-green-700 hover:to-green-800 text-white py-4 rounded-xl font-medium shadow-lg transition-all"
//               >
//                 Proceed to Payment
//               </button>
//             )}
//             <p className="text-center text-sm text-gray-500 mt-4">Your spotlight will be reviewed within 24-48 hours</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
