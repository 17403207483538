import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import Logo from "../../images/thumbnail.png";
import {
  Menu,
  X,
  Search,
  Bell,
  ChevronDown,
  Check,
  MessageCircle,
  Send,
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon,
  ChartBarIcon,
  LucideCloudLightning,
  SearchIcon,
  BriefcaseIcon,
  RocketIcon,
  ArrowRight,
  Calendar,
  Clock,
} from "lucide-react";

const NavLink = ({ href, children }) => (
  <motion.a
    href={href}
    className="text-gray-800 hover:text-blue-600 transition-colors duration-300"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    {children}
  </motion.a>
);

const SecondArticle = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="min-h-screen font-sans text-gray-900">
      {/* Header */}
      <motion.header
        className={`bg-white backdrop-blur-md bg-opacity-80 shadow-sm sticky top-0 z-50 transition-all duration-300 ${
          scrolled ? "py-2" : "py-4"
        }`}
      >
        <nav className="container mx-auto px-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <Link to="/" className="flex items-center text-2xl font-bold text-gray-800">
                <img src={Logo} alt="FeedbackbyAI Logo" className="h-10 w-auto mr-2" />
                <span className="text-2xl font-bold text-blue-600">FeedbackbyAI</span>
              </Link>
            </div>
            <div className="hidden md:flex space-x-8">
              <motion.a
                href="/signin/"
                className="bg-green-500 text-white px-6 py-2 rounded-full font-semibold hover:bg-blue-700 transition-colors duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Signin
              </motion.a>
            </div>
            <motion.button
              className="md:hidden"
              onClick={() => setMenuOpen(!menuOpen)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {menuOpen ? <X /> : <Menu />}
            </motion.button>
          </div>
          <AnimatePresence>
            {menuOpen && (
              <motion.div
                className="mt-4 md:hidden space-y-4"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <NavLink href="#features">Features</NavLink>
                <NavLink href="#how-it-works">How It Works</NavLink>
                <NavLink href="#pricing">Pricing</NavLink>
                <motion.a
                  href="/signup/"
                  className="block bg-blue-600 text-white px-6 py-2 rounded-full font-semibold hover:bg-blue-700 transition-colors duration-300 text-center"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Get Started
                </motion.a>
              </motion.div>
            )}
          </AnimatePresence>
        </nav>
      </motion.header>

      {/* Main Content */}
      <article className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-6 text-gray-800">
          The Power of AI-Generated Business Reports: What’s Inside a 100+ Page Report?
        </h1>

        {/* Relevant Unsplash Image */}
        <img
          src="https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=2559&auto=format&fit=crop"
          alt="AI Business Report"
          className="w-full h-auto rounded-lg shadow-lg mb-6"
        />

        <p className="text-lg text-gray-700 mb-6">
          In the fast-moving world of entrepreneurship, having a clear, data-driven business plan is critical. FeedbackbyAI transforms this
          task with its AI-generated, 100+ page business reports that provide comprehensive insights into your business idea’s potential.
          From market analysis to customer personas and elevator pitches, these reports leave no stone unturned.
        </p>

        <h2 className="text-3xl font-semibold mb-4 text-gray-800">What’s Inside the Report?</h2>

        <p className="text-lg text-gray-700 mb-6">
          The AI-generated report is divided into four major sections, each offering in-depth analysis and data to support your business
          idea. Here’s a look at what you’ll find in each section:
        </p>

        <h3 className="text-2xl font-semibold mb-4 text-gray-800">1. The Idea</h3>
        <ul className="list-disc list-inside mb-6 text-lg text-gray-700">
          <li>Idea Novelty Check: Assessing Originality and Patentability</li>
          <li>Why Now? Why Your Idea Will Succeed Now</li>
          <li>Problem-Solution Fit: Matching Pain Points with Your Product Offerings</li>
          <li>Customer Persona Crafting: Who is Your Ideal Customer?</li>
          <li>Your Weakness: Understand Your Potential Weakness Before Competitors</li>
          <li>Business Model: Crafting the Perfect Business Model for Your Idea Using AI</li>
          <li>Competitors: Know Who Your Products Will Be Compared To and How to Stand Out</li>
          <li>Creating Entry Barriers: Prevent Competitors from Gaining Traction</li>
          <li>Preparing for the Unexpected: Evaluating the Hurdles New Entrants May Face</li>
          <li>Finding the Blue Ocean: Discovering Your Uncharted Market Niche Using AI</li>
          <li>Crafting Your Elevator Pitch: Summarize Your Idea in a Tweet</li>
          <li>The Hook: What Makes Users Come Back to Your Product?</li>
          <li>Legal Checks: Addressing Potential Legal Issues</li>
        </ul>

        <h3 className="text-2xl font-semibold mb-4 text-gray-800">2. Building the MVP</h3>
        <ul className="list-disc list-inside mb-6 text-lg text-gray-700">
          <li>Resource Estimation: Identify the Resources Needed for MVP</li>
          <li>No Code Solution: Exploring No-Code Development Options</li>
          <li>Tech Stack: Building a Scalable Technology Stack</li>
          <li>Color Psychology: Leveraging Colors for Maximum Impact</li>
          <li>Landing Page Efficacy: Crafting High-Converting Landing Pages</li>
          <li>Defining Core Features: Focus on What Matters for MVP</li>
          <li>3-Tap UI: Simplifying the User Experience</li>
          <li>AI/ML Considerations: Integrating AI and Machine Learning</li>
          <li>Introductory Pricing: Setting Prices for Early-Stage Users</li>
          <li>Getting Your First Users: Strategies to Secure Early Adopters</li>
          <li>Measuring Success: Key Metrics to Track</li>
          <li>Conversion Optimization: Turning Visitors into Customers</li>
        </ul>

        <h3 className="text-2xl font-semibold mb-4 text-gray-800">3. Marketing for MVP</h3>
        <ul className="list-disc list-inside mb-6 text-lg text-gray-700">
          <li>Customer Journey: Map Out the Customer’s Experience</li>
          <li>Pre-Launch Hype: Building Anticipation for Your Product</li>
          <li>Audience Distractions: How to Hold Attention</li>
          <li>Grassroots Marketing: Build a Community from the Ground Up</li>
          <li>30 Seconds Reel: Creating Engaging Short-Form Video Content</li>
          <li>Press Release: Generating Media Attention</li>
          <li>Ad Copy: Writing Copy That Converts</li>
          <li>Growth Hacks & Guerrilla Tactics: Rapidly Scaling Your Reach</li>
          <li>Building Your MVP Community: Foster Engagement Around Your Product</li>
          <li>Email Sequencing Excellence: Crafting Effective Email Campaigns</li>
          <li>The Magic of Remarketing: Bringing Users Back</li>
          <li>Decoding User Behavior: Learn from Your Users</li>
          <li>Psychology-Based Marketing: Tapping into the Science of Influence</li>
          <li>Affiliates and Resellers: Expanding Your Reach Through Partnerships</li>
        </ul>

        <h3 className="text-2xl font-semibold mb-4 text-gray-800">4. Raise Capital</h3>
        <ul className="list-disc list-inside mb-6 text-lg text-gray-700">
          <li>Pitch Preparation: Crafting a Winning Pitch</li>
          <li>Valuation Factors: What Influences Investor Decisions?</li>
          <li>Y Combinator-Style Pitch Deck: Building a Powerful Presentation</li>
          <li>Investor Relations: Maintaining Strong Investor Connections</li>
          <li>Addressing Investor Concerns: Anticipate and Address Key Questions</li>
          <li>Funding Allocation for Sales Growth: Maximize Returns from Funding</li>
          <li>Sales Milestones: Planning for Growth and Scaling</li>
        </ul>

        <h2 className="text-3xl font-semibold mb-4 text-gray-800">Why These Reports Matter</h2>

        <p className="text-lg text-gray-700 mb-6">
          The AI-generated business report offered by FeedbackbyAI is more than just a collection of data—it’s a comprehensive, strategic
          roadmap designed to help you launch and grow your business with confidence. Whether you're validating an idea, developing an MVP,
          planning your marketing, or preparing to raise capital, these reports provide the detailed insights you need to make informed
          decisions and avoid common pitfalls.
        </p>

        {/* Call to Action Section */}
        <div className="bg-blue-100 border-l-4 border-blue-500 p-4 mb-6">
          <p className="text-xl font-semibold text-blue-700">
            Ready to uncover the full potential of your business idea? Sign up for a free trial and explore FeedbackbyAI's AI-generated
            business reports today.
          </p>
        </div>

        <div className="bg-gray-100 p-6 rounded-lg text-center">
          <h3 className="text-2xl font-bold mb-4">Get Your AI-Generated Business Report</h3>
          <p className="text-lg mb-4">Sign up for a free trial to access your 100+ page, in-depth business report.</p>
          <button className="bg-blue-500 hover:bg-green-500 text-white font-bold py-2 px-4 rounded">
            <a href="/">Sign Up for Free</a>
          </button>
        </div>
      </article>
    </div>
  );
};

export default SecondArticle;
